/**
 *
 * LandingPagePreferences/DisclaimerEdit
 *
 */

import React, { useState } from 'react'
// import PropTypes from 'prop-types'
import styled from '@emotion/styled'
// import { FormattedMessage } from 'react-intl'
// import messages from './messages'

import gql from 'graphql-tag'
// import Query from 'components/Query'
import { useMutation } from '@apollo/client'

import TextEditor from 'components/TextEditor'

const NeedSaveText = styled.div`
	color: red;
	font-size: 0.8rem;
	float:right;
`

const UPDATE_USER_DISCLAIMER = gql`
	mutation updateDisclaimer($user_id: ID!, $disclaimer_json: JSON) {
		updateUser(input: { id: $user_id, disclaimer_json: $disclaimer_json }) {
			user {
				id
				disclaimer_json
			}
		}
	}
`

const UPDATE_CAMPAIGN_DISCLAIMER = gql`
	mutation updateDisclaimer($campaign_id: ID!, $disclaimer_json: JSON) {
		updateCampaign(input: { id: $campaign_id, disclaimer_json: $disclaimer_json }) {
			campaign {
				id
				disclaimer_json
			}
		}
	}
`

const UPDATE_CAMPAIGN_TEMPLATE_DISCLAIMER = gql`
	mutation updateDisclaimer($campaign_template_id: ID!, $disclaimer_json: JSON) {
		updateCampaignTemplate(input: { id: $campaign_template_id, disclaimer_json: $disclaimer_json }) {
			campaign_template {
				id
				disclaimer_json
			}
		}
	}
`

function DisclaimerEdit({ user_id, campaign_id, campaign_template_id, disclaimer_json, onCompleted }) {
	const [has_changes, setHasChanges] = useState(false)

	const [updateUser] = useMutation(UPDATE_USER_DISCLAIMER, {
		onCompleted: () => {
			setHasChanges(false)
			if (onCompleted) {
				onCompleted()
			}
		}
	})

	const [updateCampaign] = useMutation(UPDATE_CAMPAIGN_DISCLAIMER, {
		onCompleted: () => {
			setHasChanges(false)
			if (onCompleted) {
				onCompleted()
			}
		}
	})

	const [updateCampaignTemplate] = useMutation(UPDATE_CAMPAIGN_TEMPLATE_DISCLAIMER, {
		onCompleted: () => {
			setHasChanges(false)
			if (onCompleted) {
				onCompleted()
			}
		}
	})

	return (
		<React.Fragment>
			<div className="small text-center mb-1">
				{user_id && 'Disclaimer applies to all campaign types.'}
				{(campaign_id || campaign_template_id) && 'Campaign disclaimers will override user level disclaimers.'}
			</div>
			<input style={{height: 0}} type="checkbox" required={has_changes} />
			{has_changes && (
				<NeedSaveText>Disclaimer Not Saved</NeedSaveText>
			)}
			<TextEditor
				onSave={(content) => {
					if (user_id) {
						updateUser({
							variables: {
								user_id,
								disclaimer_json: content,
							},
						})
					}

					if (campaign_id) {
						updateCampaign({
							variables: {
								campaign_id,
								disclaimer_json: content,
							}
						})
					}

					if (campaign_template_id) {
						updateCampaignTemplate({
							variables: {
								campaign_template_id,
								disclaimer_json: content,
							}
						})
					}
				}}
				default_content={disclaimer_json}
				wrapperStyle={{height: 750, marginBottom: '1rem', background: '#fff'}}
				editorStyle={{position: 'relative', zIndex: 99}}
				editorDidChange={() => setHasChanges(true)}
				has_changes={has_changes}
			/>
		</React.Fragment>
	)
}

DisclaimerEdit.propTypes = {}

export default DisclaimerEdit
