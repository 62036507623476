/**
 *
 * /Table
 *
 */

import React, { useEffect, useState, useReducer } from 'react'

// NPM Libraries
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fromJS } from 'immutable'

// Components
import TableHeader from 'components/Table/HeaderRow'
import TableRow from 'components/Table/Row'
import TableBodyVirtualized from 'components/Table/BodyVirtualized'
import decorateColumn from 'components/Table/column'

import { useTooltip } from 'hooks'

const PageSizeInput = styled.select``

const RTable = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	width: 100%;
	overflow-x: scroll;

	margin: 0 0 1em 0;
	padding: 0;

	background-color: #fff;

	.table-loading {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba(255, 255, 255, 0.5);
	}
`

const RTableCell = styled.div`
	box-sizing: border-box;
	flex-grow: 1;
	width: 100%;
	list-style: none;
	border-top: 1px solid #e7e7e7;

	padding: 1.5em 2em 1.5em 1.5em;
	overflow: hidden;
	text-overflow: ellipsis;

	white-space: nowrap;

	display: flex;
	justify-content: center;
	flex-direction: column;

	&.header-cell {
		color: #747474;
		font-weight: bold;
		position: relative;

		/* Accounts for l */
		padding-top: 1.3em;
		padding-bottom: 1.3em;

		border-top: 0.2em solid transparent;
		border-bottom: 0.2em solid transparent;
		border-right: 1px solid #e7e7e7;

		&.sortable {
			cursor: ns-resize;
		}

		&.header-sort-asc {
			border-bottom-color: #e7e7e7;
			cursor: s-resize;
		}

		&.header-sort-desc {
			border-top-color: #e7e7e7;
			cursor: n-resize;
		}
	}

	.header-filter-input {
		width: 100%;
		border: solid #e7e7e7 1px;
		margin-top: 7px;
		display: block;
		padding: 3px 6px;
	}
`

const ADD_RESIZED = 'components/Table/ADD_RESIZED'
const UPDATE_RESIZED = 'components/Table/UPDATE_RESIZED'
const DELETE_RESIZED = 'components/Table/DELETE_RESIZED'
const RESET_RESIZED = 'components/Table/RESET_RESIZED'

const resized_reducer = (state, action) => {
	switch (action.type) {
		case ADD_RESIZED:
		case UPDATE_RESIZED:
			return state.set(action.id, action.width)
		case DELETE_RESIZED:
			return state.delete(action.id)
		case RESET_RESIZED:
			return action.rules
		default:
			return state
	}
}

const TableContext = React.createContext()

function Table({
	columns,
	data,
	loading,
	sort,
	setSort,
	page_size,
	setPageSize,
	page,
	setPage,
	refetch,
	total_records,
	filter_input_states,
	handleFilterInputChange,
	row_dropdown,
	virtualize,
}) {
	const [currently_resizing, setCurrentlyResizing] = useState(null)
	const [resized, dispatchResized] = useReducer(resized_reducer, fromJS({}))
	const [buildTooltip] = useTooltip()

	useEffect(() => {
		refetch()
		buildTooltip && buildTooltip()
	}, [])

	useEffect(() => {
		if (data.length === 0 && page > 1 && !loading) {
			setPage(page - 1)
		}
	}, [data.length, page, loading])

	const decorated_columns = columns.map((c) => decorateColumn(c))

	const rowMinWidth = decorated_columns.reduce((s, d) => {
		const resized_column = resized.get(d.id)
		return s + (resized_column || d.width || d.minWidth)
	})

	const rows = [...data, ...Array(Math.max(page_size - data.length, 0)).fill()]

	const range_start = (page - 1) * page_size + 1
	const range_end = range_start + data.length - 1

	return (
		<TableContext.Provider value={{ columns: decorated_columns, resized }}>
			<RTable style={{ minWidth: rowMinWidth }} className={loading ? 'loading' : null}>
				<React.Fragment>
					<TableRow>
						<TableHeader
							currently_resizing={currently_resizing}
							setCurrentlyResizing={setCurrentlyResizing}
							dispatchResized={dispatchResized}
							sort={sort}
							setSort={setSort}
							filter_input_states={filter_input_states}
							handleFilterInputChange={handleFilterInputChange}
						/>
					</TableRow>
					{virtualize ? (
						<TableBodyVirtualized
							rows={rows}
							decorated_columns={decorated_columns}
							row_dropdown={row_dropdown}
							buildTooltip={buildTooltip}
						/>
					) : (
						<React.Fragment>
							{rows.map((row, index) => (
								<TableRow
									key={index}
									row={row}
									decorated_columns={decorated_columns}
									row_dropdown={row_dropdown}
									buildTooltip={buildTooltip}
								/>
							))}
						</React.Fragment>
					)}
					{loading && (
						<div className="table-loading">
							<div className="align-middle-center text-center">Loading...</div>
						</div>
					)}
				</React.Fragment>
			</RTable>
			<div className="float-right mr-5">
				<span className="mr-4">
					Rows per page:&nbsp;
					<PageSizeInput value={page_size} onChange={(e) => setPageSize(Number(e.target.value))}>
						{[5, 10, 20, 25, 50, 100].map((size) => (
							<option key={size}>{size}</option>
						))}
					</PageSizeInput>
				</span>
				<span className="mr-5">
					{range_start} - {range_end}
					{Boolean(total_records) && <span> of {total_records}</span>}
				</span>
				<button
					onClick={() => setPage(page - 1)}
					type="button"
					className="mr-5 btn-unstyled"
					disabled={page === 1}
				>
					<FontAwesomeIcon
						icon="chevron-left"
						transform="grow-3"
						className={page !== 1 ? 'text-default' : 'text-muted'}
					/>
				</button>
				<button onClick={() => setPage(page + 1)} type="button" className="mr-3 btn-unstyled">
					<FontAwesomeIcon icon="chevron-right" transform="grow-3" className="text-default" />
				</button>
			</div>
		</TableContext.Provider>
	)
}

Table.propTypes = {
	columns: PropTypes.array,
	data: PropTypes.array,
	page_size: PropTypes.number,
	refetch: PropTypes.func,
}

Table.defaultProps = {
	columns: [],
	data: [],
	page_size: 10,
	refetch: () => undefined,
}

export default Table
export { TableContext, RTableCell, ADD_RESIZED, UPDATE_RESIZED, DELETE_RESIZED, RESET_RESIZED }
