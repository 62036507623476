/**
 *
 *  LoginForm/Passwordless
 *
 */

import React, { memo, useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
// NPM Libraries
// import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { useMobileDetect } from '@joeyparis/hooks'
import { useStateAndCookie } from 'persistence-hooks'
import { differenceInMinutes, parseISO } from 'date-fns'

import { Client } from '@passwordlessdev/passwordless-client'

import { useCurrentUser } from 'hooks'
import { COLORS } from 'global-styles'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// GraphQL
// import useQuery from 'components/UseQuery'
import { gql, useMutation } from '@apollo/client'

import { FormattedMessage } from 'react-intl'
import messages from './messages'

const REGISTER_PASSWORDLESS_TOKEN = gql`
	mutation registerPasswordlessToken {
		registerPasswordlessToken(input: {}) {
			passwordless
		}
	}
`

const passwordless_client = new Client({
	apiKey: 'leadjig:public:2de17dd32ddf4c6cb4c58ae30591c5cc'
})

const Banner = styled.div`
	z-index: 100000;
	top: 0;
	left: 0;
	right: 0;
	background: ${COLORS.primary};
	color: #fff;
	text-align: center;
	position: static;
	padding: 1rem 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
`

const DismissButton = styled.button`
	margin: 1rem;
	border: none;
	background: transparent;
	color: #fff;
`

function Passwordless() {
	const current_user = useCurrentUser()

	const [hide_banner_cookie, setHideBannerCookie] = useStateAndCookie(false, 'hide_passwordless_banner', {days: 1})
	const [hide_banner, setHideBanner] = useState(hide_banner_cookie)

	useEffect(() => {
		setHideBanner(hide_banner_cookie)
	}, [hide_banner_cookie])

	const [registerPasswordlessToken] = useMutation(REGISTER_PASSWORDLESS_TOKEN)

	const enablePasswordlessLogin = useCallback(async () => {

		const { data } = await registerPasswordlessToken()

		const { token, error } = await passwordless_client.register(data.registerPasswordlessToken.passwordless.token)

		if (token) {
			const saved_tokens = localStorage.getItem('passwordless_tokens') || {}
			saved_tokens[current_user.id] = {
				id: current_user.id,
				alias: current_user.email,
				token,
			}
			localStorage.setItem('passwordless_tokens', JSON.stringify(saved_tokens))
			setHideBanner(true)
		} else {
			console.error(error)
		}
	})

	const dismissBanner = useCallback(() => {
		setHideBannerCookie(true)
	}, [setHideBannerCookie])

	const token_saved = localStorage.getItem('passwordless_tokens') && JSON.parse(localStorage.getItem('passwordless_tokens'))[current_user.id]

	const { is_mobile } = useMobileDetect()

	const login_duration = differenceInMinutes(new Date(), parseISO(current_user.current_sign_in_at))

	useEffect(() => {
		setTimeout(() => {
			setHideBanner(true)
		}, 1000 * 60 * 2);
	}, [setHideBanner])

	if (token_saved || hide_banner === true || login_duration > 2) {
		return null
	}

	return (
		<Banner>
			<div />
			<div>
				<span>Sign in faster on this device:</span>
				<button
					className="btn btn-outline-light ml-2"
					type="button"
					onClick={enablePasswordlessLogin}
				>
					{is_mobile && (
						<React.Fragment>
							<FontAwesomeIcon icon="fingerprint" className="mr-2" />
							<span>Enable Face or Fingerprint ID</span>
						</React.Fragment>
					)}
					{!is_mobile && (
						<React.Fragment>
							<FontAwesomeIcon icon="lock" className="mr-2" />
							<span>Enable Passwordless Login</span>
						</React.Fragment>
					)}
				</button>
			</div>
			<DismissButton onClick={dismissBanner}>
				<FontAwesomeIcon icon="times" />
			</DismissButton>
		</Banner>
	)
}

Passwordless.propTypes = {}

export default memo(Passwordless)
