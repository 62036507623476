/* eslint-disable no-nested-ternary */

/**
 *
 * /MutationStatusIndicator
 *
 */

import React from 'react'

// NPM Libraries
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function MutationStatusIndicator({ loading: mutating, called, error, fade, show_error_message = false }) {
	if (mutating || called || error) {
		return (
			<React.Fragment>
				<FontAwesomeIcon
					icon={mutating ? 'spinner' : error ? 'exclamation-circle' : called ? 'check-circle' : ''}
					spin={mutating}
					className={classNames({
						'text-warning': mutating,
						'text-success': called && !mutating && !error,
						'fade-out': called && !mutating && !error && fade,
						'text-danger': error && !mutating,
					})}
				/>
				{show_error_message && error && (
					<div className="text-danger" style={{ fontSize: '1rem' }}>
						{error.message}
					</div>
				)}
			</React.Fragment>
		)
	}

	return null
}

MutationStatusIndicator.propTypes = {}

MutationStatusIndicator.defaultProps = {
	fade: true,
}

export default MutationStatusIndicator
