/**
 *
 * Notifications/Toast
 *
 */

import React, { useState, useEffect } from 'react'

// NPM Libraries
import PropTypes from 'prop-types'
import { ToastContainer, toast } from 'react-toastify'
import { isAfter, subMinutes } from 'date-fns'
import { useIdentifier } from 'hooks'

// GraphQL
import gql from 'graphql-tag'
import { useLazyQuery } from '@apollo/client'

// Components
import IO from 'components/Socket/IO'
import NotificationListItem from 'components/Notifications/List/Item'

// Utils
import { Notification } from 'globalVars'

const LOAD_NOTIFICATION = gql`
	query notification($id: ID!) {
		notification(id: $id) {
			id
			created_at
			read_status
			read_status_index
			actionable
			link
			output {
				icon
				text
			}
			owner {
				id
				full_name
			}
		}
	}
`

function Toast({ user_id }) {
	const identifier = useIdentifier()
	const [notifications, setNotifications] = useState({})

	const [loadNotifications] = useLazyQuery(LOAD_NOTIFICATION, {
		onCompleted: ({ notification }) => {
			if (
				Notification.READ_NO === notification.read_status_index &&
				(!notifications[notification.id] ||
					notifications[notification.id].output.text !== notification.output.text)
			) {
				if (notifications[notification.id] && toast.isActive(notifications[notification.id].toast_id)) {
					toast.update(notifications[notification.id], {
						render: <NotificationListItem notification={notification} hide_mark_as_read />,
					})
				} else {
					setNotifications((prev_notifications) => ({
						...prev_notifications,
						[notification.id]: {
							...notification,
							toad_id: toast(
								<NotificationListItem
									notification={notification}
									hide_mark_as_read
									disable_visibility_sensor
								/>,
								{
									autoClose: 10000,
									position: toast.POSITION.BOTTOM_LEFT,
									className: 'p-0',
								},
							),
						},
					}))
				}
			}
		},
	})

	const notify = (notification_id) => {
		setTimeout(() => loadNotifications({ variables: { id: notification_id } }), 2000)
	}

	const shouldNotify = (d) => {
		const { notification } = JSON.parse(d)

		if (isAfter(new Date(notification.created_at), subMinutes(new Date(), 1))) {
			notify(notification.id)
		}
	}

	useEffect(() => {
		const io = new IO()
		io.join(`notifications_${user_id}`, identifier)
		io.on(`notifications_${user_id}`, shouldNotify)

		return () => {
			io.off(`notifications_${user_id}`, shouldNotify)
			io.leave(`notifications_${user_id}`, identifier)
		}
	}, [user_id])

	return <ToastContainer closeButton={false} />
}

Toast.propTypes = {
	user_id: PropTypes.string,
}

Toast.defaultProps = {}

export default Toast
