/* eslint-disable @emotion/jsx-import */

/**
 *
 * Table/Cell
 *
 */

import React, { useContext, useEffect } from 'react'

// NPM Libraries
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { css } from '@emotion/react'
// eslint-disable-next-line
import _ from 'lodash'

// Components
import { TableContext, RTableCell } from 'components/Table'

function Cell({ row, column, header, className, children, buildTooltip, toggleRowDropdown, virtualized, ...rest }) {
	useEffect(() => {
		if (column.withTooltip && buildTooltip) {
			buildTooltip()
		}
	}, [column])

	let accessor
	const CellComponent = column.Cell && !header ? column.Cell : ({ value }) => <React.Fragment>{value}</React.Fragment>

	if (header) {
		accessor = () => children
	} else if (!row) {
		accessor = () => (
			<React.Fragment>
				<br />
				<br />
			</React.Fragment>
		)
	} else {
		accessor = column.accessor
	}

	const { resized } = useContext(TableContext)
	const resizedValue = resized.get(column.id)
	const width = resizedValue || column.width || column.minWidth
	const maxWidth = resizedValue || column.width || column.maxWidth

	return (
		<RTableCell
			className={classNames(className, column.className, {
				'header-cell': header,
			})}
			css={css`
				flex: ${width} 0 auto;
				width: ${width}px;
				max-width: ${maxWidth}px;
				height: ${virtualized ? '80px' : 'auto'};
			`}
			{...rest}
		>
			{CellComponent({ ...row, value: accessor(row, toggleRowDropdown) })}
		</RTableCell>
	)
}

Cell.propTypes = {
	row: PropTypes.object,
	column: PropTypes.object,
	header: PropTypes.bool,
	className: PropTypes.string,
	children: PropTypes.element,
	buildTooltip: PropTypes.func,
	toggleRowDropdown: PropTypes.func,
	virtualized: PropTypes.bool,
}

Cell.defaultProps = {
	header: false,
}

export default Cell
