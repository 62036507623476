/**
 *
 *  CmdK/NavigationMenuItems
 *
 */

import React, { memo, useEffect } from 'react'
// NPM Libraries
// import PropTypes from 'prop-types'
// import styled from '@emotion/styled'
import { useIdentifier } from '@joeyparis/hooks'
import { Command } from 'cmdk'

// GraphQL
import useQuery from 'components/UseQuery'
import { gql } from '@apollo/client'
const GET_MENU_ITEMS = gql`
	query menu_items($location: String) {
		menu_items(location: $location)
	}
`
import { FormattedMessage } from 'react-intl'
import messages from './messages'

function NavigationMenuItems({ onSelect, setLoading }) {
	const identifier = useIdentifier()
	const {
		data: { menu_items },
		loading,
	} = useQuery(GET_MENU_ITEMS, {
		variables: {
			location: location.pathname,
		},
		default_data: {
			menu_items: [],
		}
	})

	useEffect(() => {
		setLoading(identifier, loading)

		return () => {
			setLoading(identifier, false)
		}
	}, [loading])

	return (
		<Command.Group heading="Navigation">
			{menu_items.map((item) => (
				<Command.Item
					key={item.label}
					onSelect={() => onSelect(item.link)}
				>
					{item.label}
				</Command.Item>
			))}
		</Command.Group>
	)
}

NavigationMenuItems.propTypes = {}

export default memo(NavigationMenuItems)
