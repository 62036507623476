/**
 *
 * Campaigns/Create
 *
 */

import React from 'react'

// NPM Libraries
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

// GraphQL
import { gql, useMutation } from '@apollo/client'

// Components
import CreateForm from 'components/Campaigns/Form'

const CREATE_CAMPAIGN = gql`
	mutation createCampaign(
		$user_id: ID
		$name: String
		$topic: String
		$description: String
		$description2: String
		$headline: String
		$objective: Int
		$highlights: [String]
		$questions: [String]
		$job_codes: [String]
		$landing_page_background_id: ID
		$redirect: Boolean
		$redirect_url: String
		$redirect_after_registration: Boolean
		$video_embed_link: String
		$is_meal_event: Boolean
		$sequence_id: ID
		$campaign_image_url: String
		$call_to_action: String
		$sub_headline: String
		$questions_required: Boolean
	) {
		createCampaign(
			input: {
				user_id: $user_id
				name: $name
				topic: $topic
				description: $description
				description2: $description2
				headline: $headline
				objective: $objective
				highlights: $highlights
				questions: $questions
				job_codes: $job_codes
				landing_page_background_id: $landing_page_background_id
				redirect: $redirect
				redirect_url: $redirect_url
				redirect_after_registration: $redirect_after_registration
				video_embed_link: $video_embed_link
				is_meal_event: $is_meal_event
				sequence_id: $sequence_id
				campaign_image_url: $campaign_image_url
				call_to_action: $call_to_action
				sub_headline: $sub_headline
				questions_required: $questions_required
			}
		) {
			campaign {
				id
				user_id
				name
				topic
				description
				description2
				headline
				objective
				highlights
				questions
				questions_required
				job_codes
				landing_page_background_id
				redirect
				redirect_url
				redirect_after_registration
				video_embed_link
				is_meal_event
				sequence {
					id
					name
				}
				campaign_image_url
				call_to_action
				sub_headline
			}
		}
	}
`

function Create({ onSave }) {
	const history = useHistory()

	const [createCampaign, { loading: creating }] = useMutation(CREATE_CAMPAIGN, {
		onCompleted: (data) => {
			onSave && onSave()
			history.push(`/campaigns/${data.createCampaign.campaign.id}`)
		},
	})

	return (
		<CreateForm
			action="Create"
			disabled={creating}
			handleSubmit={(campaign_fields) => {
				createCampaign({ variables: campaign_fields })
			}}
		/>
	)
}

Create.propTypes = {
	onSave: PropTypes.func,
}

export default Create
