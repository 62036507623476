import gql from 'graphql-tag'
import useQuery from 'components/UseQuery'

export const PERMISSION_CHECK = gql`
	query permission_check($user_id: ID, $action: String, $object_class: String, $object_id: String) {
		permission_check(user_id: $user_id, action: $action, object_class: $object_class, object_id: $object_id)
	}
`

// eslint-disable-next-line import/no-mutable-exports
let usePermissionCheck = (user_id, action, object_class, object_id) => {
	const { data } = useQuery(PERMISSION_CHECK, {
		variables: {
			user_id,
			action,
			object_class,
			object_id,
		},
		channels: [user_id],
	})

	return data && data.permission_check
}

if (global.testing) {
	usePermissionCheck = () => true
}

export default usePermissionCheck
