/**
 *
 * Campaigns/Form/Description2
 *
 */

import React, { useState } from 'react'
import MarkdownEditor from 'components/MarkdownEditor'

function Description2({ campaign_state, setCampaignState, disabled }) {
	const [show_description_2, setShowDescription2] = useState(!!campaign_state.description2)

	return (
		<div className="form-group">
			{show_description_2 ? (
				<React.Fragment>
					<div className="form-text text-muted mt-3 mb-2">
						Description Part 2{' '}
						<span className="ml-4" style={{ opacity: 0.5 }}>
							(must also be enabled in Landing Page preferences)
						</span>
					</div>
					<MarkdownEditor
						value={campaign_state.description2}
						setValue={(description2) => setCampaignState({ description2 })}
						disabled={disabled}
						onBlur={() => {
							if (!campaign_state?.description2?.trim()?.length) {
								setCampaignState({ description2: null })
								setShowDescription2(false)
							}
						}}
					/>
				</React.Fragment>
			) : (
				<button type="button" className="btn-unstyled" onClick={() => setShowDescription2(true)}>
					Add Description Part 2
				</button>
			)}
		</div>
	)
}

Description2.propTypes = {}

export default Description2
