/**
 *
 * /EmailSubscriptionBanner
 *
 */

import React, { useState, useEffect } from 'react'

// NPM Libraries
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { useStateAndCookie } from 'persistence-hooks'

// Graphql
import useQuery from 'components/UseQuery'
import { useMutation, gql } from '@apollo/client'

// Components
import { COLORS } from 'global-styles'

const Banner = styled.div`
	z-index: 100000;
	top: 0;
	left: 0;
	right: 0;
	background: #ffc933;
	text-align: center;
	padding: 5px 0;
`

const CustomButton = styled.button`
	background-color: transparent;
	color: ${(props) => props.color};
	border: 0;
	font-weight: 600;
	font-size: 90%;
	margin: 0px 5px;
	text-decoration: underline;
	&:hover {
		opacity: 0.8;
	}
`

const GET_USER = gql`
	query getUser($id: ID!) {
		user(id: $id) {
			id
			is_unsubscribed
		}
	}
`

const RESUBSCRIBE_TO_NOTIFICATIONS_EMAILS = gql`
	mutation resubscribeToNotificationEmails($user_id: ID) {
		resubscribeToNotificationEmails(input: { user_id: $user_id }) {
			status
		}
	}
`

function EmailSubscriptionBanner({ user_id }) {
	const [expire_date, setExpireDate] = useState(1)
	const key = `${user_id}_display_notification_subsribe_banner`

	const [hide_notification, setHideNotify] = useStateAndCookie(false, key, { days: expire_date })

	useEffect(() => {
		// We call this when the expire date changes so that the cookie
		// expiration date is updated
		setHideNotify(hide_notification)
	}, [expire_date])

	const {
		data: {
			user: { is_unsubscribed },
		},
	} = useQuery(GET_USER, {
		channels: [user_id],
		variables: { id: user_id },
		default_data: { user: { is_unsubscribed: false } },
	})

	const [resubscribeEmail, { loading }] = useMutation(RESUBSCRIBE_TO_NOTIFICATIONS_EMAILS, {
		variables: { user_id },
	})

	if (hide_notification || !is_unsubscribed) return null

	return (
		<Banner>
			It seems you are unsubscribed from LeadJig notification emails.
			<div className="d-inline mx-3">
				<CustomButton color={COLORS.info} onClick={() => resubscribeEmail()} disabled={loading}>
					Subscribe Again
				</CustomButton>
				<CustomButton
					color={COLORS.primary}
					onClick={() => {
						setHideNotify(true)
						setExpireDate(1)
					}}
				>
					Remind me tomorrow
				</CustomButton>
				<CustomButton
					color={COLORS.danger}
					onClick={() => {
						setHideNotify(true)
						setExpireDate(365)
					}}
				>
					Dont remind me again.
				</CustomButton>
			</div>
		</Banner>
	)
}

EmailSubscriptionBanner.propTypes = {
	user_id: PropTypes.string.isRequired,
}

export default EmailSubscriptionBanner
