/**
 *
 * CompleteProfile/Form
 *
 */

import React from 'react'

// NPM Libraries
import FloatingLabel from '@joeyparis/react-floating-label-input'

// Graphql
import gql from 'graphql-tag'
import { Mutation } from '@apollo/client/react/components'

// Components
import AddressInput from 'components/AddressInput/index'

// Utils
import { formatPhoneNumber, titleize } from 'components/utils'
import { useHandleInputChange } from 'hooks'

const UPDATE_USER = gql`
	mutation updateUser(
		$id: ID!
		$first_name: String
		$last_name: String
		$phone: BigInt
		$phone_extension: String
		$time_zone: String
		$company_name: String
		$company_phone: BigInt
		$company_phone_extension: String
		$user_id: ID
		$company_address_apt: String
		$company_address_place_id: String
		$company_address_components: JSON
		$coordinates: [Float]
	) {
		createOrUpdateCompanyAddress(
			input: {
				user_id: $user_id
				components: $company_address_components
				apt: $company_address_apt
				place_id: $company_address_place_id
				coordinates: $coordinates
			}
		) {
			user {
				id
				company_address {
					id
					user_id
					apt
					components {
						street_address
						city
						state
						zip
					}
				}
			}
		}
		updateUser(
			input: {
				id: $id
				first_name: $first_name
				last_name: $last_name
				phone: $phone
				phone_extension: $phone_extension
				company_phone: $company_phone
				company_phone_extension: $company_phone_extension
				time_zone: $time_zone
				company_name: $company_name
			}
		) {
			user {
				id
				first_name
				last_name
				phone
				phone_extension
				time_zone
				company_name
				company_phone
				company_phone_extension
				profile_complete
			}
		}
	}
` //

function Form({ user, time_zones, setComplete }) {
	const [user_info, handleInputChange] = useHandleInputChange({
		...user,
		company_address_apt: user.company_address?.apt,
		company_address_components: user.company_address?.components,
		coordinates: user.company_address?.coordinates,
		phone: formatPhoneNumber(user.phone) || '',
		company_phone: formatPhoneNumber(user.company_phone) || '',
	})

	const handleSubmit = (e, mutate) => {
		e.preventDefault()
		const variables = { ...user_info }
		variables.phone = Number(String(user_info.phone).replace(/\D/g, ''))
		variables.company_phone = Number(String(user_info.company_phone).replace(/\D/g, ''))
		variables.user_id = user.id
		mutate({ variables })
	}

	const name_blank = !user_info.first_name || !user_info.last_name

	return (
		<Mutation mutation={UPDATE_USER}>
			{(mutate, { data, error }) => {
				if (data && data.updateUser && data.updateUser.user.profile_complete) {
					setComplete(true) // hides component
				}

				return (
					<form onSubmit={(e) => handleSubmit(e, mutate)}>
						{name_blank &&
							['first_name', 'last_name'].map((input_name) => (
								<div key={input_name} className="w-50 d-inline-block">
									<FloatingLabel
										type="text"
										name={input_name}
										label={titleize(input_name)}
										value={user_info[input_name] || ''}
										onChange={handleInputChange}
										autoFocus={input_name === 'first_name'}
										dark
										transparent
										required
									/>
								</div>
							))}
						{['phone', 'phone_extension', 'company_name', 'company_phone', 'company_phone_extension'].map(
							(input_name) => (
								<div
									className="d-inline-block mb-2"
									style={{ width: input_name.match('phone') ? '50%' : '100%' }}
									key={input_name}
								>
									<FloatingLabel
										type="text"
										name={input_name}
										label={input_name.match('extension') ? 'extension' : titleize(input_name)}
										value={user_info[input_name] || ''}
										onChange={handleInputChange}
										required={!input_name.match('extension')}
										transparent
										dark
									/>
								</div>
							),
						)}
						<div className="mb-2">
							<AddressInput
								// address={user_info.company_address_full}
								address_apt={user_info.company_address_apt}
								// address_target_name="company_address_full"
								address_components={user_info.company_address_components}
								address_apt_target_name="company_address_apt"
								address_place_id_target_name="company_address_place_id"
								address_components_target_name="company_address_components"
								coordinates_target_name="coordinates"
								handleInputChange={handleInputChange}
								dark
								transparent
								required
							/>
						</div>
						<FloatingLabel
							type="select"
							name="time_zone"
							label="Time Zone"
							value={user_info.time_zone || ''}
							options={time_zones || []}
							onChange={handleInputChange}
							dark
							transparent
							prevent_autofill
							required
						/>
						<input type="submit" className="btn btn-block btn-default my-3" value="Save" />
						{error && <span className="text-center">Could not be saved.</span>}
					</form>
				)
			}}
		</Mutation>
	)
}

Form.propTypes = {}

export default Form
