/*
 * /NotSupportedBrowserModal Messages
 *
 * This contains all the text for the NotSupportedModal component.
 */

import { defineMessages } from 'react-intl'

export const scope = 'app.components.NotSupportedBrowserModal'

export default defineMessages({
	header: 'Browser Upgrade Recommendation!',
	body: 'LeadJig has detected that you are using a browser that is not fully supported. We strongly recomend you to improve your brower to Google Chrome, Brave, or Firefox to have a complete user expirience',
})
