import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import useQuery from 'components/UseQuery'
import gql from 'graphql-tag'

// import { mock_user_god /*, user_advisor*/ } from 'mocks/users'

import useIntercom from 'hooks/useIntercom'

import Sentry from 'configureSentry'
import client from 'configureApolloClient'
// console.log('from current user context', global.mock_user_god)

const CurrentUserContext = React.createContext({
	current_user: {},
	refetch: () => {
		console.log('empty function')
	},
	loading: false,
})

const withCurrentUser = (Component) => (props) =>
	(
		<CurrentUserContext.Consumer>
			{({ current_user }) => <Component {...props} current_user={global.test_user || current_user} />}
		</CurrentUserContext.Consumer>
	)

// eslint-disable-next-line react-hooks/rules-of-hooks
const useCurrentUser = () => global.test_user || useContext(CurrentUserContext).current_user

export const GET_CURRENT_USER = gql`
	{
		current_user {
			id
			full_name
			email
			is_confirmed
			phone
			role
			role_string
			company_name
			company_website
			created_at
			namespace
			available_roles
			is_advisor
			is_organization
			is_manager
			is_consultant
			is_confirmation_agent
			is_artist
			is_partner
			is_god
			time_zone
			time_zone_offset
			reset_password_on_next_login
			is_locked_out
			current_sign_in_at
			profile_complete
			default_campaign_allowed_guests
			default_campaign_event_capacity
			default_campaign_questions
			default_campaign_questions_required
		}
	}
`

function CurrentUserProvider({ children }) {
	const history = useHistory()
	const [current_user_id, setCurrentUserId] = useState('')
	const [context_state, setState] = useState({
		current_user: {},
		loading: false,
		refetch: () => {},
	})

	const {
		data: { current_user },
		loading,
		refetch,
		error,
	} = useQuery(GET_CURRENT_USER, {
		default_data: { current_user: {} },
		channels: [current_user_id],
		fetchPolicy: 'network-only',
		notifyOnNetworkStatusChange: true,
		onError: logOutIfNeeded,
	})

	logOutIfNeeded()

	useEffect(() => {
		setCurrentUserId(current_user?.id)

		setState({ ...context_state, current_user: current_user || {} })

		Sentry.setUser({
			id: current_user.id,
			email: current_user.email,
			username: current_user.full_name,
		})
	}, [JSON.stringify(current_user)])

	useEffect(() => {
		setState({ current_user, loading, refetch })
	}, [loading, refetch])

	useIntercom(current_user)

	function logOutIfNeeded() {
		if (
			error &&
			!current_user?.id &&
			window.location.pathname !== '/login' &&
			window.location.pathname !== '/users/password/edit' &&
			!window.location.pathname.startsWith('/rsvp/') &&
			!window.location.pathname.startsWith('/email-settings')
		) {
			client.resetStore().catch()
			history.push('/login')
		}
	}

	return <CurrentUserContext.Provider value={context_state}>{children}</CurrentUserContext.Provider>
}

// if (!!global.CurrentUser){
// 	CurrentUserProvider = function({children, mock_user}){
// 		return (
// 			<CurrentUserContext.Provider
// 				value={{
// 					current_user: global.CurrentUser,
// 					refetch: () => {},
// 					loading: false,
// 				}}
// 			>
// 				{children}
// 			</CurrentUserContext.Provider>
// 		)
// 	}
// }

export default CurrentUserProvider
export { CurrentUserContext, withCurrentUser, useCurrentUser }
