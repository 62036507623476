/**
 *
 * CompleteProfile
 *
 */

import React from 'react'

// NPM Libraries
import styled from '@emotion/styled'
import { withState } from 'recompose'

// GraphQL
import useQuery from 'components/UseQuery'
import { gql } from '@apollo/client'

// Components
import Form from 'components/CompleteProfile/Form'

const GET_USER_AND_TIMEZONES = gql`
	query userAndTimeZones($user_id: ID) {
		user(id: $user_id) {
			id
			first_name
			last_name
			phone
			phone_extension
			time_zone
			company_name
			company_phone
			company_phone_extension
			company_address {
				id
				components {
					street_address
					city
					state
					zip
				}
				apt
			}
		}
		time_zones
	}
`

const CompleteProfileScreen = styled('div')`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1001;
	color: #32323a;
	#overlay {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #16a1d4;
		opacity: 0.8;
	}
	.cp-form-wrapper {
		width: 420px;
		background-color: #16a1d4;
		opacity: 0.7;
		padding: 20px;
		border-radius: 20px;
	}
`

const SunglassesImg = styled('div')`
	background: #fff url(/images/sunglasses.svg) top 54% center / 63% no-repeat;
	width: 200px;
	min-height: 200px;
	border-radius: 100%;
	margin: 10px auto 20px;
`

function CompleteProfile({ user_id, profile_complete, setComplete }) {
	const {
		data: { user, time_zones },
	} = useQuery(GET_USER_AND_TIMEZONES, { variables: { user_id } })

	if (profile_complete) return null

	return (
		<CompleteProfileScreen id="complete-profile">
			<div id="overlay" />
			<div
				className="d-flex flex-column align-items-center h-100 position-relative"
				style={{ overflow: 'scroll' }}
			>
				<SunglassesImg />
				<header className="mb-4 text-center">
					<h1>Let's complete your account...</h1>
					<p>We need a little bit more info from you.</p>
				</header>
				<div className="cp-form-wrapper">
					{!!user && <Form user={user} time_zones={time_zones} setComplete={setComplete} />}
				</div>
			</div>
		</CompleteProfileScreen>
	)
}

CompleteProfile.propTypes = {}

export default withState('profile_complete', 'setComplete', false)(CompleteProfile)
