/**
 *
 * Campaigns/Create/JobCodes
 *
 */

import React, { useState } from 'react'

// Components
import FloatingLabel from '@joeyparis/react-floating-label-input'

function JobCodes({ setJobCodes, job_codes, disabled }) {
	const [validation_message, setValidationMessage] = useState('')
	const [job_code_string, setJobCodeString] = useState(job_codes ? job_codes.join(' ') : '')

	const validateJobCodes = (string) => {
		const too_short_inputs = string.match(/\b\d{1,4}\b/g)
		const too_long_inputs = string.match(/\b\d{7,}\b/g)
		let message = ''
		if (too_short_inputs) {
			message += `Too short: ${too_short_inputs.join(', ')}   `
		}
		if (too_long_inputs) {
			message += `Too long: ${too_long_inputs.join(', ')} `
		}
		setValidationMessage(message)
	}

	return (
		<FloatingLabel
			type="text"
			name="job_codes"
			label="Job Codes"
			value={job_code_string}
			onChange={(e) => {
				setJobCodeString(e.target.value)
				validateJobCodes(e.target.value)
				setJobCodes(e.target.value.match(/\b(AO)*\d{5,6}\b/g) || [])
			}}
			message={validation_message}
			disabled={disabled}
			prevent_autofill
		/>
	)
}

JobCodes.propTypes = {}

export default JobCodes
