/**
 *
 * Campaigns/Form/ObjectiveSelector
 *
 */

import React from 'react'

// Components
import CircleImage from 'components/CircleImage'
import DropDownMenu from 'components/DropDownMenu'

// Images
import EventsIcon from 'components/Campaigns/Events.svg'
import AppointmentsIcon from 'components/Campaigns/Appointments.svg'
import LeadsIcon from 'components/Campaigns/Leads.svg'
import DownloadIcon from 'components/Campaigns/Download.svg'
import WebinarIcon from 'components/Campaigns/Webinar.svg'

function ObjectiveSelector({
	setObjectiveIndex,
	objective_index,
	hide_events,
	call_to_action,
	setCallToAction,
	disabled,
}) {
	const OBJECTIVES = [
		{
			index: 0,
			icon: EventsIcon,
			string: 'Host an Event',
			string_short: 'Events',
			call_to_action_default: "You're exclusively invited to attend",
		},
		{
			index: 1,
			icon: AppointmentsIcon,
			string: 'Schedule Appointments',
			string_short: 'Appointments',
			call_to_action_default: '',
		},
		{
			index: 2,
			icon: LeadsIcon,
			string: 'Gather Leads',
			string_short: 'Leads',
			call_to_action_default: '',
		},
		{
			index: 3,
			icon: DownloadIcon,
			string: 'File Download',
			string_short: 'Download',
			call_to_action_default: 'Download Our Complimentary Report',
		},
		{
			index: 5,
			icon: WebinarIcon,
			string: 'Webinar',
			string_short: 'Webinar',
			call_to_action_default: 'Watch Our Online Webinar',
		},
	]

	if (hide_events) {
		OBJECTIVES.splice(
			OBJECTIVES.findIndex((o) => o.index === 0),
			1,
		)
	}

	const objective_option = OBJECTIVES.find((o) => o.index === objective_index) || { index: -1 }

	const confirmAndSetCallToAction = (call_to_action_default) => {
		if (setCallToAction) {
			if (!call_to_action) {
				setCallToAction(call_to_action_default)
			} else if (call_to_action.length || call_to_action_default.length) {
				/* eslint-disable no-undef */
				swal({
					text: `Set text above headline to default (${
						call_to_action_default.length ? call_to_action_default : 'blank'
					})?`,
					showCancelButton: true,
					confirmButtonText: 'Yes',
					cancelButtonText: 'No',
					confirmButtonClass: 'btn btn-success d-inline',
					cancelButtonClass: 'btn btn-default d-inline',
					buttonsStyling: false,
				}).then(() => setCallToAction(call_to_action_default))
				/* eslint-enable no-undef */
			}
		}
	}

	return (
		<div className="py-3 d-flex  align-items-center">
			<CircleImage size="4rem">
				<img src={objective_option.icon} style={{ height: '2.6rem' }} alt="" />
			</CircleImage>
			<div className="ml-3">
				<span className="font-weight-bold">{objective_option.string ? 'Objective' : ''}</span>
				<div>{objective_option.string_short}</div>
				{!disabled && (
					<DropDownMenu
						menu_style={{ right: 0, left: 'initial' }}
						Button={
							<span>{objective_option.string ? 'Change Campaign Type' : 'Select Campaign Type'}</span>
						}
					>
						<ul className="list-unstyled">
							{OBJECTIVES.map((option) => (
								<li key={option.index}>
									<button
										type="button"
										className="btn-unstyled"
										onClick={() => {
											setObjectiveIndex(option.index)
											confirmAndSetCallToAction(option.call_to_action_default)
										}}
									>
										<img
											src={option.icon}
											style={{
												height: '2rem',
												marginRight: '20px',
											}}
											alt=""
										/>
										{option.string}
									</button>
								</li>
							))}
						</ul>
					</DropDownMenu>
				)}
			</div>
		</div>
	)
}

ObjectiveSelector.propTypes = {}

export default ObjectiveSelector
