/**
 *
 *  CmdK/GlobalSearch
 *
 */

import React, { memo, useEffect } from 'react'
// NPM Libraries
// import PropTypes from 'prop-types'
// import styled from '@emotion/styled'
import { useHistory } from 'react-router-dom'
import { useIdentifier } from '@joeyparis/hooks'

import { Command } from 'cmdk'

// GraphQL
import useQuery from 'components/UseQuery'
import { gql } from '@apollo/client'

import { FormattedMessage } from 'react-intl'
import messages from './messages'

const GLOBAL_SEARCH = gql`
	query GlobalSearch($search: String!) {
		global_search(search: $search)
	}
`

function GlobalSearch({ search, setLoading, onSelect }) {
	const history = useHistory()
	const id = useIdentifier()

	const { data, loading } = useQuery(GLOBAL_SEARCH, {
		variables: {
			search,
		},
		default_data: {
			global_search: [],
		},
		skip: !search || search.length < 3,
	})

	console.info({data})

	useEffect(() => {
		setLoading(id, loading)
	}, [loading])

	useEffect(() => {
		return () => {
			setLoading(id, false)
		}
	}, [])

	return (
		<Command.Group heading="Global Search">
			{data.global_search.map((object) => (
				<Command.Item
					key={object.id}
					value={`${search}`}
					onSelect={() => {
						history.push(object.link)
						if (onSelect) onSelect()
					}}
				>
					<div>
						{object.label}<br />
						<small>{object.id}</small>
					</div>
				</Command.Item>
			))}
		</Command.Group>
	)
}

GlobalSearch.propTypes = {}

export default memo(GlobalSearch)
