/**
 *
 * AccountPreferences/AppointmentDuration
 *
 */

import React, { useState } from 'react'

// GraphQL
import { gql, useMutation } from '@apollo/client'

export const APPOINTMENT_DURATION_OPTIONS = [
	{
		value: 900,
		text: '15 minutes',
	},
	{
		value: 1800,
		text: '30 minutes',
	},
	{
		value: 2700,
		text: '45 minutes',
	},
	{
		value: 3600,
		text: '1 hour',
	},
	{
		value: 5400,
		text: '1 hour 30 minutes',
	},
	{
		value: 7200,
		text: '2 hours',
	},
]

const UPDATE_APPOINTMENT_DURATION = gql`
	mutation updateAppointmentDuration($id: ID!, $appointment_duration: Int) {
		updateUser(input: { id: $id, appointment_duration: $appointment_duration }) {
			user {
				id
				appointment_duration
			}
		}
	}
`

function AppointmentDuration({ user_id, appointment_duration }) {
	const [duration, setDuration] = useState(Number(appointment_duration))
	const [updateAppointmentDuration] = useMutation(UPDATE_APPOINTMENT_DURATION)

	return (
		<div className="my-2">
			<p className="text-muted mb-4" style={{ fontSize: '14px' }}>
				Default Appointment Duration
			</p>

			<div
				style={{
					background: '#EAEEF5',
					borderRadius: '4px',
					padding: '10px 15px',
				}}
			>
				<select
					name="appointment_duration"
					value={duration}
					onChange={(e) => {
						setDuration(e.target.value)
						updateAppointmentDuration({
							variables: {
								id: user_id,
								appointment_duration: Number(e.target.value),
							},
						})
					}}
					style={{
						background: 'inherit',
						border: 'none',
						fontSize: 'smaller',
						width: '11rem',
					}}
				>
					{APPOINTMENT_DURATION_OPTIONS.map((opt) => (
						<option key={opt.value} value={opt.value} style={{ fontSize: '1rem' }}>
							{opt.text}
						</option>
					))}
				</select>
			</div>
		</div>
	)
}

AppointmentDuration.propTypes = {}

export default AppointmentDuration
