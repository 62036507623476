/**
 *
 *  /NotSupportedBrowserModal
 *
 */

import React from 'react'

// NPM Libraries
import styled from '@emotion/styled'
import { useStateAndCookie } from 'persistence-hooks'
import { browserName } from 'react-device-detect'

// Components
import BraveIcon from 'images/browsers/brave-icon.png'
import ChromeIcon from 'images/browsers/chrome-icon.png'
import FirefoxIcon from 'images/browsers/firefox-icon.png'
import FullModal from 'components/FullModal'
import messages from './messages'

const Container = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`

const BrowserLink = ({ icon, name, url }) => (
	<a href={url} target="_blank" rel="noreferrer noopener" className="mx-3 text-center">
		<img src={icon} title={`Download ${name} Browser`} alt={`${name} icon`} style={{ width: 55, height: 55 }} />
		<span className="d-block mt-1">Download {name}</span>
	</a>
)

function NotSupportedBrowserModal({ user_id }) {
	const key = `${user_id}_display_not_supported_browser_modal`
	const is_supported_browser = browserName === 'Chrome' || browserName === 'Firefox'

	const [hide_modal, setHideModal] = useStateAndCookie(false, key, { days: 1 })

	if (!user_id || is_supported_browser) return null

	const handleAction = (days = 1) => {
		setHideModal(true, undefined, { days })
	}

	return (
		<FullModal show={!hide_modal} modalDidClose={handleAction} style={{ overflow: 'hidden' }}>
			<Container>
				<h3>{messages.header}</h3>
				<span className="w-50 mt-2 mb-4 text-center">{messages.body}</span>
				<div className="d-flex">
					<BrowserLink name="Chrome" icon={ChromeIcon} url="https://google.com/intl/en_us/chrome/" />
					<BrowserLink name="Brave" icon={BraveIcon} url="https://brave.com/download/" />
					<BrowserLink name="Firefox" icon={FirefoxIcon} url="https://mozilla.org/en-US/firefox/new/" />
				</div>
				<div className="mt-5">
					<button type="button" className="btn btn-info btn-sm mr-3" onClick={handleAction}>
						Remind me tomorrow.
					</button>
					<button type="button" className="btn btn-danger btn-sm" onClick={() => handleAction(365)}>
						Dont remind me again.
					</button>
				</div>
			</Container>
		</FullModal>
	)
}

NotSupportedBrowserModal.propTypes = {}

export default NotSupportedBrowserModal
