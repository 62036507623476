/**
 *
 * CampaignTemplates/Selector
 *
 */

import React, { memo, useMemo, useState, useEffect } from 'react'

// NPM Libraries
import { useHistory } from 'react-router-dom'
import { useSet } from 'hooks'
import { titleize } from 'components/utils'
import styled from '@emotion/styled'

// GraphQL
import useQuery from 'components/UseQuery'
import { gql } from '@apollo/client'

// Components
import CampaignTemplatesShow from 'components/CampaignTemplates/Show'
import Gallery from 'components/Gallery'
import { Campaign } from 'globalVars'
import Modal from 'components/Modal'

import ErrorBoundary from 'components/ErrorBoundary'

export const GET_CAMPAIGN_TEMPLATES = gql`
	query getTemplates($always_on: Boolean) {
		campaign_templates(always_on: $always_on) {
			id
			name
			objective
			topic
			topic_to_s
			headline
			description
			description2
			disclaimer_json
			highlights
			questions
			landing_page_background_url
			landing_page_background_id
			campaign_image_url
			is_meal_event
		}
	}
`

const LI = styled.li`
	display: flex;

	> span {
		flex: 1;

		cursor: pointer;
	}

	> label {
		flex: 4;

		display: flex;
		align-items: start;

		input {
			margin-right: 0.5rem;
		}

		cursor: pointer;
	}

`

function TemplateSelector({ campaign, setAppliedTemplate }) {
	const history = useHistory()
	const {
		data: { campaign_templates },
		loading,
	} = useQuery(GET_CAMPAIGN_TEMPLATES, {
		variables: {
			always_on: campaign.always_on,
		},
		default_data: { campaign_templates: [] },
	})

	const [template_to_apply, setTemplateToApply] = useState({})
	const [keys_to_apply, addKeyToApply, removeKeyToApply, setKeysToApply] = useSet([])

	useEffect(() => {
		if (template_to_apply.id) {
			setKeysToApply(Object.keys(template_to_apply).filter((key) => template_to_apply[key] !== null))
		}
	}, [template_to_apply])

	/* eslint-disable indent */
	const objective_filter_options = campaign.always_on
		? {
				1: 'Appointments',
				3: 'Download',
				5: 'Webinar',
		  }
		: {
				0: 'Events',
				1: 'Appointments',
				3: 'Download',
				5: 'Webinar',
		  }
	/* eslint-enable indent */

	const applyTemplate = (template) => {
		const applied_template = { ...campaign }
		Array.from(keys_to_apply).forEach((key) => {
			applied_template[key] = template[key]
		})
		setAppliedTemplate(applied_template)
	}

	const initial_filter_key = String(campaign?.objective || Campaign.OBJECTIVE_EVENTS)

	const templates_prop = useMemo(
		() =>
			campaign_templates.map((template) => ({
				id: template.id,
				filter_key: String(template.objective),
				original: template,
				show: <CampaignTemplatesShow campaign_template={template} />,
				content_below: (
					<div>
						{template.name}
						<br />
						Topic: {template.topic_to_s}
						<br />
						{Campaign.OBJECTIVES[template.objective]}
						<br />
						<button
							type="button"
							onClick={() => setTemplateToApply(template)}
							className="text-warning btn-unstyled mt-1"
						>
							Select Template
						</button>
					</div>
				),
				visible: template.objective === initial_filter_key,
			})),
		[JSON.stringify(campaign_templates)],
	)

	const goToCampaign = () => history.push(`/campaigns/${campaign.id}#edit`)

	const keys = useMemo(() => {
		const keys = new Set()
		Object.keys(template_to_apply).forEach((key) => {
			if ([
				'__typename',
				'id',
				'name',
				'is_meal_event',
				'topic_to_s',
			].includes(key)) {
				return
			}

			if (campaign[key] || template_to_apply[key]) {
				keys.add(key)
			}
		})
		return Array.from(keys)
	}, [template_to_apply, campaign])

	return (
		<React.Fragment>
			<Gallery
				title="Choose Template for Your Campaign"
				filter_options={objective_filter_options}
				initial_filter_key={initial_filter_key}
				search_keys={['name', 'headline', 'description', 'topic_to_s']}
				startFromScratch={history.location.pathname.startsWith('/campaign') ? undefined : goToCampaign}
				templates={templates_prop}
				loading={loading}
			/>
			<Modal
				show={Boolean(template_to_apply.id)}
				modalDidClose={() => setTemplateToApply({})}
				style={{
					width: '90%'
				}}
			>
				<ul>
					<LI>
						<span><strong>Field</strong></span>
						<label><strong>Existing</strong></label>
						<label><strong>Template</strong></label>
					</LI>
					{keys.map((key) => (
						<LI key={key}>
							<span>{titleize(key)}</span>
							<label>
								<input
									type="checkbox"
									value={key}
									checked={!keys_to_apply.has(key)}
									onChange={(e) => (!e.target.checked ? addKeyToApply(key) : removeKeyToApply(key))}
								/>
								<span className={!keys_to_apply.has(key) ? 'font-weight-bold' : ''}>
									<ErrorBoundary message="Failed to render field, check cautiously.">
										<TemplateCheckboxPreview $key={key} item={campaign[key]} items={campaign} />
									</ErrorBoundary>
								</span>
							</label>
							<label>
								<input
									type="checkbox"
									value={key}
									checked={keys_to_apply.has(key)}
									onChange={(e) => (e.target.checked ? addKeyToApply(key) : removeKeyToApply(key))}
								/>
								<span className={keys_to_apply.has(key) ? 'font-weight-bold' : ''}>
									<ErrorBoundary message="Failed to render field, check cautiously.">
										<TemplateCheckboxPreview $key={key} item={template_to_apply[key]} items={template_to_apply} />
									</ErrorBoundary>
								</span>
							</label>
						</LI>
					))}
				</ul>
				<button type="submit" className="btn btn-primary" onClick={() => applyTemplate(template_to_apply)}>
					Apply Template
				</button>
			</Modal>
		</React.Fragment>
	)
}

const TemplateCheckboxPreview = ({ $key, item, items }) => {
	if ($key === 'topic') {
		return items.topic_to_s
	}

	if ($key.includes('json')) {
		return item?.blocks?.map((b) => b.text).join("\r\n") || ''
	}

	if (Array.isArray(item)) {
		return (
			<ul>
				{item.map((subitem, index) => <li key={index}>{subitem}</li>)}
			</ul>
		)
	}

	if (typeof x === 'object' && !Array.isArray(x) && x !== null) {
		return <pre>
			{JSON.stringify(item, null, 2)}
		</pre>
	}

	return item
}

TemplateSelector.propTypes = {}

export default memo(TemplateSelector)
