/**
 *
 * CampaignTemplates/Show
 *
 */

import React, { memo, useRef } from 'react'

// NPM Libraries
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import FloatingLabel from '@joeyparis/react-floating-label-input'

// Components
import ActivityAlert from 'components/CampaignTemplates/Show/ActivityAlert'
import AdvisorContact from 'components/CampaignTemplates/Show/AdvisorContact'
import Carousel from 'components/CampaignTemplates/Show/Carousel'
import Countdown from 'components/CampaignTemplates/Show/Countdown'
import Disclaimer from 'components/CampaignTemplates/Show/Disclaimer'
import Events from 'components/CampaignTemplates/Show/Events'
import ErrorBoundary from 'components/ErrorBoundary'
import Profiles from 'components/CampaignTemplates/Show/Profiles'
import Map from 'components/CampaignTemplates/Show/Map'

// Utils
import DefaultImage from './Header.png'
import { Campaign } from 'globalVars'

export const LandingPage = styled('div')`
	background: #fff;
	.nav {
		background: #fff;
		min-height: 3.5em;
	}
	.form {
		width: 300px;
		margin: 20px auto;
		text-align: left;
	}
	.calendly-form {
		width: 300px;
		height: 500px;
		margin: 20px auto;
		text-align: center;
		background: #fff;
	}
	.campaign-info {
		font-size: larger;
	}
`

// Mimics landing page layout so user can preview template or preferences

function Show({ campaign_template: campaign, advisor, landing_page_config, events, activity_alert_messages }) {
	const ref = useRef()

	let submit_button_text
	switch (campaign.objective) {
		case Campaign.OBJECTIVE_APPOINTMENTS:
			submit_button_text = 'Schedule Appointment'
			break
		case Campaign.OBJECTIVE_DOWNLOAD:
			submit_button_text = 'Click to Access'
			break
		case Campaign.OBJECTIVE_WEBINAR:
			submit_button_text = 'Watch Now'
			break
		default:
			submit_button_text = 'Click to Register'
	}

	return (
		<div
			className="position-relative"
			ref={ref}
			style={{
				paddingBottom: advisor?.activity_alert ? 100 : 0,
				background: '#fff',
				fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
			}}
		>
			{advisor?.activity_alert && (
				<ActivityAlert activity_alert_messages={activity_alert_messages} parent_ref={ref} />
			)}
			<LandingPage className="container-fluid">
				<div className="nav row">
					{advisor?.company_logo && (
						<div className="col-sm-3 col text-center">
							<img
								className="m-3"
								src={`${advisor?.company_logo}?t=${new Date().getTime()}`}
								alt={advisor?.company_name}
								// onError={() => setHideCompanyLogo(true)}
								style={{
									maxHeight: 75,
									maxWidth: '100%',
								}}
							/>
						</div>
					)}
					<div className="col-sm-8 col-12 float-right">
						<ul className="row list-unstyled h-100 d-flex justify-content-around align-items-center">
							<li className="text-dark">What You'll Learn</li>
							<li className="text-dark">About Us</li>
						</ul>
					</div>
				</div>
				<header
					className="header row py-4"
					style={{
						background: `url(${campaign.landing_page_background_url || DefaultImage}) center / cover`,
					}}
				>
					<div className="col-12">
						<h3 className="text-center text-white mx-4 mb-4">{campaign.headline}</h3>

						{events && (
							<div className="row">
								<div className="col-10 offset-1">
									<Events
										events={events}
										allow_appointment_registration={landing_page_config.allow_appointments}
									/>
								</div>
							</div>
						)}

						<div className="row">
							{campaign.objective === Campaign.OBJECTIVE_DOWNLOAD && (
								<div className="col-sm-5 col-12 text-sm-right text-center">
									<img
										className={`img-fluid ${!!campaign.campaign_image_url && 'mt-4'}`}
										src={
											campaign.campaign_image_url ||
											`https://leadjig-email-templates.s3.amazonaws.com/images/57b2502cfddc1b7242018d60/DRC%20Images/${campaign.id}.png`
										}
										alt=""
									/>
								</div>
							)}

							<div className="col">
								{campaign.objective === Campaign.OBJECTIVE_APPOINTMENTS && advisor?.calendly_url ? (
									<div className="calendly-form">Advisor's Calendly form</div>
								) : (
									<form className="form">
										<div className="fl-input-group">
											<FloatingLabel label="First Name" containerStyle={{ width: '50%' }} dark />
											<FloatingLabel label="Last Name" containerStyle={{ width: '50%' }} dark />
										</div>
										{landing_page_config.phone_field > 0 && (
											<FloatingLabel label="Mobile Phone" dark />
										)}
										{landing_page_config.email_field > 0 && <FloatingLabel label="Email" dark />}
										{landing_page_config.address_field > 0 && (
											<FloatingLabel label="Address" dark />
										)}
										{(advisor.default_campaign_questions || []).map((question, index) => (
											<FloatingLabel key={`question_${index}`} label={question} dark />
										))}
										{advisor?.default_opt_in_text && (
											<div className="alert alert-info mt-4">
												<input type="checkbox" className="mr-2" />
												{advisor.default_opt_in_text}
											</div>
										)}
										{landing_page_config.note_field === 1 && (
											<div className="text-center">
												<div className="btn text-white">
													<strong>
														<u>Add a note</u>
													</strong>
												</div>
											</div>
										)}
										<button
											type="button"
											className="btn btn-block btn-lg btn-warning rounded text-white mt-4 py-4"
										>
											{submit_button_text}
										</button>
									</form>
								)}
							</div>
						</div>
					</div>
				</header>

				{landing_page_config?.modules?.map((module_name) => {
					if (module_name === 'description')
						return (
							<div key={module_name} className="row pt-3" id="learn">
								<div className="col text-left">
									<ReactMarkdown source={campaign.description} />
								</div>
							</div>
						)

					if (module_name === 'description2')
						return (
							<div key={module_name} className="row pt-3">
								<div className="col text-left">
									<ReactMarkdown source={campaign.description2} />
								</div>
							</div>
						)

					if (module_name === 'highlights')
						return (
							<div className="row campaign-info pt-3" key={module_name}>
								<div className="col-11 mx-auto">
									<ul className="row list-unstyled">
										{campaign.highlights?.map((highlight) => (
											<li key={highlight} className="align-top col-md-6 col-12">
												<div className="row">
													<div className="col-2 text-right">
														<i
															className="fas fa-check-circle"
															style={{ color: '#00A0D6' }}
														></i>
													</div>
													<div className="col-10 text-left">
														<ReactMarkdown source={highlight} />
													</div>
												</div>
											</li>
										))}
									</ul>
								</div>
							</div>
						)

					if (module_name === 'profiles') return <Profiles advisor={advisor} key={module_name} />

					if (module_name === 'carousel')
						return <Carousel images={campaign.carousel_images} key={module_name} />

					if (module_name === 'company_info') return <AdvisorContact advisor={advisor} key={module_name} />

					if (module_name === 'map')
						return (
							<ErrorBoundary key={module_name}>
								<Map events={events} advisor={advisor} />
							</ErrorBoundary>
						)

					if (module_name === 'countdown')
						return (
							<Countdown
								campaign={campaign}
								next_event_date={events && events[0]?.start_time}
								key={module_name}
							/>
						)

					return null
				})}
				{advisor && <Disclaimer advisor={advisor} />}
			</LandingPage>
		</div>
	)
}

Show.propTypes = {
	campaign_template: PropTypes.object,
	advisor: PropTypes.object,
	landing_page_config: PropTypes.object,
	events: PropTypes.array,
	activity_alert_messages: PropTypes.array,
}

Show.defaultProps = {
	advisor: {
		profiles: [],
		company_address: {
			components: {},
		},
	},
	landing_page_config: {
		field_address: 0,
		field_email: 1,
		field_phone: 1,
		modules: ['description', 'highlights'],
	},
	campaign_template: {},
}

export default memo(Show)
