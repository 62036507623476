/**
 *
 * Preferences/OptIns/Required
 *
 */

import React from 'react'
import DropDownMenu from 'components/DropDownMenu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { OptIn } from 'globalVars'

function Required({ handle_opt_in, onChange, opt_in, disabled }) {
	const options = [
		{ text: 'Opt-in is required for registration', value: OptIn.REQUIRED },
		{ text: 'Allow campaign communication; block custom emails', value: OptIn.BLOCK_ALL_AUTOMATIONS },
		{
			text: 'Allow campaign communication; block custom emails about the campaign topic',
			value: OptIn.BLOCK_CAMPAIGN_TOPIC,
		},
		{ text: 'Block all emails and text messages', value: OptIn.BLOCK_ALL_COMMUNICATION },
	]

	if (!opt_in.global_default) {
		options.push({ text: 'Proceed as normal', value: 0 })
	}

	const selected_option = options.find((option) => option.value === handle_opt_in)

	return (
		<div className="mb-3 d-flex flex-wrap">
			<div className="mr-2">
				If a contact does <em>not</em> accept the opt-in:
			</div>
			<DropDownMenu
				Button={
					<div className="text-left">
						{selected_option?.text} <FontAwesomeIcon icon="caret-down" className="text-decoration-none" />
					</div>
				}
				disabled={disabled}
			>
				<ul className="list-unstyled" style={{ width: 400 }}>
					{options.map((option) => (
						<li key={option.value} onClick={() => onChange(option.value)} style={{ whiteSpace: 'normal' }}>
							{option.text}
						</li>
					))}
				</ul>
			</DropDownMenu>
		</div>
	)
}

Required.propTypes = {}

export default Required
