import _ from 'lodash'

const decorateColumn = (column) => {
	// Set default values
	let decorated_column = {
		sortable: true,
	}
	decorated_column = { ...decorated_column, ...column }

	// Ensure minWidth is not greater than maxWidth if set
	if (decorated_column.maxWidth < decorated_column.minWidth) {
		decorated_column.minWidth = decorated_column.maxWidth
	}

	// First check for string accessor
	if (typeof decorated_column.accessor === 'string') {
		decorated_column.id = decorated_column.id || decorated_column.accessor
		const accessor_string = decorated_column.accessor
		decorated_column.accessor = (row) => _.get(row, accessor_string)
	}

	// Fall back to functional accessor (but require an ID)
	if (decorated_column.accessor && typeof decorated_column.accessor !== 'string' && !decorated_column.id) {
		console.warn(decorated_column)
		throw new Error('A column id is required if using a non-string accessor for column above.')
	}

	// Fall back to an undefined accessor
	if (!decorated_column.accessor) {
		decorated_column.accessor = () => undefined
	}

	// Set the sort key if not already provided
	decorated_column.sort_key = decorated_column.sort_key || decorated_column.id

	return decorated_column
}

export default decorateColumn
