/**
 *
 *  /useTooltip
 *
 */

import React, { useEffect, createContext, useContext, useCallback } from 'react'

// NPM Libraries
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

// id: 'context_tooltip',
const default_props = {
	id: 'context-tooltip',
	effect: 'solid',
	place: 'top',
}

export const TooltipContext = createContext([])

export const TooltipProvider = ({ children }) => {
	useEffect(() => {
		buildTooltip()
	}, [buildTooltip])

	const buildTooltip = useCallback(() => {
		ReactTooltip.rebuild()
		ReactTooltip.hide()
	}, [])

	return (
		<TooltipContext.Provider value={[buildTooltip]}>
			{children}
			<ReactTooltip {...default_props} />
		</TooltipContext.Provider>
	)
}

TooltipProvider.propTypes = {
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
}

const useTooltip = () => useContext(TooltipContext)

export default useTooltip
