/**
 *
 * /AddressInput
 *
 */

import React, { memo, useEffect, useRef } from 'react'

// NPM Libraries
import PropTypes from 'prop-types'
import { useHandleInputChange } from 'hooks'

// Components
import FloatingLabel from '@joeyparis/react-floating-label-input'

function AddressInput({
	handleInputChange,
	address,
	address_apt,
	address_apt_target_name,
	address_place_id_target_name,
	address_target_name,
	address_components = {},
	address_components_target_name,
	// coordinates = [],
	coordinates_target_name,
	required,
	...floating_label_props // such as disabled, dark, transparent, input_style
}) {
	const address_ref = useRef()
	const address_apt_ref = useRef()
	const autocomplete = useRef()

	/* eslint-disable indent */
	const [components_state, handleComponentsInputChange, setComponents] = useHandleInputChange(
		address_components
			? {
					...address_components,
					street_address:
						address_components.street_address ||
						(address_components.street_number &&
							`${address_components.street_number} ${address_components.street}`),
			  }
			: {},
	)
	/* eslint-enable indent */

	// update address components of parent
	useEffect(() => {
		handleInputChange({
			target: {
				value: components_state,
				name: address_components_target_name,
			},
			preventDefault: () => {},
		})
	}, [JSON.stringify(components_state)])

	const setContactPlaceId = () => {
		const place = autocomplete.current.getPlace()

		if (place.address_components) {
			setComponents(getComponents(place))
		}

		if (typeof place.formatted_address !== 'undefined')
			handleInputChange({
				target: {
					value: place.formatted_address,
					name: address_target_name,
				},
				preventDefault: () => {},
			})

		handleInputChange({
			target: {
				value: place.place_id || '',
				name: address_place_id_target_name,
			},
			preventDefault: () => {},
		})

		handleInputChange({
			target: {
				value: [place.geometry?.location?.lat(), place.geometry?.location?.lng()],
				name: coordinates_target_name || 'coordinates',
			},
			preventDefault: () => {},
		})
	}

	const getComponents = (place) => {
		const components = {}
		place.address_components?.forEach((component) => {
			const name = component.types[0]
			components[name] = component.short_name
		})

		return {
			street_number: components.street_number,
			street: components.street || components.route,
			street_address: `${components.street_number || ''} ${components.street || components.route || ''}`.trim(),
			city:
				components.city ||
				components.sublocality_level_1 ||
				components.locality ||
				components.administrative_area_level_3,
			state: components.state || components.administrative_area_level_1,
			zip: components.zip || components.postal_code,
		}
	}

	/*
	const geolocate = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position) => {
				const geolocation = {
					lat: position.coords.latitude,
					lng: position.coords.longitude,
				}

				const circle = new window.google.maps.Circle({
					center: geolocation,
					radius: position.coords.accuracy,
				})

				autocomplete.current.setBounds(circle.getBounds())
			})
		}
	}
	*/

	useEffect(() => {
		const google_places_autocomplete_interval = setInterval(() => {
			if (window.google) {
				console.info('Google Places Autocomplete ready!')
				clearInterval(google_places_autocomplete_interval)

				// Create the autocomplete object, restricting the search to geographical
				// location types.
				autocomplete.current = new window.google.maps.places.Autocomplete(
					/** @type {!HTMLInputElement} */ (address_ref.current),
					{ types: ['geocode'] },
				)

				// When the user selects an address from the dropdown, populate the address
				// fields in the form.
				autocomplete.current.addListener('place_changed', setContactPlaceId)

				// Prevent the form from submitting when a user hits enter on the suggestion list
				address_ref.current.addEventListener('keydown', (e) => {
					if (e.keyCode === 13) {
						e.preventDefault()
					}

					return false
				})
			} else {
				console.info('Google Places Autocomplete not ready.')
			}
		}, 50)

		return () => {
			if (google_places_autocomplete_interval) {
				clearInterval(google_places_autocomplete_interval)
			}
		}
	}, [])

	return (
		<div>
			<input id="address" name="address" style={{ display: 'none' }} />
			<div className="d-flex flex-wrap">
				<FloatingLabel
					input_ref={address_ref}
					type="text"
					label="Street Address"
					name="street_address"
					value={components_state.street_address || ''}
					onChange={handleComponentsInputChange}
					show_bar={false}
					required={required || !!address_apt}
					containerStyle={{ minWidth: 260, flex: '2 0' }}
					prevent_autofill
					{...floating_label_props}
				/>
				<FloatingLabel
					input_ref={address_apt_ref}
					type="text"
					name="apt"
					label="Apt"
					value={address_apt || ''}
					onChange={(e) => {
						handleInputChange({
							target: {
								name: address_apt_target_name,
								value: e.target.value,
							},
							preventDefault: () => {},
						})
					}}
					show_bar={false}
					containerStyle={{ minWidth: 160, flex: '1 0' }}
					{...floating_label_props}
				/>
			</div>
			<div className="d-flex flex-wrap">
				<FloatingLabel
					type="text"
					label="City"
					name="city"
					value={components_state?.city || ''}
					onChange={handleComponentsInputChange}
					containerStyle={{ minWidth: 220, flex: '2 0' }}
					{...floating_label_props}
					required={required}
				/>
				<div className="fl-input-group d-flex" style={{ minWidth: 220, flex: '1 0' }}>
					<FloatingLabel
						type="text" // make this a dropdown?
						label="State"
						name="state"
						value={components_state?.state || ''}
						onChange={handleComponentsInputChange}
						containerStyle={{ minWidth: 100, flex: '1 0' }}
						{...floating_label_props}
						required={required}
					/>
					<FloatingLabel
						type="text"
						label="Zip"
						name="zip"
						value={components_state?.zip || ''}
						onChange={handleComponentsInputChange}
						containerStyle={{ minWidth: 130, flex: '1 0' }}
						{...floating_label_props}
						required={required}
					/>
				</div>
			</div>
		</div>
	)
}

AddressInput.propTypes = {
	handleInputChange: PropTypes.func.isRequired,
	address: PropTypes.string,
	address_apt: PropTypes.string,
	address_apt_target_name: PropTypes.string,
	address_place_id_target_name: PropTypes.string,
	address_target_name: PropTypes.string,
	dark: PropTypes.bool,
	disabled: PropTypes.bool,
	input_style: PropTypes.objectOf(PropTypes.any),
	required: PropTypes.bool,
	transparent: PropTypes.bool,
}

export default memo(AddressInput)
