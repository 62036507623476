/**
 *
 * ImagePreview
 *
 */

import React from 'react'

// NPM Libraries
import { Global, css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withState } from 'recompose'

// Components
import Modal from 'components/Modal'

const styles = css`
	.image_preview {
		max-height: 250px;
		border: none;
		padding: 0;
		height: 100%;
		width: 100%;
		background: transparent;

		img {
			max-height: 250px;
			max-width: 100%;
		}

		.image_preview_overlay {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;

			background-color: #fff;

			opacity: 0;
			transition: opacity 250ms;
		}

		.image_preview_icon {
			opacity: 0;
			transition: opacity 250ms;
		}

		&:hover {
			.image_preview_overlay {
				opacity: 0.25;
			}

			.image_preview_icon {
				opacity: 1;
			}
		}
	}
`

function ImagePreview({ thumbnail_src, src, alt, show_preview, setShowPreview }) {
	return (
		<div
			className="text-center p-relative h-100"
			style={{ background: `center / cover no-repeat url(${thumbnail_src || src})` }}
		>
			<Global styles={styles} />
			<button type="button" className="image_preview" onClick={() => setShowPreview(true)}>
				<div className="image_preview_overlay" />
				<FontAwesomeIcon icon="search" className="image_preview_icon align-middle-center" size="2x" />
			</button>
			<Modal
				show={show_preview}
				modalDidClose={() => setShowPreview(false)}
				style={{
					padding: '1rem',
					width: '90%',
					maxHeight: '100%',
				}}
			>
				<div className="text-center">
					<img
						src={src}
						alt={alt}
						style={{
							maxHeight: '100%',
							maxWidth: '100%',
						}}
					/>
				</div>
			</Modal>
		</div>
	)
}

ImagePreview.propTypes = {}

export default withState('show_preview', 'setShowPreview', false)(ImagePreview)
