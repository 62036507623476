/* eslint-disable no-extend-native */
/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 *
 *
 */

window.Buffer = require('buffer/').Buffer

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import 'configureSentry'
import * as Sentry from '@sentry/react'
import 'configureAnalytics'

import React from 'react'
import ReactDOM from 'react-dom/client'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import history from 'utils/history'
import 'sanitize.css/sanitize.css'

import 'configureAxios'

// Import root app
import App from 'containers/App'

// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider'

// Import GraphQL Provider
import { ApolloProvider } from '@apollo/client'

import CmdK from 'components/CmdK'

// Load the favicon and the .htaccess file
/* eslint-disable import/no-unresolved */
import '!file-loader?name=[name].[ext]!./images/favicon.ico'
import 'file-loader?name=.htaccess!./.htaccess'
/* eslint-enable import/no-unresolved */

// Remove AmCharts branding
import * as am4core from '@amcharts/amcharts4/core'
am4core.addLicense('CH233332656')

// Import FontAwesome Icons
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import('@fortawesome/pro-solid-svg-icons').then(({ fas }) => library.add(fas))
import('@fortawesome/pro-regular-svg-icons').then(({ far }) => library.add(far))
import('@fortawesome/pro-light-svg-icons').then(({ fal }) => library.add(fal))
import('@fortawesome/free-brands-svg-icons').then(({ fab }) => library.add(fab))
// import { fas } from '@fortawesome/pro-solid-svg-icons'
// library.add(fas)
dom.watch()

import { HelmetProvider } from 'react-helmet-async'
import configureStore from './configureStore'

// Import i18n messages
import { translationMessages } from './i18n'

// Create redux store with history
const initialState = {}
const store = configureStore(initialState, history)
const MOUNT_NODE = document.getElementById('app')

// Moment default to UTC
import moment from 'moment-timezone'
moment.tz.setDefault('UTC')
import 'moment.distance'

Date.prototype.toDate = function () {
	return new Date(this)
}

import Mousetrap from 'mousetrap'
import CurrentUserProvider from './currentUserContext'
import { ScreenshotProvider } from 'hooks/useScreenshot'
import { ForceUpdateProvider } from 'hooks/useForceUpdate'
import { TooltipProvider } from 'hooks/useTooltip'
import client from './configureApolloClient'
Mousetrap.bind('up up down down left right left right b a enter', () => {
	const audio = new Audio('/public/KonamiCode.mp3')

	const egg = ReactDOM.createRoot(document.getElementById('egg'))

	audio.addEventListener(
		'canplay',
		() => {
			audio.play()
			egg.render(
				<img
					alt="Contra"
					style={{
						position: 'fixed',
						top: 5,
						left: 5,
						zIndex: 10000,
					}}
					src="/public/Contra.gif"
				/>
			)
		},
		false,
	)

	audio.addEventListener(
		'ended',
		() => {
			egg.unmount()
		},
		false,
	)
})

const ConnectedApp = Sentry.withProfiler((props) => (
	<Provider store={store}>
		<LanguageProvider messages={props.messages}>
			<ApolloProvider client={client}>
				<ConnectedRouter history={history}>
					<CurrentUserProvider>
						<HelmetProvider>
							<ScreenshotProvider>
								<ForceUpdateProvider>
									<TooltipProvider>
										<CmdK />
										<App />
									</TooltipProvider>
								</ForceUpdateProvider>
							</ScreenshotProvider>
						</HelmetProvider>
					</CurrentUserProvider>
				</ConnectedRouter>
			</ApolloProvider>
		</LanguageProvider>
	</Provider>
))

ConnectedApp.propTypes = {
	messages: PropTypes.object,
}

const root = ReactDOM.createRoot(MOUNT_NODE)

const render = (messages) => {
	root.render(<ConnectedApp messages={messages} />)
}

if (module.hot) {
	// Hot reloadable translation json files
	// modules.hot.accept does not accept dynamic dependencies,
	// have to be constants at compile-time
	module.hot.accept(['./i18n'], () => {
		root.unmount()
		render(translationMessages)
	})
}

render(translationMessages)

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
	require('offline-plugin/runtime').install() // eslint-disable-line global-require
}
