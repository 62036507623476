/**
 *
 * LandingPageBackgroundSelector
 *
 */

import React from 'react'
import { Global, css } from '@emotion/react'
// import { FormattedMessage } from 'react-intl'
// import messages from './messages'
import { gql } from '@apollo/client'

import ImagePreview from 'components/ImagePreview'
import ApolloClient from '../ApolloClient'

const styles = css`
	#landing_page_background_selector {
		padding-top: 5px;
		overflow: auto;

		input[type='radio'] {
			display: block;
			margin: 0.5em auto;
		}

		.image-container {
			width: 120px;
			margin: 5px;
			position: relative;
		}

		label {
			width: 100%;
			height: 75px;
		}

		label.selected {
			outline: 4px solid #16a1d4; /*bootstrap primary color*/
		}

		.use_default_message {
			width: 100%;
			height: 100%;
			color: #bbb;
			font-weight: bold;
			padding: 0 5px;
			text-align: center;
			border: 1px solid #bbb;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
`

class LandingPageBackgroundSelector extends React.Component {
	// eslint-disable-line react/prefer-stateless-function

	constructor(props) {
		super(props)
		this.state = {
			backgrounds: [],
		}
		this.client = new ApolloClient()
	}

	loadBackgrounds = () => {
		const GET_BACKGROUNDS = gql`
			{
				landing_page_backgrounds {
					id
					name
					image_url
					thumbnail_url
				}
			}
		`

		this.client.query({ query: GET_BACKGROUNDS }).then((r) => {
			if (r.data) {
				this.setState({ backgrounds: r.data.landing_page_backgrounds })
			}
		})
	}

	componentDidMount() {
		this.loadBackgrounds()
	}

	render() {
		return (
			<React.Fragment>
				<Global styles={styles} />
				<div id="landing_page_background_selector">
					<div className="form-text text-muted mb-2">Landing Page Background Image</div>

					<div className="d-flex flex-wrap">
						<div className="image-container">
							<label
								htmlFor="default"
								className={this.props.current_background_id === '' ? 'selected' : ''}
							>
								<div className="use_default_message">
									<span>Use Default Image</span>
								</div>
							</label>
							<input
								id="default"
								type="radio"
								name="landing_page_background_id"
								value=""
								onChange={this.props.handleInputChange}
								checked={this.props.current_background_id === ''}
								disabled={this.props.disabled}
							/>
						</div>
						{this.state.backgrounds &&
							this.state.backgrounds.map((background) => (
								<div key={background.id} className="image-container">
									<label
										htmlFor={background.id}
										className={this.props.current_background_id === background.id ? 'selected' : ''}
									>
										<ImagePreview src={background.image_url} thumbnail_src={background.thumbnail_url} alt={background.name} />
									</label>
									<input
										id={background.id}
										type="radio"
										name="landing_page_background_id"
										value={background.id}
										onChange={this.props.handleInputChange}
										checked={this.props.current_background_id === background.id}
										disabled={this.props.disabled}
									/>
								</div>
							))}
					</div>
				</div>
			</React.Fragment>
		)
	}
}

LandingPageBackgroundSelector.propTypes = {}

export default LandingPageBackgroundSelector
