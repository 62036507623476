/* eslint-disable */

// TODO: FIX ESLINT

const axios = require('axios')
// const pluralize = require('pluralize')
const phoneFormatter = require('phone-formatter')
const { saveAs } = require('file-saver')
const { format, getHours, getMinutes, setHours, setMinutes, isSameDay, isSameMonth, isSameYear } = require('date-fns')

export const uploadFiles = (options) =>
	new Promise((resolve, reject) => {
		let files = options.files || []
		if (FileList.name === files.constructor.name) files = [...files]
		if (options.file && File.name === options.file.constructor.name) files.push(options.file)

		if (!Array.isArray(files)) {
			reject(new Error('`files` attribute must be an array type or a FileList object.'))
			return
		}

		if (!options.url || !options.attribute || files.length < 1) {
			reject(new Error('URL, attribute, and file(s) are all required options.'))
			return
		}

		const form = new FormData()
		files.forEach((file) =>
			form.append(
				`${options.attribute}[]`,
				new Blob([file], {
					encoding: 'UTF-8',
					type: 'text/plain;charset=UTF-8',
				}),
			),
		)

		axios({
			method: 'POST',
			url: options.url,
			data: form,
		})
			.then((response) => {
				resolve(response)
			})
			.catch((err) => {
				reject(err)
			})

		// const request = new XMLHttpRequest()
		// request.open('POST', options.url)
		// request.send(form)
		// request.onload = () => {
		// 	if (request.status >= 200 && request.status < 300) {
		// 		resolve(request)
		// 		return
		// 	}
		// 	reject(request)
		// }
	})

export const saveFile = (data, file_name) => {
	const s2ab = (s) => {
		const buf = new ArrayBuffer(s.length)
		const view = new Uint8Array(buf)
		for (let i = 0; i !== s.length; i += 1) view[i] = s.charCodeAt(i) & 0xff
		return buf
	}

	saveAs(new Blob([s2ab(data)], { type: 'application/octet-stream' }), `${file_name}`)
}

export const queryVariables = (loc) => {
	const params = {}
	loc.search
		.substring(1)
		.split('&')
		.forEach((v) => {
			const pair = v.split('=')
			params[pair[0]] = decodeURIComponent(pair[1]) // eslint-disable-line prefer-destructuring
		})

	return params
}

export const sample = (arr) => arr[Math.floor(Math.random() * arr.length)]

/* eslint-disable prettier/prettier */
export const sanitizeDraftJsMap = (raw_content) => ({
	blocks: raw_content.blocks ? raw_content.blocks.map((block) => (block.text ? block : { ...block, text: '' })) : [],
	entityMap:
		raw_content.entityMap && raw_content.entityMap[0]
			? raw_content.entityMap
			: {
				data: raw_content.entityMap.data ? raw_content.entityMap.data : '',
				type: raw_content.entityMap.type ? raw_content.entityMap.type : '',
				mutability: raw_content.entityMap.mutability ? raw_content.entityMap.mutability : '',
			},
})
/* eslint-enable prettier/prettier */

export const formatPhoneNumber = (number) => {
	let number_string = number ? String(number) : number
	if (number) {
		number_string = number_string.replace(/^(1+|\+1+|0+)/g, '')
		number_string = phoneFormatter.format(number_string.replace(/\D/g, '').substr(0, 10), '(NNN) NNN-NNNN')
		number_string =
			number_string.indexOf('N') !== -1
				? number_string.substring(0, number_string.indexOf('N')).replace(/([()-\s]+$)/g, '')
				: number_string
	}

	return number_string
}

export const phoneToInteger = (formatted_phone) => Number(String(formatted_phone).replace(/\D/g, ''))

export const fontAwesomeIconToBlob = ({ icon, iconName, prefix }, props) => {
	const svg = `
		<svg
			aria-hidden="true"
			focusable="false"
			data-prefix="${prefix}"
			data-icon="${iconName}"
			class="svg-inline--fa fa-${iconName} fa-w-12"
			role="img"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 ${icon[0]} ${icon[1]}"
		>
			<path fill="${props.fill}" d="${icon[4]}"></path>
		</svg>
	`

	return new Blob([svg], { type: 'image/svg+xml' })
}

export const fontAwesomeIconToBlobURL = (font_awesome_icon, props) => {
	const blob = fontAwesomeIconToBlob(font_awesome_icon, props)
	return URL.createObjectURL(blob)
}

const parseCurrencyFloat = (string = '') => parseFloat(String(string).replace(/[^\d.\-e]/g, ''))

export const formatCurrency = (number_or_string, include_prefix = false, no_decimals = false) => {
	const formatted_currency = new Intl.NumberFormat('en', {
		style: 'currency',
		currency: 'USD',
		...(no_decimals
			? {
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
			  }
			: {}),
	}).format(parseCurrencyFloat(number_or_string))
	return !include_prefix ? formatted_currency.replace('$', '') : formatted_currency
}

export const flattenObjectArrays = (obj) => [...Object.keys(obj).map((k) => obj[k])]

export const titleize = (str) =>
	(str || '')
		.toString()
		.replace(/([._-])/g, ' ')
		.replace(/([A-Z._-])/g, ' $1')
		.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())

// doesn't add spaces between capital letters
export const titleizeConstantName = (str) =>
	(str || '')
		.toString()
		.replace(/([._-])/g, ' ')
		.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())

export const possessive = (string) => {
	if (string === '') {
		return string
	}

	const APOSTROPHE_CHAR = '’'
	const lastChar = string.slice(-1)
	const endOfWord = lastChar.toLowerCase() === 's' ? APOSTROPHE_CHAR : `${APOSTROPHE_CHAR}s`

	return `${string}${endOfWord}`
}

export const listToText = (words) => {
	switch (words.length) {
		case 1:
			return words[0]
		case 2:
			return words.join(' and ')
		default:
			const last = words[words.length - 1]
			words.pop()
			return `${words.join(', ')}, and ${last}`
	}
}

export const generateToken = () => Math.random().toString(36).substr(2)

// Not currently being used - would have to rewrite without moment.
// export const sortKeysByDayOfWeek = (obj) => {
// 	const sorted = {}
// 	Object.keys(obj)
// 		.sort((a, b) => moment(a, 'ddd dddd').weekday() > moment(b, 'ddd dddd').weekday())
// 		.forEach((key) => {
// 			console.log({key})
// 			sorted[key] = obj[key]
// 		})
// 	return sorted
// }

export const recurrenceRuleText = (recurrence_rule) => {
	console.log({ recurrence_rule })

	// const text = [
	// 	`Repeats every ${recurrence_rule.interval} ${pluralize(
	// 		recurrence_rule.frequency,
	// 		parseInt(recurrence_rule.interval, 10),
	// 	)}`,
	// ]
	// if (recurrence_rule.frequency === 'week') {
	// 	let days_of_week = Object.keys(recurrence_rule.days_of_week).filter((d) => recurrence_rule.days_of_week[d])

	// 	if (days_of_week.length) {
	// 		days_of_week = days_of_week.map((d) => titleize(d))
	// 		if (days_of_week.length > 2) {
	// 			const last_day = days_of_week.pop()
	// 			text.push(`on ${days_of_week.join(', ')}, and ${last_day}`)
	// 		} else if (days_of_week.length === 2) {
	// 			text.push(`on ${days_of_week.join(' and ')}`)
	// 		} else {
	// 			text.push(`on ${days_of_week[0]}`)
	// 		}
	// 	}
	// }
	// if (
	// 	recurrence_rule.will_end ||
	// 	(typeof recurrence_rule.will_end === 'undefined' && typeof recurrence_rule.end !== 'undefined')
	// )
	// 	text.push(`until ${format(recurrence_rule.end, 'MMMM do, yyyy')}`)
	// 	console.log('got here')

	// return text.join(' ')
}

export const formatDateRange = (start, end) => {
	if (isSameDay(start, end)) {
		return format(start, 'MMM d, yyyy')
	}
	if (isSameMonth(start, end)) {
		return `${format(start, 'MMM d')} - ${format(end, 'd, yyyy')}`
	}
	if (isSameYear(start, end)) {
		return `${format(start, 'MMM d')} - ${format(end, 'MMM d, yyyy')}`
	}
	return `${format(start, 'MMM d, yyyy')} - ${format(end, 'MMM d, yyyy')}`
}

export const toBoolean = (value) => {
	switch (typeof value === 'string' ? value.toLowerCase() : value) {
		case false:
		case 'false':
		case 'f':
		case 0:
		case '':
			return false
		default:
			return true
	}
}

export const optionsFromStrings = (string_array) => {
	if (Array.isArray(string_array)) {
		return string_array.map((str) => ({ text: str, value: str }))
	}
	return []
}

export const arrayDiff = (a, b) => a.filter((i) => b.indexOf(i) === -1)

export const max = (arr) => {
	let { length } = arr
	let maximum = -Infinity

	while (length--) {
		maximum = arr[length] > maximum ? arr[length] : maximum
	}

	return maximum
}

export const min = (arr) => {
	let { length } = arr
	let minimum = Infinity

	while (length--) {
		minimum = arr[length] < minimum ? arr[length] : minimum
	}

	return minimum
}

export const backParam = (text) => {
	let query_string = `back=${encodeURIComponent(window.location.pathname + window.location.hash)}`
	if (text) {
		query_string += `&back_text=${encodeURIComponent(text)}`
	}
	return query_string
}
export const underscore = (string) => String(string).replace(/\W/g, '_')

export const calculateChartHeight = (data_length) => {
	let height = data_length * 20 + 200
	if (height < 420) {
		height = 420
	}
	return height
}

export const isUUID = (str) =>
	RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i).test(String(str))

export const coalesce = (conditions) => {
	const match = conditions.find((c) => c[0] === true)
	return typeof match === 'undefined' ? conditions[conditions.length - 1] : match[1]
}

export const searchBetween = (starting = '', ending = '', search = '') => {
	// todo: Actually finish, probably need to escape characters
	new RegExp(`(?<=${starting})(?:(?:.|\s)*?)\K(${search})(?=(?:(?:.|\s)*?)(?=${ending}))`)
}

export const decodeHTMLEntities = (text) => {
	const entities = [
		['amp', '&'],
		['apos', "'"],
		['#x27', "'"],
		['#x2F', '/'],
		['#39', "'"],
		['#47', '/'],
		['lt', '<'],
		['gt', '>'],
		['nbsp', ' '],
		['quot', '"'],
		['rsquo', "'"],
	]

	let decoded_text = text
	for (let i = 0, max = entities.length; i < max; i += 1)
		decoded_text = decoded_text.replace(new RegExp(`&${entities[i][0]};`, 'g'), entities[i][1])

	return decoded_text
}

export const lzw_encode = (s) => {
	const dict = {}
	const data = `${s}`.split('')
	const out = []
	let currChar
	let phrase = data[0]
	let code = 256
	for (let i = 1; i < data.length; i += 1) {
		currChar = data[i]
		if (dict[phrase + currChar] != null) {
			phrase += currChar
		} else {
			out.push(phrase.length > 1 ? dict[phrase] : phrase.charCodeAt(0))
			dict[phrase + currChar] = code
			code += 1
			phrase = currChar
		}
	}
	out.push(phrase.length > 1 ? dict[phrase] : phrase.charCodeAt(0))
	for (let i = 0; i < out.length; i += 1) {
		out[i] = String.fromCharCode(out[i])
	}
	return out.join('')
}

// Decompress an LZW-encoded string
export const lzw_decode = (s) => {
	const dict = {}
	const data = `${s}`.split('')
	let currChar = data[0]
	let oldPhrase = currChar
	const out = [currChar]
	let code = 256
	let phrase
	for (let i = 1; i < data.length; i += 1) {
		const currCode = data[i].charCodeAt(0)
		if (currCode < 256) {
			phrase = data[i]
		} else {
			phrase = dict[currCode] ? dict[currCode] : oldPhrase + currChar
		}
		out.push(phrase)
		currChar = phrase.charAt(0)
		dict[code] = oldPhrase + currChar
		code += 1
		oldPhrase = phrase
	}
	return out.join('')
}

// Get a value (or undefined) from a nested object. path_string is like "user.calendly_account.id"
export let dig = (object, path_string) => {
	const names = path_string.split('.')
	let value = object
	for (let i = 0; i < names.length; i += 1) {
		if (!value) break
		value = value[names[i]]
	}
	return value
}

// format a javascript Date as MM/DD/YYYY
export const shortDate = (date) => `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getFullYear()}`

export function formatBytes(bytes, decimals = 1) {
	// pasted from https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
	if (bytes === 0) return '0 Bytes'

	const k = 1024
	const dm = decimals < 0 ? 0 : decimals
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

	const i = Math.floor(Math.log(bytes) / Math.log(k))

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

// convert an ISO string or javascript Date in UTC to the browser's timezone with the same hours and minutes.
export const toLocalDate = (date) => {
	if (!date) {
		return new Date()
	}

	if (!(date instanceof Date)) {
		date = new Date(date)
	}
	const local_date = new Date(
		date.getUTCFullYear(),
		date.getUTCMonth(),
		date.getUTCDate(),
		date.getUTCHours(),
		date.getUTCMinutes(),
	)
	return local_date
}

// convert a local Date to a string containing only date and time, not timezone
export const toUTCString = (date) => `${format(new Date(date), 'yyyy-MM-dd')}T${format(new Date(date), 'HH:mm:ss')}Z`

export const copyDate = (date) => new Date(date.valueOf())

// return a new date object with the same hours & minutes but new day of the year
export const setDate = (date_and_time, new_date) =>
	setMinutes(setHours(copyDate(new_date), getHours(date_and_time)), getMinutes(date_and_time))

// return a new date object with the same day of the year but new hour (0-23) and minute (0-59).
export const setHourMin = (date_and_time, hour, minute) => setMinutes(setHours(copyDate(date_and_time), hour), minute)

export const processSpreadsheet = (spreadsheet) => {}

