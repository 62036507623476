/**
 *
 * Table/HeaderRow
 *
 */

import React, { useContext } from 'react'

// NPM Libraries
import classNames from 'classnames'

// Components
import { TableContext } from 'components/Table'
import Cell from 'components/Table/Cell'
import Resizer from 'components/Table/Cell/Resizer'

function HeaderRow({
	currently_resizing,
	setCurrentlyResizing,
	dispatchResized,
	sort,
	setSort,
	filter_input_states,
	handleFilterInputChange,
}) {
	const { columns } = useContext(TableContext)

	const updateSort = (column) => {
		setSort({
			key: column.sort_key,
			direction: sort.direction === 'asc' ? 'desc' : 'asc',
		})
	}

	return (
		<React.Fragment>
			{columns.map((column, index) => (
				<Cell
					key={index}
					className={classNames('header-cell', {
						sortable: column.sortable,
						'header-sort-asc': sort.key === column.sort_key && sort.direction === 'asc',
						'header-sort-desc': sort.key === column.sort_key && sort.direction === 'desc',
					})}
					column={column}
					header
				>
					<div onClick={column.sortable ? () => updateSort(column) : null}>
						{column.header || column.Header}

						{filter_input_states && column.filter_key && (
							<div
								onClick={(e) => {
									e.stopPropagation()
									return false
								}}
							>
								{column.filter_options && (
									<select
										name={column.filter_key}
										className="header-filter-input text-muted"
										value={filter_input_states[column.filter_key] || ''}
										onChange={handleFilterInputChange}
									>
										{column.filter_options}
									</select>
								)}
								{!column.filter_options && (
									<input
										name={column.filter_key}
										className="header-filter-input text-muted"
										value={filter_input_states[column.filter_key] || ''}
										onChange={handleFilterInputChange}
									/>
								)}
							</div>
						)}
					</div>
					<Resizer
						column={column}
						currently_resizing={currently_resizing}
						setCurrentlyResizing={setCurrentlyResizing}
						dispatchResized={dispatchResized}
					/>
				</Cell>
			))}
		</React.Fragment>
	)
}

HeaderRow.propTypes = {}

HeaderRow.defaultProps = {
	sort: {},
}

export default HeaderRow
