import React, { useState, createContext, useContext } from 'react'

export const ForceUpdateContext = createContext({update_on_change: undefined, forceUpdate: () => {}})

export const ForceUpdateProvider = ({children}) => {
	const [update_on_change, setUpdateOnChange] = useState(0)

	const forceUpdate = () => setUpdateOnChange(update_on_change + 1)
	return <ForceUpdateContext.Provider value={{update_on_change, forceUpdate}}>{children}</ForceUpdateContext.Provider>
}

export const useForceUpdate = () => {
	const { update_on_change, forceUpdate } = useContext(ForceUpdateContext)
	return { update_on_change, forceUpdate }
}