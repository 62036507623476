/**
 *
 * Notifications/NotificationCenter
 *
 */

import React, { useState, useEffect, useLayoutEffect, useRef, useContext } from 'react'

// NPM Libraries
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import axios from 'axios'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { useOnClickOutside } from 'hooks'

// GraphQL
import useQuery from 'components/UseQuery'
import { gql } from '@apollo/client'

// Componets
import NotificationList from 'components/Notifications/List'
import { HelmetContext } from 'containers/App'

// Utils
import messages from './messages'
import { COLORS } from 'global-styles'

const NotificationGroup = styled.div`
	position: relative;
	display: inline-block;
`

const NotificationCenterButton = styled.button`
	cursor: pointer;
	position: relative;
	display: inline-block;
	margin-right: 1em;
	width: 32px;
	height: 32px;
	text-align: center;
	border-radius: 50%;
	background-color: #f6f6f6;
	color: #bfbfc1;
	border: none;
	outline: none;

	.fa,
	.svg-inline--fa {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.badge {
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(50%, -50%);
	}
`

const NotificationListComponent = styled.div`
	position: fixed;

	box-shadow: 1px 1px 5px #aaa;

	width: 500px;
	z-index: 1020; /* 10 more than .topbar */

	&:before {
		content: '';
		height: 32px;
		width: 15px;
		position: absolute;
		left: -13px;
		bottom: 0;
		z-index: 1021;
		border-top: 15px solid transparent;
		border-bottom: 15px solid transparent;
		border-right: 15px solid ${COLORS.primary};
	}

	.card-header {
		background-color: ${COLORS.primary};
		color: #fff;

		a,
		.btn-unstyled {
			color: #fff;
		}
	}

	.infinite-scroll-component {
		height: 500px;
		overflow: auto;

		.notification-list-item {
			padding: 0.75em 1em 0.5em;
			color: $grey;
		}
	}
`

const LOAD_NOTIFICATIONS_COUNT = gql`
	query loadNotificationCount($actionable: Boolean) {
		unread_count: notification_count(actionable: $actionable)
		total_count: notification_count
	}
`

function NotificationCenter({ icon, user_id, actionable, title }) {
	const {
		data: { unread_count, total_count },
	} = useQuery(LOAD_NOTIFICATIONS_COUNT, {
		variables: { actionable },
		default_data: { this_count: 0, total_count: 0 },
		channels: [`notifications_${user_id}`],
	})

	const button_ref = useRef()
	const list_ref = useRef()
	const [list_position, setListPosition] = useState()
	const [show_notifications, setShowNotifications] = useState(false)

	const setNotificationCount = useContext(HelmetContext)

	const markAllAsRead = () => {
		axios({
			method: 'PATCH',
			url: `${process.env.API_URL}/api/v1/notifications/mark-all-as-read`,
			data: { actionable },
		})
	}

	useEffect(() => {
		if (typeof setNotificationCount === 'function') {
			setNotificationCount(total_count)
		}
	}, [total_count])

	const reposition = () => {
		if (button_ref.current && list_ref.current) {
			const button_position = button_ref.current.getBoundingClientRect()
			const list_bounds = list_ref.current.getBoundingClientRect()

			setListPosition({
				top: button_position.y + button_position.height - list_bounds.height, // eslint-disable-line prettier/prettier
				left: button_position.x + 50,
			})
		}
	}

	useLayoutEffect(() => {
		if (show_notifications) {
			reposition()

			window.addEventListener('wheel', reposition)
			const reposition_interval = setInterval(reposition, 100)
			return () => {
				window.removeEventListener('wheel', reposition)
				clearInterval(reposition_interval)
			}
		}

		return () => null
	}, [show_notifications])

	useOnClickOutside(list_ref, () => setShowNotifications(false))

	return (
		<NotificationGroup>
			<NotificationCenterButton
				ref={button_ref}
				type="button"
				className="notification-center-button"
				onClick={() => setShowNotifications(!show_notifications)}
			>
				<FontAwesomeIcon fixedWidth icon={icon} />
				<span className="badge badge-pill badge-danger">{unread_count > 0 && unread_count}</span>
			</NotificationCenterButton>

			{show_notifications &&
				ReactDOM.createPortal(
					<NotificationListComponent
						ref={list_ref}
						className="card border-light notification-list"
						style={list_position}
					>
						<div className="card-footer text-center">
							<Link to="/notifications" className="card-link">
								<FormattedMessage {...messages.view_all} />
							</Link>
						</div>
						<NotificationList
							user_id={user_id}
							height={500}
							actionable={actionable}
							setLoaded={reposition}
							hide_mark_as_read
						/>
						<div className="card-header">
							<strong>{title}</strong>
							<button type="button" className="btn-unstyled float-right" onClick={() => markAllAsRead()}>
								<FormattedMessage {...messages.mark_all_read} />
							</button>
						</div>
					</NotificationListComponent>,
					document.body,
				)}
		</NotificationGroup>
	)
}

NotificationCenter.propTypes = {
	icon: PropTypes.string,
	user_id: PropTypes.string,
	actionable: PropTypes.bool,
	title: PropTypes.string,
	show_notifications: PropTypes.bool,
	setShowNotifications: PropTypes.func,
}

NotificationCenter.defaultProps = {}

export default NotificationCenter
