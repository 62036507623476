/**
 *
 * ErrorBoundary
 *
 */

import React from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import * as Sentry from '@sentry/browser'

import { withCurrentUser } from 'currentUserContext'

const TextArea = styled.textarea`
	font-size: 15px;
	padding: 8px 12px;
	border: 1px solid #bac2d3;
	background: #fbfbfc;
	width: 100%;
	height: auto;
	box-sizing: border-box;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06);
	border-radius: 2px;
`

const Or = styled.div`
	margin: 2rem auto 1rem;

	&:before,
	&:after {
		display: inline-block;
		content: '';
		width: 45%;
		border-bottom: 1px solid lightgrey;
		vertical-align: middle;
		margin: auto 5px;
	}
`

class ErrorBoundary extends React.PureComponent {
	constructor(props) {
		super(props)

		this.state = {
			has_error: false,
			has_retried: false,
			feedback: '',
			feedback_sent: false,
		}
	}

	componentDidCatch(error) {
		// eslint-disable-line no-unused-vars, prettier/prettier
		this.setState({
			has_error: true,
		})

		const event_id = Sentry.captureException(error) // eslint-disable-line no-undef
		this.setState({
			event_id,
		})
	}

	handleSubmit = (e) => {
		e.preventDefault()

		const sentryAxios = axios.create({
			baseURL: 'https://sentry.io',
			headers: {
				Authorization: `DSN https://499f986f97d946b58bcc7a80ccdef060@o152289.ingest.sentry.io/2016748`,
			},
			withCredentials: false,
		})
		delete sentryAxios.defaults.headers.common['X-Front-End-Build-Number']

		sentryAxios({
			method: 'POST',
			url: '/api/0/projects/touchfuse/leadjig-front-end/user-feedback/',
			data: {
				event_id: this.state.event_id,
				comments: this.state.feedback,
				email: this.props.current_user?.email,
				name: this.props.current_user?.full_name,
			},
		})
			.then(() => {
				this.setState({
					feedback_sent: true,
				})
			})
			.catch((err) => {
				Sentry.captureException(err)
			})

		return false
	}

	render() {
		if (this.state.has_error && this.props.message) {
			return (
				<div className="text-center">
					<h1>{this.props.message}</h1>
					{this.state.event_id && (
						<p style={{ color: '#94A0B3' }}>
							Our team has been notified. If you&apos;d like to help, tell us what happened below.
						</p>
					)}
					{!this.state.feedback_sent && (
						<form onSubmit={this.handleSubmit} className="mx-auto clearfix" style={{ maxWidth: 500 }}>
							<TextArea
								id="feedback"
								placeholder="I clicked on 'X' and then hit 'Confirm'"
								value={this.state.feedback}
								onChange={(e) => this.setState({ feedback: e.target.value })}
							/>
							<button className="btn btn-success float-right" type="submit">
								Submit Feedback
							</button>
						</form>
					)}
					{this.state.feedback_sent && <span>Thank you for your help!</span>}
					{!this.state.has_retried && (
						<div className="mx-auto clearfix" style={{ maxWidth: 500 }}>
							<Or>OR</Or>
							<button
								className="btn btn-primary"
								type="button"
								onClick={() => this.setState({ has_error: false, has_retried: true })}
							>
								Try Again
							</button>
						</div>
					)}
				</div>
			)
		}

		if (this.state.has_error) return null

		return this.props.children
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	message: PropTypes.string,
	current_user: PropTypes.objectOf(PropTypes.any),
}

export default withCurrentUser(ErrorBoundary)
