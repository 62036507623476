/**
 *
 * /DragAndDropList
 *
 */

import React, { memo, useEffect, useCallback } from 'react'

// NPM Libraries
import PropTypes from 'prop-types'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

// Components
import SingleInput from 'components/DragAndDropList/SingleInput'

// TODO: Replace with useReducer
import update from 'immutability-helper'

function DragAndDropList({ items, setItems, label, ...props }) {
	const moveItem = useCallback(
		(dragIndex, hoverIndex) => {
			const dragCard = items[dragIndex]
			setItems(
				update(items, {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, dragCard],
					],
				}),
			)
		},
		[items],
	)

	const setItem = (item) => {
		setItems(items.map((i, index) => (index !== item.index ? i : item.text)))
	}

	const clearEmptyItems = () => {
		const filtered_items = items.filter((i) => i?.length > 0).concat('')

		setItems(filtered_items)
	}

	useEffect(() => {
		clearEmptyItems()
	}, [])

	useEffect(() => {
		if (items[items.length-1] !== '') {
			setItems([...items, ''])
		}
	}, [JSON.stringify(items)])

	return (
		<DndProvider backend={HTML5Backend}>
			{items.map((item, i) => (
				<SingleInput
					key={i}
					id={item}
					text={item}
					index={i}
					moveItem={moveItem}
					setItem={setItem}
					label={i === 0 ? label : ''}
					onBlur={clearEmptyItems}
					always_float
					{...props}
				/>
			))}
		</DndProvider>
	)
}

DragAndDropList.propTypes = {
	items: PropTypes.arrayOf(PropTypes.string),
	setItems: PropTypes.func,
	label: PropTypes.string,
}

export const DraggableItems = {
	LI: 'li',
}

export default memo(DragAndDropList)
