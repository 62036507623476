import { ApolloClient, ApolloLink } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
// import { BatchHttpLink } from '@apollo/client/link/batch-http'

import { buildAxiosFetch } from '@lifeomic/axios-fetch'
import axios from 'axios'

const isObject = (node) => typeof node === 'object' && node !== null

// rough first draft, could probably be optimised in a loads of different ways.
// eslint-disable-next-line no-unused-vars
const hasFiles = (node, found = []) => {
	Object.keys(node).forEach((key) => {
		if (!isObject(node[key]) || found.length > 0) {
			return
		}

		if (
			(typeof File !== 'undefined' && node[key] instanceof File) ||
			(typeof Blob !== 'undefined' && node[key] instanceof Blob)
		) {
			found.push(node[key])
			return
		}

		hasFiles(node[key], found)
	})

	return found.length > 0
}

const client = new ApolloClient({
	link: ApolloLink.from([
		onError(({ graphQLErrors, networkError }) => {
			if (graphQLErrors)
				graphQLErrors.forEach(({ message, locations, path }) =>
					console.error(
						`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
						locations,
					),
				)
			if (networkError) console.error(`[Network error]: ${networkError}`)
		}),
		createUploadLink({
			uri: `${process.env.API_URL}/graphql`,
			fetch: buildAxiosFetch(axios),
		}),
		// }).split(
		// 	({ variables }) => hasFiles(variables),
		// 	createUploadLink({
		// 		uri: `${process.env.API_URL}/graphql`,
		// 		fetch: buildAxiosFetch(axios),
		// 	}),
		// 	new BatchHttpLink({
		// 		uri: `${process.env.API_URL}/graphql`,
		// 		fetch: buildAxiosFetch(axios),
		// 	}),
		// ),
	]),
	cache: new InMemoryCache(),
	shouldBatch: false,
	defaultOptions: {
		query: {
			fetchPolicy: 'cache-and-network',
		},
	},
})

const prospectix_client = new ApolloClient({
	link: ApolloLink.from([
		onError(({ graphQLErrors, networkError }) => {
			if (graphQLErrors)
				graphQLErrors.forEach(({ message, locations, path }) =>
					console.error(
						`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
						locations,
					),
				)
			if (networkError) console.error(`[Network error]: ${networkError}`)
		}),
		createUploadLink({
			uri: `${process.env.PROSPECTIX_URL}/graphql`,
			fetch: buildAxiosFetch(axios),
		}),
		// new HttpLink({
		// 	uri: `${process.env.PROSPECTIX_URL}/graphql`,
		// 	fetch: buildAxiosFetch(axios),
		// }),
	]),
	cache: new InMemoryCache(),
	shouldBatch: false,
	defaultOptions: {
		query: {
			fetchPolicy: 'cache-and-network',
		},
	},
})

export default client

export { client, prospectix_client }
