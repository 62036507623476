/* stylelint-disable no-descending-specificity */

import { css } from '@emotion/react'
import 'bootstrap_ext.scss'
import 'react-contextmenu.css'
import 'react-toggle/style.css'
import 'assets/react_select_search.css'
import 'react-toastify/dist/ReactToastify.css'

export const COLORS = {
	brand_logo: '#00a2d0',
	brand_highlight: '#edf2fa',
	// primary: '#16a1d4',
	primary: '#0092d1',
	zima: '#5bc2e7',
	prospectix_orange: '#FF8000',
	success: '#1abc9c',
	info: '#45b4dd',
	warning: '#f5ab35',
	danger: '#eb6b56',
	dark: '#676a6c',
	black: '#32323a', // Off black, because true black is actually really ugly
	white: '#ffffff',
	// grey: '#1b1b1c',
	grey: '#626576',
	grey_9: '#9598A9',
	grey_c: '#CBCCD1', // between the text grey and background grey.
	grey_d: '#D8D9DE',
	grey_e: '#E4E5EA',
	lightGrey: '#fafafa',
	borderGrey: '#f1f1f3',
	headerGrey: '#727577',
	backgroundGrey: '#f1f2f7',
	purple: '#8067dc',
}

const GlobalStyle = css`
	html,
	body,
	#app {
		height: 100%;
	}

	html,
	body {
		height: 100%;
		width: 100%;

		color: ${COLORS.grey};
		background-color: ${COLORS.backgroundGrey};
		font-family: 'Source Sans Pro', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
		font-size: 15px;
	}

	#app {
		background-color: #fafafa;
		min-height: 100%;
		min-width: 100%;
	}

	p,
	label {
		line-height: 1.5em;
	}

	.btn-unstyled {
		border: none;
		background: none;
		padding: 0;
		color: ${COLORS.primary};
		text-decoration: underline;
	}

	.btn-link {
		background: none;
	}

	strong {
		/* Instead of default 700, easier to read */
		font-weight: 600;
	}

	a {
		color: ${COLORS.brand_logo};
	}

	button {
		cursor: pointer;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	.h1,
	.h2,
	.h3,
	.h4,
	.h5,
	.h6 {
		margin-bottom: 0.5rem;
		font-family: inherit;
		font-weight: 500;
		line-height: 1.2;
		color: inherit;
	}

	.flex-row-around {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
	}

	.white-space-normal {
		white-space: normal;
	}

	.nowrap {
		white-space: nowrap;
		overflow: hidden;
	}

	.swal2-modal {
		font-family: inherit;
	}

	.react-toggle,
	.react-toggle-text {
		vertical-align: middle;
	}

	.react-toggle-screenreader-only {
		width: 0 !important;
	}

	.vertical-timeline-element-content {
		@media only screen and (min-width: 1170px) {
			/* width: 44.25% !important; */
		}
	}

	.vertical-timeline-element-icon .svg-inline--fa {
		left: 55%;
		font-size: 1em;
	}

	.pac-container {
		/* Google autocomplete in a dialog */
		z-index: 1051 !important;
	}

	.gm-style-iw {
		top: 0 !important;
		left: 0 !important;
		background-color: #fff;
		min-width: 300px;

		border-bottom: 5px solid theme-color('success');
		padding: 1.5rem;
	}

	.sk-spinner > div {
		display: inline-block;
	}

	.invisible-file-input {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		width: 100%;
		cursor: pointer;
	}

	.react-toggle--unsaved .react-toggle-thumb {
		background-color: theme-color('warning');
	}

	.vertical-timeline-element-date {
		padding: 0 !important;
	}

	#email-unconfirmed,
	#subscribe-banner {
		position: relative;
		left: -25px;
		top: -15px;
		width: calc(100% + 50px);
		background-color: theme-color('warning');
		color: #fff;
		text-align: center;
		padding: 0.5em 1em;
	}

	::-webkit-file-upload-button {
		cursor: pointer;
	}

	input[type='file'] {
		cursor: pointer;
	}

	.ldg-fb-table {
		font-size: 10pt;
		margin: 25px 10px 10px 10px;
	}

	h3.analytics_title {
		color: #777777;
	}

	.metric-header {
		font-size: 12pt;
		color: #777777;
	}

	.metric-row {
		background: #fff;
		padding: 25px;
		width: 98%;
		height: 332px;
		margin-bottom: 15px;
	}

	.metric-row-sm {
		height: 200px;
	}

	.metric {
		text-align: center;
	}

	.metric-data {
		font-size: 20pt;
		padding-left: 10px;
		margin-bottom: -5px;
	}

	.metric-label {
		font-size: 8pt;
		color: #777777;
		padding-left: 48px;
	}

	.metric-icon {
		color: #0a66b7 !important;
		font-size: 16pt !important;
	}

	/* Email Activity */
	.activity-data {
		font-size: 14pt;
		color: #777777;
		margin-left: -100px;
		padding-top: 105px;
	}

	.activity-icon {
		padding-right: 5px;
		font-size: 16pt;
	}

	.totals {
		font-size: 24pt;
	}

	.ldj-blue {
		color: #67b7dc;
	}

	.ldj-green {
		color: #84b761;
	}

	/* Contact View */
	.float-left > .ldj-contact-header {
		width: 125%;
		margin-left: 25px;
		margin-bottom: 25px;
		border: none;
	}

	.ldj-contact-icon {
		margin-right: 10px;
		font-size: 12pt;
	}

	.ldj-contact-row {
		background: #fff;
		padding: 0;
		margin: 0;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.ldj-desc-list dl {
		color: #777;
	}

	.ldj-dec-list dl dt {
		color: #000;
		padding-top: 10px;
	}

	.ldj-no-border {
		border: 0;
	}

	.ldj-cm-totals {
		font-size: 18pt;
		color: #777;
	}

	.ldj-cm-cases {
		margin: 10px 20px;
		float: right;
		color: #777;
	}

	.ldj-cm-totals > .ldj-cm-label {
		font-size: 14pt;
	}

	.ldj-contact-title {
		color: #777;
		font-size: 10pt;
	}

	[title='JavaScript charts'] {
		display: none !important;
	}

	g[filter^='url(#filter-id-'][style='cursor: pointer;'] {
		display: none !important;
	}

	.fl-input-group .fl-input-container {
		display: inline-flex;
		vertical-align: top;
	}

	.fl-input-group .btn {
		vertical-align: bottom;
	}

	.fl-input-group .fl-input-container:not(:first-of-type):not(:last-child) input {
		border-radius: 0;
	}

	.fl-input-group .fl-input-container:first-of-type input {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	.fl-input-group .fl-input-container:last-child input {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
`

export default GlobalStyle
