/**
 *
 * Table/Query
 *
 */

import React, { useState, useEffect } from 'react'
import { useStateAndSessionStorage } from 'persistence-hooks'
import PropTypes from 'prop-types'
// import styled from '@emotion/styled'

import { useIdentifier, useHandleInputChangeWithDelay } from 'hooks'

import useQuery from 'components/UseQuery'
import Table from 'components/Table'

function TableQuery({ query, search, default_sort, columns, cache_key, channels, variables, rowDropdown, virtualize }) {
	const [page_size, setPageSize] = useStateAndSessionStorage(10, `${cache_key}-page_size`)

	// Current page and sort should only be shared across the same table so
	// unless there's a unique cache_key defined just set the cache key for
	// this specific instance
	const cache_key_identifier = useIdentifier(cache_key)

	const [page, setPage] = useStateAndSessionStorage(1, `${cache_key_identifier}-page`)
	const [sort, setSort] = useStateAndSessionStorage(default_sort, `${cache_key_identifier}-page_sort`)
	const [filters, filter_input_states, handleFilterInputChange] = useHandleInputChangeWithDelay(
		columns.reduce((filter_object, column) => {
			if (column.filter_key) {
				filter_object[column.filter_key] = ''
			}
			return filter_object
		}, {}) || {},
	)

	const [all_channels, setAllChannels] = useState(channels)

	const { data, loading, refetch } = useQuery(query, {
		variables: {
			...variables,
			page,
			page_size,
			sort: sort.key,
			sort_direction: sort.direction,
			search,
			filters,
		},
		channels: all_channels,
		default_data: {
			search: {
				results: [],
			},
		},
	})

	useEffect(() => {
		setAllChannels([...channels, ...data.search.results.map((c) => c.id)])
	}, [JSON.stringify(channels), JSON.stringify(data.search.results.map((c) => c.id))])

	return (
		<Table
			columns={columns}
			data={data.search.results}
			loading={loading}
			sort={sort}
			setSort={setSort}
			page={page}
			setPage={setPage}
			page_size={page_size}
			setPageSize={setPageSize}
			refetch={refetch}
			total_records={data.search.total}
			filter_input_states={filter_input_states}
			handleFilterInputChange={handleFilterInputChange}
			row_dropdown={rowDropdown}
			virtualize={virtualize}
		/>
	)
}

TableQuery.propTypes = {
	cache_key: PropTypes.string,
	query: PropTypes.object.isRequired,
	variables: PropTypes.object,
	channels: PropTypes.array,
	default_sort: PropTypes.shape({
		key: PropTypes.string,
		direction: PropTypes.string,
	}),
}

TableQuery.defaultProps = {
	default_sort: {
		key: 'updated_at',
		direction: 'desc',
	},
	variables: {},
	channels: [],
}

export default TableQuery
