/**
 *
 * CampaignTemplates/Show/ActivityAlert
 *
 */

import React, { memo, useState, useRef, useEffect } from 'react'

// NPM Libraries
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatDistanceToNow } from 'date-fns'
import { useScroll } from 'hooks'

// Components
import { HoverDiv } from 'utils/custom-styled-components'

function ActivityAlert({ activity_alert_messages = [], parent_ref }) {
	const [show, setShow] = useState(false)
	const [top, setTop] = useState(window.innerHeight)
	const [permanently_closed, setPermanentlyClosed] = useState(false)
	const [message, setMessage] = useState(activity_alert_messages[0])
	const scroll = useScroll()
	const index_ref = useRef(0)

	// keep the popup in the bottom left corner. Unfortunately we can't use css for this.
	useEffect(() => {
		if (parent_ref.current) {
			const max_top = parent_ref.current.getBoundingClientRect().height - 95
			let new_top = window.innerHeight - parent_ref.current.getBoundingClientRect().top - 95
			if (new_top > max_top) {
				new_top = max_top
			}
			setTop(new_top)
		}
	}, [scroll])

	useEffect(() => {
		// reset if messages change (ie if campaign objective changes for landing page settings)
		setMessage(activity_alert_messages[0])
		setPermanentlyClosed(false)

		// delay 3 seconds before showing first popup.
		let timeout = setTimeout(() => setShow(true), 3000)

		// change the message every 20 seconds.
		let interval
		if (activity_alert_messages.length > 1) {
			interval = setInterval(() => {
				setShow(false)
				timeout = setTimeout(() => {
					let new_index = index_ref.current + 1
					if (new_index >= activity_alert_messages.length) {
						new_index = 0
					}
					setMessage(activity_alert_messages[new_index])
					index_ref.current = new_index
					setShow(true)
				}, 600)
			}, 20000)
		}
		return () => {
			clearTimeout(timeout)
			clearInterval(interval)
		}
	}, [activity_alert_messages])

	const closePermanently = () => {
		setShow(false)
		setTimeout(() => setPermanentlyClosed(true), 600)
	}

	// don't show the popup on tiny screens
	if (window.innerHeight < 600) return null

	return message && !permanently_closed ? (
		<React.Fragment>
			<div
				className="border shadow"
				style={{
					top,
					position: 'absolute',
					left: show ? 10 : -260,
					minHeight: '80px',
					maxWidth: 250,
					background: '#fff',
					zIndex: 1,
					fontSize: '0.95rem',
					transition: 'left 0.4s ease',
				}}
			>
				<HoverDiv className="position-relative py-2 px-3" style={{ minHeight: 80 }}>
					<button
						type="button"
						className="btn-unstyled place-tr px-1 small appear-on-hover"
						onClick={closePermanently}
					>
						<FontAwesomeIcon icon={['fal', 'times']} style={{ color: '#aaa' }} />
					</button>
					<div className="d-flex flex-column justify-content-center h-100">
						<div style={{ flexGrow: 2 }}>
							{message.message_start}
							<span style={{ fontWeight: 600 }} className="px-1">
								{message.message_highlight}
							</span>
							{message.message_end}
						</div>
						{message.time && (
							<div style={{ color: '#aaa' }}>
								{formatDistanceToNow(new Date(), { includeSeconds: true })} ago
							</div>
						)}
					</div>
				</HoverDiv>
			</div>
			<div
				className="position-absolute" // covers the popup when moved to the left
				style={{
					zIndex: 2,
					width: 270,
					left: -270,
					background: '#f1f2f7',
					height: '100px',
					top: top - 5,
				}}
			></div>
		</React.Fragment>
	) : null
}

ActivityAlert.propTypes = {}

export default memo(ActivityAlert)
