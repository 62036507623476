/**
 *
 * Spinner
 *
 */

// Core React
import React, { useState, memo } from 'react'

// NPM Libraries
import PropTypes from 'prop-types'
import Spinkit from 'react-spinkit'

export const SPINNERS = [
	'rotating-plane',
	'double-bounce',
	'wave',
	'wandering-cubes',
	'pulse',
	'chasing-dots',
	'three-bounce',
	'circle',
	'cube-grid',
	'fading-circle',
	'folding-cube',
]

const Spinner = ({ color, fadeIn, scale, name }) => {
	const [spinner_name] = useState(
		SPINNERS.indexOf(name) !== -1 ? name : SPINNERS[Math.floor(Math.random() * SPINNERS.length)],
	)

	return (
		<div className="mx-auto text-center">
			<Spinkit
				className="d-inline-block"
				name={spinner_name}
				color={color}
				fadeIn={fadeIn || 'none'}
				style={{ zoom: scale }}
			/>
		</div>
	)
}

Spinner.propTypes = {
	color: PropTypes.string,
	fadeIn: PropTypes.any, // todo: Unsure if this is a number or string
	scale: PropTypes.number,
	name: PropTypes.string,
}

Spinner.defaultProps = {
	color: '#16A1D4',
	scale: 1,
}

export default memo(Spinner)
