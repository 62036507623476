import Jimp from 'jimp/es'
import { formatBytes } from 'components/utils'

export async function scaleToFit({ file, width, height, force, setFile, setUrl, setBytesText, setDimensionsText }) {
	async function performResize(buffer) {
		const image = await Jimp.read(buffer)
		// let extension = image.getExtension()
		let mime = image.getMIME()

		// don't resize if image is already smaller, unless force
		if (force || image.bitmap.width > width || image.bitmap.height > height) {
			const copy = await image.clone()

			await copy.scaleToFit(width, height)

			setDimensionsText(`${copy.bitmap.width} x ${copy.bitmap.height} pixels`)

			if (!['image/jpeg', 'image/png'].includes(mime)) {
				mime = 'image/jpeg'
			}

			const new_buffer = await copy.getBufferAsync(mime)
			const new_file = new Blob([new_buffer])
			const new_data_uri = await copy.getBase64Async(mime)

			setUrl(new_data_uri)
			setFile(new_file)
			setBytesText(formatBytes(new_buffer.byteLength))
		} else {
			setDimensionsText(`${image.bitmap.width} x ${image.bitmap.height} pixels`)
			setBytesText(formatBytes(buffer.byteLength))
		}
	}

	if (file) {
		const reader = new FileReader()
		reader.onload = (e) => {
			performResize(e.target.result)
		}
		reader.readAsArrayBuffer(file)
	} else console.log('No image file provided')

	// todo: allow resizing the image that was already uploaded. Download from the url.
}
