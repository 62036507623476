/**
 *
 * /UseQuery
 *
 */

import { useEffect, useRef } from 'react'

// NPM Libraries
import { debounce } from 'debounce'
import { useIdentifier } from 'hooks'

// GraphQL
import { useQuery } from '@apollo/client'

// Components
import IO from 'components/Socket/IO'

function UseQuery(QUERY, { channels = [], default_data = {}, ...args }) {
	const query = useQuery(QUERY, args)
	query.data = { ...default_data, ...query.data }

	// if (global.testing) return query

	if (
		query.error &&
		query.error.networkError &&
		query.error.networkError.message.includes('Received status code 404')
	) {
		query.data = {}
	}
	const identifier = useIdentifier()

	const refetch = useRef()
	if (!refetch.current) {
		refetch.current = debounce(
			(channel) => {
				console.info(`Refetch executed ${channel}`)
				try {
					query?.refetch() // Sometimes if we navigate away quick the query is removed, before the debounce takes effect
				} catch (e) {
					// const messages = [
					//	"Cannot read property 'refetch' of undefined",
					//	'o.currentObservable.query is undefined',
					// ]
					// if (!messages.includes(e.message)) {
					console.error(e)
					// 	throw e
					// } else {
					// Sentry.captureException(e) // eslint-disable-line no-undef
					// }
				}
			},
			500,
			false,
		)
	}

	useEffect(() => {
		const io = new IO()
		io.join(channels, identifier)
		channels.forEach((channel) =>
			io.on(channel, () => {
				console.info(`Refetch requested ${channel}`)
				refetch.current(channel)
			}),
		)

		return () => {
			io.leave(channels, identifier)
		}
	}, [channels, identifier])
	// If data is undefined because of an Apollo bug default to an empty
	// object
	if (typeof query.data === 'undefined') query.data = {}

	return query
}

export default UseQuery
