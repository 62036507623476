/*
 * CmdK/UserSearch Messages
 *
 * This contains all the text for the UserSearch component.
 */

import { defineMessages } from 'react-intl'

export const scope = 'app.components.CmdKUserSearch'

export default defineMessages({
	header: {
		id: `${scope}.header`,
		defaultMessage: 'This is the CmdKUserSearch component!',
	},
})
