/**
 *
 * /LogoutButton
 *
 */

import React, { memo, useContext } from 'react'

// NPM Libraries
import axios from 'axios'
import client from 'configureApolloClient'
import { CurrentUserContext } from 'currentUserContext'
import { useHistory } from 'react-router-dom'

function LogoutButton() {
	const { refetch, current_user } = useContext(CurrentUserContext)
	const history = useHistory()

	const logout = () => {
		// eslint-disable-next-line no-undef, prettier/prettier
		swal({
			title: 'Are you sure you want to log out?',
			type: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Log out',
			cancelButtonText: 'Cancel',
			confirmButtonClass: 'btn btn-primary d-inline',
			cancelButtonClass: 'btn btn-default d-inline',
			buttonsStyling: false,
		}).then(() => {
			axios({
				method: 'DELETE',
				url: `${process.env.API_URL}/users/sign_out`,
				maxRedirects: 0,
			}).then(clearAndRedirect, clearAndRedirect)
		})
	}

	function clearAndRedirect() {
		refetch().then(
			() => {
				if (window.location.pathname !== '/login' && current_user.id) {
					client.resetStore().catch()
					history.push('/login')
				}
			},
			() => {
				if (window.location.pathname !== '/login' && current_user.id) {
					client.resetStore().catch()
					history.push('/login')
				}
			},
		)
	}

	return (
		<button type="button" className="btn-unstyled" onClick={logout}>
			Logout
		</button>
	)
}

LogoutButton.propTypes = {}

export default memo(LogoutButton)
