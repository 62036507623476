import styled from '@emotion/styled'
import { COLORS } from '../global-styles'

export const SmallInput = styled('input')`
	border: 1px solid #e1e1e1;
	height: 1.9em;
	font: inherit;
	color: inherit;
	background-color: #fff;
	width: 3em;
`

export const Container = styled('div')`
	max-width: 1250px;
	margin-left: auto;
	margin-right: auto;
`

export const TemplateGallery = styled('div')`
	&.container-fluid {
		max-width: 1200px;
		margin-left: auto;
		margin-right: auto;
	}
	.template-gallery-box {
		width: 260px;
		height: 350px;
		background: #f1f2f7;
		margin: 1rem auto;
	}
	.template-preview-box {
		position: relative;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
		overflow: hidden;
	}
	.template-preview-iframe {
		width: 406%;
		height: 400%;
		transform: scale(0.25);
		transform-origin: top left;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
	}
	.template-preview {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		transform-origin: top left;
	}
	.template-preview-33 {
		width: 300%;
		height: 300%;
		transform: scale(0.333);
		transform-origin: top left;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
	}
	.template-preview-37 {
		width: 270%;
		height: 270%;
		transform: scale(0.37);
		transform-origin: top left;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
	}

	.template-preview-40 {
		width: 250%;
		height: 250%;
		transform: scale(0.4);
		transform-origin: top left;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
	}
	.template-preview-50 {
		width: 200%;
		height: 200%;
		transform: scale(0.5);
		transform-origin: top left;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
	}
	.preview-message {
		color: transparent;
	}
	.click-to-preview:hover .preview-message {
		background: rgba(255, 255, 255, 0.3);
		color: inherit;
	}
`
export const HoverDiv = styled('div')`
	.appear-on-hover {
		opacity: 0;
	}
	&:hover .appear-on-hover {
		opacity: 1;
	}
`

export const MenuItem = styled('span')`
	display: inline-block;
	cursor: pointer;
	padding: 0 5px;
	margin: 0 15px 10px;
	vertical-align: top;
	&.selected-menu-item {
		color: #16a1d4;
		border-bottom: 3px solid #16a1d4;
	}
	&.active-menu-item {
		color: ${COLORS.warning};
		border-bottom: 3px solid ${COLORS.warning};
	}
	&.menu-search-box {
		cursor: unset;
		width: 225px;
		height: 2.2em;
	}
	.menu-search-input {
		background: none;
		padding-left: 35px;
	}
`

export const TabNav = styled('nav')`
	.item {
		display: inline-block;
		padding: 0.5rem 0.75rem;
		margin: 0.25rem;
		z-index: 2;
		border-radius: 3.75px;
		color: inherit;
		text-decoration: none;
		cursor: pointer;
	}
	.item.active {
		background-color: ${COLORS.success};
		color: #fff;
	}
`

export const MenuWrapper = styled('div')`
	font-size: 1.1em;
	margin: 20px -12px 5px;
`

// small blue text, looks like floating label
export const Label = styled('div')`
	display: inline-block;
	padding-left: 0.25em;
	transform: scale(0.8) translate3d(0, 5px, 0);
	transform-origin: top left;
	color: #3949ab;
	font-family: 'Roboto', sans-serif;
`

export const HorizontalScroll = styled('div')`
	::-webkit-scrollbar {
		background: #f4f5f9;
		height: 9px;
		border-radius: 10px;
	}
	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: #d5d8e1;
	}
`

export const VerticalScroll = styled('div')`
	overflow-y: scroll;
	::-webkit-scrollbar {
		background: #f4f5f9;
		width: 9px;
		border-radius: 10px;
	}
	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: #d5d8e1;
	}
`

export const ColXXL3 = styled('div')`
	padding: 0 13px;
	@media (min-width: 1500px) {
		width: 25%;
		flex: 0 0 25%;
	}
`

export const ColXXL4 = styled('div')`
	padding: 0 13px;
	@media (min-width: 1500px) {
		width: 33.33%;
		flex: 0 0 33.33%;
	}
`

export const ColXXL9 = styled('div')`
	padding: 0 13px;
	@media (min-width: 1500px) {
		width: 75%;
		flex: 0 0 75%;
	}
`

// col-12 at screen breakpoint 900px
export const Col900 = styled('div')`
	@media (max-width: 900px) {
		max-width: 100%;
		flex: 0 0 100%;
		-webkit-box-width: 0;
	}
`

export const DT = styled('dt')`
	font-weight: inherit;
	text-align: right;
	white-space: nowrap;
	overflow: visible;
	@media (max-width: 665px) {
		text-align: left;
	}
`

// color is text-muted. Can use within .card or .p-3
export const SmallTitle = styled('div')`
	font-size: 0.9375rem;
	position: relative;
	top: -0.5rem;
	margin-bottom: 1rem;
	color: #6c757d;
`

export const StickyDiv = styled('div')`
	position: sticky;
	top: 0;
	z-index: 4;
	.sticky-row {
		position: relative;
		z-index: 4;
		padding-top: 1rem;
		margin-top: -1rem;
		background: rgba(241, 242, 247);
	}
	@media (max-height: 700px) {
		position: static;
	}
	@media (max-width: 992px) {
		position: static;
	}
`

// Use function to create StickyFadeBorder with specified distance from top.
export const stickyFadeBorder = (top) => styled('div')`
	position: sticky;
	top: ${top};
	z-index: 2;
	width: 100%;
	height: 2rem;
	background: linear-gradient(rgba(0, 0, 0, 0.18), rgba(0, 0, 0, 0));
	@media (max-height: 700px) {
		position: static;
	}
	@media (max-width: 992px) {
		position: static;
	}
`

// Place this just below the StickyFadeBorder to cover it when not scrolled to sticky position.
export const CoverStickyBorder = styled('div')`
	z-index: 3;
	background: rgba(241, 242, 247);
	min-height: 2rem;
	margin-top: -2rem;
`
