/**
 *
 * CampaignTemplates/Show/Events
 *
 */

import React from 'react'

// NPM Libraries
import { Global, css } from '@emotion/react'
import { format } from 'date-fns'

// Utils
import { toLocalDate } from 'components/utils'

const styles = css`
	.event-list {
		/* Display, Positioning, Sizing */
		padding: 0;

		/* List Styling */
		list-style-type: none;
	}

	.event-list-item {
		/* Display, Positioning, Sizing */
		display: inline-block;
		max-width: 100%;
		overflow-x: auto;

		/* Font Styling */
		text-align: left;
	}

	.event-item {
		/* Display, Positioning, Sizing */
		margin-bottom: 1em;
		white-space: nowrap;
		overflow: hidden;
	}

	.event-item:hover {
		overflow-x: auto;
	}

	.event-item .event-day-date {
		/* Display, Positioning, Sizing */
		display: inline-block;
		vertical-align: top;
		width: 7em;
		padding: 0.8em 0.25em;

		/* Border */
		border: 2px solid #00a0d6;
		border-radius: 4px;

		/* Font Styling */
		color: #00a0d6;
		text-transform: uppercase;
		font-weight: bold;
		text-align: center;
	}

	.event-item .event-day-date .day {
		/* Display, Positioning, Sizing */
		margin-bottom: -0.35em;
	}

	.event-item .event-day-date .date {
		/* Font Styling */
		font-size: 1.75rem;
	}

	.event-item .event-day-date .additional_times {
		/* Display, Positioning, Sizing */
		display: block;
		white-space: normal;

		/* Font Styling */
		font-size: 0.75em;
	}

	.event-item .event-day-date .additional_times .additional_time {
		/* Display, Positioning, Sizing */
		display: inline-block;
		width: 50%;
		float: left;

		/* Font Styling */
		text-align: center;
	}

	.event-item .event-information {
		/* Display, Positioning, Sizing */
		display: inline-block;
		vertical-align: top;
		margin-left: 1em;
		padding: 0.1rem;

		/* Font Styling */
		color: #fff;
		font-weight: bold;
		font-size: 0.9rem;
		text-align: left;
	}

	.event-item .event-information address {
		/* Display, Positioning, Sizing */
		margin-bottom: 0;
	}
`

function Events({ events, allow_appointment_registration }) {
	return (
		<div>
			<Global styles={styles} />
			<ul className="event-list text-lg-center text-left row">
				{events.map((event) => {
					const start_time = toLocalDate(event.start_time) // This component is only for fake events which are already in local time

					return (
						<li
							key={event.id}
							className={`event-list-item col-lg-6 col-12 ${events.length === 1 && 'offset-lg-3'}`}
						>
							{/* from the EventItem component */}
							<div className="event-item">
								<time className="event-day-date">
									<div className="day">{format(start_time, 'eee / MMM')}</div>
									<div className="date">{format(start_time, 'd')}</div>
								</time>
								<div className="event-information text-left">
									<div>{event.title}</div>
									<div>{event.venue}</div>
									<address>
										{event.address_components.street_number} {event.address_components.street}{' '}
										{event.address_apt}
										<br />
										{[event.address_components.city, event.address_components.state]
											.filter((c) => c)
											.join(', ')}{' '}
										{event.address_components.zip}
									</address>
									<div>
										<time className="time">{format(start_time, 'h:mm a')}</time>
									</div>
								</div>
							</div>
						</li>
					)
				})}
			</ul>
			{allow_appointment_registration && (
				<div className="row">
					<div className="col-12 text-center text-white mb-4" style={{ marginTop: '-0.75em' }}>
						Prefer a one-on-one?{' '}
						<button type="button" className="btn btn-warning rounded px-4 ml-2" onClick={() => {}}>
							Click to schedule an appointment
						</button>
					</div>
				</div>
			)}
		</div>
	)
}

Events.propTypes = {}

export default Events
