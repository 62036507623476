/**
 *
 * DragAndDropList/SingleInput
 *
 */

import React, { memo, useRef } from 'react'

// NPM Libraries
import PropTypes from 'prop-types'
import FloatingLabel from '@joeyparis/react-floating-label-input'
import { useDrag, useDrop } from 'react-dnd'
import { DraggableItems } from '..'

function SingleInput({ id, text, index, moveItem, setItem, label, ...props }) {
	const ref = useRef()

	const [, drop] = useDrop({
		accept: DraggableItems.LI,
		hover(item, monitor) {
			if (!ref.current) {
				return
			}
			const dragIndex = item.index
			const hoverIndex = index
			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return
			}
			// Determine rectangle on screen
			const hoverBoundingRect = ref.current.getBoundingClientRect()
			// Get vertical middle
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
			// Determine mouse position
			const clientOffset = monitor.getClientOffset()
			// Get pixels to the top
			const hoverClientY = clientOffset.y - hoverBoundingRect.top
			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%
			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return
			}
			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return
			}
			// Time to actually perform the action
			moveItem(dragIndex, hoverIndex)
			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex
		},
	})

	const [{ isDragging }, drag] = useDrag({
		item: { type: DraggableItems.LI, id, index },
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	})

	drag(drop(ref))

	return (
		<div ref={ref}>
			<FloatingLabel
				type="text"
				label={label}
				value={text || ''}
				onChange={(e) => setItem({ id, index, text: e.target.value })}
				onKeyDown={(e) => {
					if (e.keyCode === 13) {
						e.preventDefault()

						const nextInput = e.target.parentElement.parentElement.nextElementSibling
						if (nextInput) nextInput.querySelector('input').focus()
					}

					return false
				}}
				style={{
					opacity: isDragging ? 0.5 : 1,
					cursor: 'move',
				}}
				{...props}
			/>
		</div>
	)
}

SingleInput.propTypes = {
	id: PropTypes.string,
	text: PropTypes.string,
	index: PropTypes.number,
	moveItem: PropTypes.func,
	setItem: PropTypes.func,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
}

export default memo(SingleInput)
