/**
 *
 * CampaignTemplates/Show/Carousel
 *
 */

import React, { memo, useState, useEffect, useRef } from 'react'

// NPM Libraries
import PropTypes from 'prop-types'
import Carousel, { Dots } from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'

function CarouselWrapper({ images }) {
	const [slide, setSlide] = useState(0)
	const slide_ref = useRef(slide)
	const images_ref = useRef(images)
	slide_ref.current = slide
	images_ref.current = images

	const nextSlide = () => {
		if (images_ref.current && images_ref.current.length) {
			setSlide((slide_ref.current + 1) % images_ref.current.length)
		}
	}

	useEffect(() => {
		const interval = setInterval(nextSlide, 5000)
		return () => clearInterval(interval)
	}, [])

	if (!images || images.length === 0) {
		return null
	}

	return (
		<React.Fragment>
			<Carousel
				plugins={['arrows', 'infinite']}
				animationSpeed={1000}
				value={slide}
				slides={images.map((i) => (
					<img key={i} className="img-fluid" src={i} alt={i} />
				))}
				onChange={setSlide}
			/>
			<Dots
				number={4}
				thumbnails={images.map((i) => (
					<img key={i} style={{ height: 25 }} src={i} alt={i} />
				))}
				value={slide}
				onChange={setSlide}
			/>
		</React.Fragment>
	)
}

CarouselWrapper.defaultProps = {
	images: [
		'https://leadjig-landing-page-backgrounds.s3.amazonaws.com/production/landing-page-background-8e0889ae-ed25-4698-9f1c-246393f14712',
		'https://leadjig-landing-page-backgrounds.s3.amazonaws.com/production/landing-page-background-73f5bc75-0a58-45dd-9619-0be8f9ece97f',
		'https://leadjig-landing-page-backgrounds.s3.amazonaws.com/production/landing-page-background-8c70cfd9-4465-4559-beb0-0f1d389e64cd',
	],
}

CarouselWrapper.propTypes = {
	images: PropTypes.arrayOf(PropTypes.string),
}

export default memo(CarouselWrapper)
