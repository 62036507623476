/*
 * CmdK/CampaignSearch Messages
 *
 * This contains all the text for the CampaignSearch component.
 */

import { defineMessages } from 'react-intl'

export const scope = 'app.components.CmdKCampaignSearch'

export default defineMessages({
	header: {
		id: `${scope}.header`,
		defaultMessage: 'This is the CmdKCampaignSearch component!',
	},
})
