/**
 *
 * /Gallery
 *
 */

import React, { memo, useState, useEffect, useMemo } from 'react'

// NPM Libraries
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { matchSorter } from 'match-sorter'

// Components
import DropDownMenu from 'components/DropDownMenu'
import Icon from 'components/LandingPages/New/landing_page_icon.png'
import Modal from 'components/Modal'
import Spinner from 'components/Spinner'
import { MenuItem, TemplateGallery } from 'utils/custom-styled-components'

/* eslint-disable consistent-return */
function fuzzySearchMutipleWords(
	templates, // templates prop
	search_keys, // keys to search
	search_string, // potentially multi-word search string
) {
	if (search_string?.length) {
		const keys = search_keys.map((key) => `original.${key}`)

		const terms = search_string.split(' ')

		// reduceRight will mean sorting is done by score for the _first_ entered word.
		return terms.reduceRight(
			(results, term) => matchSorter(results, term, { keys, threshold: matchSorter.rankings.CONTAINS }),
			templates,
		)
	}
}

function Gallery({
	title,
	filter_options,
	initial_filter_key,
	search_keys,
	startFromScratch,
	templates,
	loading,
	scale,
	dropdown,
	dropdown_text,
}) {
	const [selected_filter_key, setFilterKey] = useState(initial_filter_key)
	const [search_text, setSearchText] = useState('')

	const [preview, setPreview] = useState(null)

	// const filterAndSearch = (filter_key, search_string) => {
	// 	let list = templates

	// 	if (filter_key !== 'All') {
	// 		list = templates.filter((template) => template.filter_key === filter_key)
	// 	}

	// 	if (search_string?.length > 0) {
	// 		list = fuzzySearchMutipleWords(list, search_keys, search_string)
	// 	}

	// 	/* eslint-disable no-return-assign */
	// 	templates.forEach((template) => (template['visible'] = list.some((li) => li.id === template.id)))
	// 	/* eslint-enable no-return-assign */
	// }

	const filtered_templates = useMemo(() => {
		let list = templates

		if (selected_filter_key !== 'All') {
			list = templates.filter((template) => template.filter_key === selected_filter_key)
		}

		if (search_text?.length > 0) {
			list = fuzzySearchMutipleWords(list, search_keys, search_string)
		}

		/* eslint-disable no-return-assign */
		// templates.forEach((template) => (template['visible'] = list.some((li) => li.id === template.id)))
		/* eslint-enable no-return-assign */
		return list

	}, [templates, selected_filter_key, search_text])

	const handleFilterChange = (filter_key) => {
		setFilterKey(filter_key)
	}

	const handleSearchChange = (e) => {
		setSearchText(e.target.value)
	}

	useEffect(() => {
		// If initial_filter_key is not in filter_options, set to 'All'. (For example, if it's a Lead campaign and we have no campaign templates for that)
		if (filter_options && !Object.keys(filter_options).includes(selected_filter_key)) {
			setFilterKey('All')
		}

	}, [filter_options, selected_filter_key])

	return (
		<TemplateGallery className="container-fluid text-center">
			<header className="py-5 d-inline-block">
				<h2 className="px-5">
					<img
						src={Icon}
						alt="landing page"
						style={{ height: '1.3em', marginRight: '1.3em', verticalAlign: 'bottom' }}
					/>
					{title}
				</h2>
				<hr className="my-4" />
				<div>
					{dropdown && filter_options && (
						<DropDownMenu
							Button={
								<div className="mr-4 pt-1">
									{dropdown_text} {filter_options[selected_filter_key]}{' '}
									<FontAwesomeIcon icon="caret-down" />
								</div>
							}
						>
							<ul className="list-unstyled">
								{Object.keys(filter_options).map((key) => (
									<li key={key} onClick={() => handleFilterChange(key)} role="presentation">
										{filter_options[key]}
									</li>
								))}
							</ul>
						</DropDownMenu>
					)}
					{filter_options && !dropdown && (
						<React.Fragment>
							<MenuItem
								onClick={() => handleFilterChange('All')}
								className={selected_filter_key === 'All' && 'selected-menu-item'}
							>
								All
							</MenuItem>
							{Object.keys(filter_options).map((key) => (
								<MenuItem
									key={key}
									onClick={() => handleFilterChange(key)}
									className={key === selected_filter_key && 'selected-menu-item'}
								>
									{filter_options[key]}
								</MenuItem>
							))}
						</React.Fragment>
					)}
					{search_keys?.length > 0 && (
						<MenuItem className="position-relative border rounded menu-search-box">
							<input
								type="text"
								placeholder="Search"
								value={search_text}
								onChange={handleSearchChange}
								className="border-0 place-tl menu-search w-100 h-100 menu-search-input"
							/>
							<FontAwesomeIcon icon="search" className="place-tl mt-2 ml-2" />
						</MenuItem>
					)}
				</div>
			</header>
			{loading && (
				<div className="text-center py-4">
					<Spinner />
				</div>
			)}
			<div className="row">
				{startFromScratch && (
					<div className="col-md-4 col-sm-6 text-center pb-5">
						<div
							role="presentation"
							className="d-flex flex-column justify-content-center align-items-center clickable template-gallery-box"
							style={{ border: '2px dashed #16a1d4', color: '#16a1d4' }}
							onClick={startFromScratch}
						>
							<img src={Icon} alt="landing page" style={{ marginBottom: '1.5rem', width: '55px' }} />
							<h5>Start From Scratch</h5>
						</div>
					</div>
				)}
				{filtered_templates.map((template) => (
					<div
						className="col-md-4 col-sm-6 text-center pb-4"
						key={template.id}
					>
						<div className="template-gallery-box template-preview-box">
							<div
								className="template-preview"
								style={{
									transform: `scale(${scale})`,
									width: `${100 / scale}%`,
									height: `${100 / scale}%`,
								}}
							>
								{template.show}
							</div>
							<div
								role="presentation"
								className="w-100 h-100 place-tl clickable click-to-preview"
								onClick={() => setPreview(template.show)}
							>
								<div className="preview-message pb-2">
									Click to Preview &nbsp;
									<FontAwesomeIcon icon="search" />
								</div>
							</div>
						</div>
						{template.content_below}
					</div>
				))}
			</div>
			<Modal
				show={!!preview}
				modalDidClose={() => setPreview(null)}
				style={{ padding: 0, width: '900px', height: '95%' }}
				containerStyle={{ zIndex: 1051 }}
			>
				{preview}
			</Modal>
		</TemplateGallery>
	)
}

Gallery.defaultProps = {
	initial_filter_key: 'All',
	scale: 0.37,
	templates: [],
}

Gallery.propTypes = {
	title: PropTypes.string.isRequired,
	filter_options: PropTypes.object, // keys correspond to the filter_key of the templates
	initial_filter_key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	startFromScratch: PropTypes.func,
	templates: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			show: PropTypes.element, // to be displayed large or small
			content_below: PropTypes.element,
			filter_key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // corresponds to filter_options or dropdown_options.
			original: PropTypes.object, // the original object
			visible: PropTypes.bool,
		}),
	),
	search_keys: PropTypes.arrayOf(PropTypes.string), // attributes of the original object to search
	scale: PropTypes.number, // a number less than 1 indicating how small to make the previews. Such as .33 for 33%.
	dropdown_text: PropTypes.string,
	dropdown: PropTypes.bool,
}

export default memo(Gallery)
