/**
 *
 * /UploadCampaignImage
 *
 */

import React from 'react'

// GraphQL
import UseQuery from 'components/UseQuery'
import { useMutation, gql } from '@apollo/client'

// Components
import ImageUploader from 'components/ImageUploader'

const GET_CAMPAIGN_IMAGE_UPLOAD = gql`
	query getCampaignImageUpload($id: ID) {
		campaign(id: $id) {
			id
			campaign_image_url
		}
	}
`
const CREATE_CAMPAIGN_IMAGE_UPLOAD = gql`
	mutation CreateCampaignImageUpload($id: ID!, $image_file: Upload) {
		uploadCampaignImage(input: { id: $id, image_file: $image_file, is_campaign_image: true }) {
			campaign {
				id
				campaign_image_url
			}
		}
	}
`
const DELETE_CAMPAIGN_IMAGE = gql`
	mutation DeleteCampaignImage($id: ID!, $campaign_image_url: String) {
		deleteCampaignImage(input: { id: $id, campaign_image_url: $campaign_image_url }) {
			campaign {
				id
			}
		}
	}
`
function UploadCampaignImage({ campaign, campaign_image_url, onChange }) {
	const { data } = UseQuery(GET_CAMPAIGN_IMAGE_UPLOAD, { variables: { id: campaign.id } })

	const [createCampaignImage] = useMutation(CREATE_CAMPAIGN_IMAGE_UPLOAD, {
		variables: { image_file: campaign.image_file },
		onCompleted: ({ uploadCampaignImage: { campaign: new_campaign } }) => onChange(new_campaign.campaign_image_url),
	})

	const [deleteCampaignImage] = useMutation(DELETE_CAMPAIGN_IMAGE, {
		variables: { campaign_image_url },
		onCompleted: () => onChange(null),
	})

	const handleCreate = (image_file) => {
		createCampaignImage({
			variables: {
				id: campaign.id,
				image_file,
			},
		})
	}

	const handleRemove = () => {
		deleteCampaignImage({
			variables: {
				id: campaign.id,
				image_file: campaign_image_url,
			},
		})
	}

	if (!data.campaign) return null

	return (
		<div>
			<div className="form-text text-muted mb-2">Campaign Image</div>
			<ImageUploader
				image_url={data.campaign.campaign_image_url}
				uploadImage={handleCreate}
				removeImage={handleRemove}
			/>
		</div>
	)
}

UploadCampaignImage.propTypes = {}

export default UploadCampaignImage
