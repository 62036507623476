import gql from 'graphql-tag'
import useQuery from 'components/UseQuery'

const GET_CAMPAIGN_TOPIC_OPTIONS = gql`
	query topics {
		topics {
			id
			value
			text
		}
	}
`

export const useCampaignTopicOptions = () => {
	const {
		data: { topics },
	} = useQuery(GET_CAMPAIGN_TOPIC_OPTIONS, {
		default_data: { topics: [] },
	})

	return topics
}

export default useCampaignTopicOptions
