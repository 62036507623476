/**
 *
 * Campaigns/Form/OptIn
 *
 */

import React, { memo, useEffect } from 'react'

// Graphql
import useQuery from 'components/UseQuery'
import { gql } from '@apollo/client'

// Components
import DropDownMenu from 'components/DropDownMenu'
import OptInRequired from 'components/Preferences/OptIns/Required'

const GET_OPT_INS = gql`
	query user($user_id: ID!) {
		user(id: $user_id) {
			id
			opt_ins {
				id
				name
				text
				global_default
			}
			opt_in_id
			handle_opt_in
		}
	}
`

function OptIn({ user_id, campaign_state, mergeState, disabled }) {
	const {
		data: { user },
		loading,
	} = useQuery(GET_OPT_INS, {
		variables: { user_id },
		channels: [user_id],
	})

	useEffect(() => {
		// change a custom opt_in if the user changes and the old opt_in is not found.
		if (
			campaign_state.opt_in_id?.length &&
			user?.opt_ins &&
			!user.opt_ins.some((opt_in) => opt_in.id === campaign_state.opt_in_id)
		) {
			mergeState({ opt_in_id: user.opt_in_id, handle_opt_in: user.handle_opt_in })
		}
	}, [JSON.stringify(user)])

	const selected_opt_in = user?.opt_ins.find((opt_in) => opt_in.id === campaign_state.opt_in_id)

	return (
		<div>
			<div className="d-flex flex-wrap">
				<span className="text-muted mr-3">Contact Opt-In:</span>

				<DropDownMenu
					Button={selected_opt_in?.name || (loading ? '' : 'None')}
					className="mr-4"
					position="right"
					disabled={disabled}
				>
					<ul className="list-unstyled" style={{ maxWidth: '75vw' }}>
						<li onClick={() => mergeState({ opt_in_id: null, handle_opt_in: null })} role="presentation">
							None
						</li>
						{user?.opt_ins.map((opt_in) => (
							<li
								key={opt_in.id}
								onClick={() =>
									mergeState({
										opt_in_id: opt_in.id,
										handle_opt_in: 1,
									})
								}
								role="presentation"
								className="d-flex"
							>
								<div className="mr-3 font-weight-bold">{opt_in.name}</div>
								<div className="overflow-hidden text-muted">{opt_in.text}</div>
							</li>
						))}
					</ul>
				</DropDownMenu>
			</div>
			{selected_opt_in && (
				<OptInRequired
					handle_opt_in={campaign_state.handle_opt_in}
					onChange={(value) => mergeState({ handle_opt_in: value })}
					opt_in={selected_opt_in}
					disabled={disabled}
				/>
			)}
		</div>
	)
}

OptIn.propTypes = {}

export default memo(OptIn)
