/**
 *
 * Campaigns/Create/SelectUser
 *
 */

import React, { useState } from 'react'

// Components
import CircleImage from 'components/CircleImage'
import Modal from 'components/Modal'
import UsersSelect from 'components/UsersSelect/WithData'

// Utils
import { withCurrentUser } from 'currentUserContext'

function SelectUser({ setUser, user, disabled }) {
	const [modal_open, setModalOpen] = useState(false)

	return (
		<div>
			<div className="py-3 d-flex justify-content-center align-items-center">
				<CircleImage url={user ? user.profile_image : ''} size="4rem" />
				<div className="ml-3">
					<span className="font-weight-bold">{user ? user.full_name : ' '}</span>
					<div>{user ? user.email : ' '}</div>
					{!disabled && (
						<button type="button" className="btn-unstyled" onClick={() => setModalOpen(true)}>
							{user ? 'Change Campaign Owner' : 'Select Campaign Owner'}
						</button>
					)}
				</div>
			</div>

			<Modal show={modal_open} modalDidClose={() => setModalOpen(false)}>
				<UsersSelect
					onSelect={(select_user) => {
						setUser(select_user)
						setModalOpen(false)
					}}
				/>
			</Modal>
		</div>
	)
}

SelectUser.propTypes = {}

export default withCurrentUser(SelectUser)
