import React, { useRef, useEffect, useState, createContext } from 'react'
import { useIdentifier } from 'hooks'
import { sleep } from '@joeyparis/utils'
import html2canvas from 'html2canvas'

export const ScreenshotContext = createContext(false)

export const useScreenshot = () => {
	const identifier = useIdentifier()
	const [taking_screenshot, setTakingScreenshot] = useState(false)
	const [screenshot, setScreenshot] = useState({})
	const screenshot_request = useRef()

	const prepareForScreenshot = () => {
		if (window.Intercom) {
			window.Intercom('update', { hide_default_launcher: true })
			document.querySelector('body').style.pointerEvents = 'none'
		}
		setTakingScreenshot(true)

		// setTimeout(() => {
		// 	screenshot_request.current = requestAnimationFrame(takeScreenshot)
		// }, 50)
	}

	const completeScreenshot = () => {
		if (window.Intercom) {
			window.Intercom('update', { hide_default_launcher: false })
			document.querySelector('body').style.pointerEvents = ''
		}
		setTakingScreenshot(false)
	}

	const takeScreenshot = async () => {
		screenshot_request.current = requestAnimationFrame(prepareForScreenshot)
		// await sleep(50)
		/* eslint-disable no-undef */
		await sleep(50)
		const canvas = await html2canvas(document.body, {
			allowTaint: true,
			useCORS: true
		})
		
		const base64image = canvas.toDataURL("image/png");
		setScreenshot(base64image)

		/* eslint-enable no-undef */
		screenshot_request.current = requestAnimationFrame(completeScreenshot)

		return base64image
	}

	return [taking_screenshot, takeScreenshot, screenshot]
}

export const ScreenshotProvider = ({ children }) => {
	const [taking_screenshot, takeScreenshot, screenshot] = useScreenshot()

	return (
		<ScreenshotContext.Provider value={{ taking_screenshot, takeScreenshot, screenshot }}>
			{children}
		</ScreenshotContext.Provider>
	)
}

export default useScreenshot
