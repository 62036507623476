/**
 *
 * CampaignTemplates/Show/Countdown
 *
 */

import React, { memo, useState, useEffect } from 'react'

// NPM Libraries
import styled from '@emotion/styled'
import plural from 'pluralize'
import { addMinutes, differenceInSeconds } from 'date-fns'

// Utils
import { COLORS } from 'global-styles'
import { toLocalDate } from 'components/utils'

const CountdownWrapper = styled('div')`
	max-width: 32rem;
	margin: 40px auto;

	.count-box {
		/* Display, Positioning, Sizing */
		display: inline-block;
		vertical-align: top;
		width: 21%;
		padding: 0.4em 0.2em 0.7em;
		margin: 0 0.5em;

		/* Border */
		border: 2px solid ${COLORS.grey_9};
		border-radius: 4px;

		/* Font Styling */
		color: ${COLORS.primary};
		text-transform: uppercase;
		font-weight: bold;
		text-align: center;

		.number {
			font-size: 1.8em;
			color: ${COLORS.warning};
		}
	}
`

function Countdown({ campaign, next_event_date, active_event }) {
	const [end_time, setEndTime] = useState()
	const [countdown_time, setTime] = useState({})
	const [title, setTitle] = useState()

	useEffect(() => {
		let countdown_title
		let new_end_time

		switch (campaign.objective) {
			case 0:
				countdown_title = active_event ? 'Your event begins in:' : 'Next event begins in:'

				if (active_event) {
					new_end_time = active_event.start_time
				} else if (next_event_date) {
					new_end_time = next_event_date
				} else {
					new_end_time = undefined
				}

				// make moment obj with local time
				if (new_end_time) {
					new_end_time = toLocalDate(new_end_time)
				}
				break
			case 3:
				countdown_title = 'Download available in:'
				new_end_time = addMinutes(new Date(), 1)
				break
			case 5:
				countdown_title = 'Webinar starts in:'
				new_end_time = addMinutes(new Date(), 1)
				break

			default:
				break
		}
		setEndTime(new_end_time)
		setTitle(countdown_title)

		const interval = new_end_time && setInterval(() => update(new_end_time), 1000)

		return () => clearInterval(interval)
	}, [campaign.objective, next_event_date, active_event])

	function update(new_end_time) {
		const now = toLocalDate(Date.now())
		const diff = differenceInSeconds(new_end_time, now)
		if (diff <= 0) {
			if (countdown_time.seconds !== 0) {
				setTime({ days: 0, hours: 0, minutes: 0, seconds: 0 })
			}
			return
		}
		const days = Math.floor(diff / 86400)
		const hours = Math.floor((diff % 86400) / 3600)
		const minutes = Math.floor((diff % 3600) / 60)
		const seconds = diff % 60
		setTime({ days, hours, minutes, seconds })
	}

	return end_time ? (
		<CountdownWrapper>
			<h4 className="mb-3 countdown-title ml-2">{title}</h4>
			<div>
				<div className="count-box">
					<div className="number">{countdown_time.days}</div>
					<div>{plural('Day', countdown_time.days)}</div>
				</div>
				<div className="count-box">
					<div className="number">{countdown_time.hours}</div>
					<div>{plural('Hour', countdown_time.hours)}</div>
				</div>
				<div className="count-box">
					<div className="number">{countdown_time.minutes}</div>
					<div>{plural('Minute', countdown_time.minutes)}</div>
				</div>
				<div className="count-box">
					<div className="number">{countdown_time.seconds}</div>
					<div>{plural('Second', countdown_time.seconds)}</div>
				</div>
			</div>
		</CountdownWrapper>
	) : null
}

Countdown.propTypes = {}

export default memo(Countdown)
