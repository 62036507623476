/* stylelint-disable no-descending-specificity */

/**
 *
 * ImageUploader
 *
 */

import React, { useState, useCallback } from 'react'

// NPM Libraries
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Global, css } from '@emotion/react'
import { useDropzone } from 'react-dropzone'

// Components
import CircleImage from 'components/CircleImage'

// Utils
import { scaleToFit } from 'utils/image_processing'
import { formatBytes } from 'components/utils'

const styles = css`
	.imageUploader {
		width: 100%;
		min-height: 250px;
		text-align: center;
		position: relative;
	}

	.imageUploaderActive {
		border: 2px dashed;
	}

	.imageUploaderAcceptable {
		border-color: #1abc9c;
	}

	.imageUploaderRejectable {
		border-color: #eb6b56;
	}

	.imageUploaderUploading {
		opacity: 0.5;
	}

	/* stylelint-disable no-duplicate-selectors */
	.imageUploaderNewImage,
	.imageUploaderOldImage {
		opacity: 1;
		transition: opacity 250ms;
		position: absolute;
	}

	.imageUploaderOldImage,
	.imageUploaderNewImage {
		height: 250px;
	}
	/* stylelint-enable no-duplicate-selectors */

	.imageUploader .imageUploaderOldImage.actualSize,
	.imageUploader .imageUploaderNewImage.actualSize {
		height: unset;
		position: static;
	}

	.imageUploaderNewImage + .imageUploaderOldImage,
	.imageUploaderNewImage + .imageUploaderOldImage.actualSize {
		opacity: 0;
		height: 250px;
		position: absolute;
	}
`

function ImageUploader({
	fallback_image,
	image_url,
	removeImage,
	uploadImage,
	display_image_as_circle,
	accept,
	max_width,
	max_height,
	resizeable,
	actual_size,
}) {
	const [circle, setCircle] = useState(display_image_as_circle)
	const [new_image_file, setNewImageFile] = useState()
	const [new_image_file_url, setNewImageFileURL] = useState()
	const [processed_image_file, setProcessedImageFile] = useState()
	const [fit_width, setFitWidth] = useState(max_width)
	const [fit_height, setFitHeight] = useState(max_height)
	const [image_dimensions, setDimensionsText] = useState()
	const [image_size, setBytesText] = useState()

	const onDrop = useCallback((acceptedFiles) => {
		if (acceptedFiles[0]) {
			setNewImageFile(acceptedFiles[0])
			setCircle(false)

			if (max_width && max_height) {
				scaleToFit({
					file: acceptedFiles[0],
					width: max_width,
					height: max_height,
					force: false,
					setFile: setProcessedImageFile,
					setUrl: setNewImageFileURL,
					setBytesText,
					setDimensionsText,
				})
			} else {
				setNewImageFileURL(URL.createObjectURL(acceptedFiles[0]))
				setBytesText(formatBytes(acceptedFiles[0].size))
			}
		}
	}, [])

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		multiple: false,
		accept: accept || 'image/*',
	})

	const uploadNewImageFile = () => {
		if (uploadImage(processed_image_file || new_image_file, circle) !== false) {
			setNewImageFile()
			setProcessedImageFile()
			setBytesText()
			setDimensionsText()
		}
	}

	const clearNewImageFile = () => {
		setNewImageFile()
		setProcessedImageFile()
		setBytesText()
		setDimensionsText()
	}

	async function increaseSize() {
		const width = fit_width * 1.1
		const height = fit_height * 1.1
		setFitWidth(width)
		setFitHeight(height)
		scaleToFit({
			file: new_image_file,
			width,
			height,
			force: true,
			setFile: setProcessedImageFile,
			setUrl: setNewImageFileURL,
			setBytesText,
			setDimensionsText,
		})
	}

	async function reduceSize() {
		const width = fit_width / 1.1
		const height = fit_height / 1.1
		setFitWidth(width)
		setFitHeight(height)
		scaleToFit({
			file: new_image_file,
			width,
			height,
			force: true,
			setFile: setProcessedImageFile,
			setUrl: setNewImageFileURL,
			setBytesText,
			setDimensionsText,
		})
	}

	const has_old_image = Boolean(image_url)
	const has_new_image_file = new_image_file
	const has_image = has_old_image || has_new_image_file
	const has_circle_option = typeof display_image_as_circle === 'boolean'
	const has_changes = has_new_image_file || circle !== display_image_as_circle

	return (
		<div className="mb-0">
			<Global styles={styles} />
			<div className="d-flex align-items-center justify-content-center imageUploader" {...getRootProps()}>
				<input {...getInputProps()} />
				{has_new_image_file &&
					(circle ? (
						<CircleImage
							url={new_image_file_url}
							size="250px"
							className="imageUploaderNewImage"
							alt="new-file"
						/>
					) : (
						<img
							className={classnames('imageUploaderNewImage', { actualSize: actual_size || resizeable })}
							src={new_image_file_url}
							alt="new-file"
						/>
					))}
				{circle ? (
					<CircleImage
						url={`${image_url || fallback_image}?${new Date().getTime()}`}
						size="250px"
						alt="old-file"
						className="imageUploaderOldImage"
					/>
				) : (
					<img
						alt="old-file"
						className={classnames('imageUploaderOldImage', { actualSize: actual_size })}
						src={`${image_url || fallback_image}?${new Date().getTime()}`}
					/>
				)}
			</div>

			<div className="mx-auto overflow-hidden mt-3" style={{ maxWidth: 580 }}>
				<div className="float-left">
					{has_old_image && !has_new_image_file && (
						<button type="button" className="btn-unstyled card-link text-danger" onClick={removeImage}>
							Remove
						</button>
					)}
					{!has_new_image_file && (
						<button
							type="button"
							className="btn-unstyled card-link text-primary"
							onClick={getRootProps().onClick}
						>
							Select Image to Upload
						</button>
					)}
					{has_image && has_circle_option && (
						<button
							type="button"
							className="btn-unstyled card-link text-primary"
							onClick={() => setCircle(!circle)}
						>
							{circle ? 'As Rectangle' : 'As Circle'}
						</button>
					)}
					{has_new_image_file && (
						<button
							type="button"
							className="btn-unstyled card-link text-danger"
							onClick={clearNewImageFile}
						>
							Cancel
						</button>
					)}
					{has_changes && (
						<button
							type="button"
							className="btn-unstyled card-link text-success"
							onClick={uploadNewImageFile}
						>
							Save
						</button>
					)}
					<br />
					{image_size} <span className="ml-3">{image_dimensions}</span>
				</div>
				{resizeable && has_new_image_file && (
					<div className="float-right">
						<button type="button" className="btn-unstyled mx-1 p-1" onClick={reduceSize}>
							<FontAwesomeIcon icon="minus-square" size="lg" />
						</button>
						<button type="button" className="btn-unstyled p-1" onClick={increaseSize}>
							<FontAwesomeIcon icon="plus-square" size="lg" />
						</button>
					</div>
				)}
			</div>
		</div>
	)
}

ImageUploader.propTypes = {
	removeImage: PropTypes.func,
	uploadImage: PropTypes.func,
	image_url: PropTypes.string,
	fallback_image: PropTypes.string,
	display_image_as_circle: PropTypes.bool,
}

ImageUploader.defaultProps = {
	fallback_image: '/images/jim_lead_266x266.png',
}

export default ImageUploader
