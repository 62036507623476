/**
 *
 * Campaigns/Form/CarouselImages
 *
 */

import React, { memo, useState, useCallback } from 'react'

// NPM Libraries
import update from 'immutability-helper'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

// GraphQL
import { gql, useMutation } from '@apollo/client'

// Components
import DragAndDropImage from 'components/Campaigns/Form/CarouselImages/DragAndDropImage'
import ImageUploader from 'components/ImageUploader'
import Modal from 'components/Modal'
import MutationStatusIndicator from 'components/MutationStatusIndicator'

// Utils
import { Campaign } from 'globalVars'

const UPLOAD_CAROUSEL_IMAGE = gql`
	mutation uploadImage($id: ID!, $image_file: Upload) {
		uploadCampaignImage(input: { id: $id, image_file: $image_file }) {
			image_url
		}
	}
`

function CarouselImages({ campaign_state: { id, carousel_images, objective_index }, setImages }) {
	const [show_edit, setShowEdit] = useState(false)
	const [show_add_more, setShowAddMore] = useState(false)

	const [draggable_images, setDraggableImages] = useState(
		carousel_images.map((url) => ({
			id: Math.random().toString(36).substring(2),
			url,
		})),
	)

	const [mutate, mutation_status] = useMutation(UPLOAD_CAROUSEL_IMAGE, {
		onCompleted: (data) => {
			const new_draggable_image = {
				id: Math.random().toString(36).substring(2),
				url: data.uploadCampaignImage.image_url,
			}
			setDraggableImages([...draggable_images, new_draggable_image])
		},
	})

	const has_images = !!draggable_images?.length
	const campaign_type = Campaign.OBJECTIVES[objective_index] || 'this type of'

	const uploadImage = (image_file) => {
		mutate({
			variables: {
				id,
				image_file,
			},
		})
		setShowAddMore(false)
	}

	const finishEditing = () => {
		setImages(draggable_images.map((data) => data.url))
		setShowEdit(false)
		setShowAddMore(false)
	}

	const removeImage = (remove_id) => {
		setDraggableImages(draggable_images.filter((data) => data.id !== remove_id))
	}

	const moveItem = useCallback(
		(dragIndex, hoverIndex) => {
			const dragCard = draggable_images[dragIndex]

			setDraggableImages(
				update(draggable_images, {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, dragCard],
					],
				}),
			)
		},
		[JSON.stringify(draggable_images)],
	)

	return (
		<div>
			{carousel_images?.length > 0 && (
				<React.Fragment>
					<div className="form-text">Carousel Images</div>
					<div className="d-flex">
						{carousel_images.map((url, index) => (
							<img
								key={index}
								src={url}
								alt={`Img ${index + 1}`}
								className="m-3"
								style={{ maxHeight: '10rem' }}
							/>
						))}
					</div>
				</React.Fragment>
			)}
			<button
				type="button"
				className="btn btn-primary rounded"
				onClick={() => {
					setShowEdit(true)
					if (!has_images) {
						setShowAddMore(true)
					}
				}}
			>
				Carousel Images
			</button>
			<br />
			{show_edit && (
				<Modal show={show_edit} modalDidClose={finishEditing}>
					<h4>
						Carousel Images <MutationStatusIndicator {...mutation_status} />
					</h4>
					{has_images && !show_add_more && (
						<React.Fragment>
							<ul style={{ paddingInlineStart: '20px' }}>
								<li>
									<button
										type="button"
										className="btn-unstyled"
										onClick={() => setShowAddMore(true)}
										disabled={mutation_status.loading}
									>
										Add another image
									</button>
								</li>
								{draggable_images.length > 1 && <li>Drag images up or down to change order.</li>}
								<li>
									Enable image carousel for {campaign_type} campaigns in Landing Page Preferences.
								</li>
							</ul>

							<DndProvider backend={HTML5Backend}>
								{draggable_images.map((data, index) => (
									<DragAndDropImage
										key={data.id}
										id={data.id}
										index={index}
										removeImage={() => removeImage(data.id)}
										moveItem={moveItem}
										data={data}
									/>
								))}
							</DndProvider>
							<div className="text-center pt-3">
								<button className="btn btn-success rounded" onClick={finishEditing}>
									Done
								</button>
							</div>
						</React.Fragment>
					)}
					{show_add_more && (
						<React.Fragment>
							<ImageUploader
								uploadImage={uploadImage}
								max_width={600}
								max_height={400}
								accept="image/jpeg, image/png, image/tiff, image/gif"
							/>
						</React.Fragment>
					)}
				</Modal>
			)}
		</div>
	)
}

CarouselImages.propTypes = {}

export default memo(CarouselImages)
