/**
 *
 *  CmdK/CampaignSearch
 *
 */

import React, { memo, useEffect } from 'react'
// NPM Libraries
// import PropTypes from 'prop-types'
// import styled from '@emotion/styled'
import { useHistory } from 'react-router-dom'
import { Command } from 'cmdk'
import { useIdentifier } from '@joeyparis/hooks'

// GraphQL
import useQuery from 'components/UseQuery'
// import { gql, useMutation } from '@apollo/client'
import { SEARCH_CAMPAIGNS } from 'components/Campaigns/Index'

import { FormattedMessage } from 'react-intl'
import messages from './messages'

function CampaignSearch({ search, setLoading, onSelect }) {
	const history = useHistory()
	const id = useIdentifier()

	const { data, loading } = useQuery(SEARCH_CAMPAIGNS, {
		variables: {
			search,
		},
		default_data: {
			search: {
				results: [],
			},
		},
		skip: !search || search.length < 3,
	})

	useEffect(() => {
		setLoading(id, loading)
	}, [loading])

	useEffect(() => {
		return () => {
			setLoading(id, false)
		}
	}, [])

	return (
		<Command.Group heading="Campaigns">
			{data.search.results.map((campaign) => (
				<Command.Item
					key={campaign.id}
					value={`${campaign.job_codes.join(", ")} - ${campaign.name}`}
					onSelect={() => {
						history.push(`/campaigns/${campaign.id}`)
						if (onSelect) onSelect()
					}}
				>
					{campaign.job_codes.join(", ")} - {campaign.name}
				</Command.Item>
			))}
		</Command.Group>
	)
}

CampaignSearch.propTypes = {}

export default memo(CampaignSearch)
