/**
 *
 *  /CmdK
 *
 */

import React, { memo, useState, useMemo, useEffect } from 'react'

// NPM Libraries
// import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Command } from 'cmdk'
import { titleize } from 'components/utils'
import { useSet } from '@joeyparis/hooks'
import { useHistory } from 'react-router-dom'
import create from 'zustand'

// GraphQL
// import useQuery from 'components/UseQuery'
// import { gql } from '@apollo/client'

import GlobalSearch from 'components/CmdK/GlobalSearch'
import CampaignSearch from 'components/CmdK/CampaignSearch'
import UserSearch from 'components/CmdK/UserSearch'
import NavigationMenuItems from 'components/CmdK/NavigationMenuItems'

import './index.scss'

const useCommandStore = create((set, get) => ({
	open: false,
	setOpen: (open) => set({ open }),
	commands: new Set(),
	registerCommand: (command) => set(state => {
		const new_state = new Set(state.commands)
		new_state.add(command)
		return { commands: new_state }
	}),
	unregisterCommand: (command) => set(state => {
		const new_state = new Set(state.commands)
		new_state.delete(command)
		return { commands: new_state }
	}),
}))

export const useRegisterCmdKActions = (new_commands=[], dependencies=[]) => {
	const store = useCommandStore()
	const { registerCommand, unregisterCommand, commands } = useCommandStore()

	const actions_cache = useMemo(() => {
		if (typeof new_commands === 'function') {
			new_commands = new_commands(store)
		}

		return new_commands
	}, dependencies)

	useEffect(() => {
		actions_cache.forEach((command) => {
			registerCommand(command)
		})

		return () => {
			actions_cache.forEach((command) => {
				unregisterCommand(command)
			})
		}
	}, [actions_cache])

	return commands
}

function CmdK() {
	const history = useHistory()

	const registered_commands = useCommandStore(({ commands }) => commands)
	const open = useCommandStore(({ open }) => open)
	const setOpen = useCommandStore(({ setOpen }) => setOpen)

	const [search, setSearch] = useState('')
	const [pages, setPages] = useState([])
	const page = pages[pages.length - 1]

	const [loading_list,addLoading,removeLoading] = useSet([])
	const loading = loading_list.size > 0

	// Toggle the menu when ⌘K is pressed
	useEffect(() => {
		const down = (e) => {
			if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
				e.preventDefault()
				setOpen((open) => !open)
			}
		}

		document.addEventListener('keydown', down)
		return () => document.removeEventListener('keydown', down)
	}, [])

	useEffect(() => {
		if (!open) {
			setPages([])
			setSearch('')
		}
	}, [open])

	const navigate = (path) => {
		history.push(path)
		setOpen(false)
		setSearch('')
	}
	

	return (
		<Command.Dialog open={open} onOpenChange={setOpen} label="Global Command Menu">
			<Command
				loop
				onKeyDown={(e) => {
					// Escape goes to previous page
					// Backspace goes to previous page when search is empty
					if (e.key === 'Escape' || (e.key === 'Backspace' && !search)) {
						e.preventDefault()
						setPages((pages) => pages.slice(0, -1))
					}
				}}
			>
				<div>
					{pages.map((p) => (
						<div key={p} cmdk-vercel-badge="">
						{titleize(p)}
						</div>
					))}
				</div>
				<Command.Input value={search} onValueChange={setSearch} />
				<Command.List>
					{loading && <Command.Loading>Hang on…</Command.Loading>}
					{!loading && <Command.Empty>No results found.</Command.Empty>}

					<React.Fragment>
						{Array.from(registered_commands)}
					</React.Fragment>

					{!page && (
						<React.Fragment>
							<Command.Group heading="Search">
								<Command.Item
									key="campaign_search"
									onSelect={() => {
										setPages((pages) => [...pages, 'campaign_search'])
										setSearch('')
									}}
								>
									Campaign Search
								</Command.Item>
								<Command.Item
									key="user_search"
									onSelect={() => {
										setPages((pages) => [...pages, 'user_search'])
										setSearch('')
									}}
								>
									User Search
								</Command.Item>
								<Command.Item
									key="global_search"
									forceMount
									value={search}
									onSelect={() => {
										setPages((pages) => [...pages, 'global_search'])
									}}
								>
									Global Search
								</Command.Item>
							</Command.Group>
							<NavigationMenuItems
								onSelect={(path) => {
									navigate(path)
								}}
								setLoading={(id, l) => l ? addLoading(id) : removeLoading(id)}
							/>
						</React.Fragment>
					)}

					{page === 'global_search' && (
						<GlobalSearch
							search={search}
							setLoading={(id, l) => l ? addLoading(id) : removeLoading(id)}
							onSelect={() => setOpen(false)}
						/>
					)}

					{page === 'campaign_search' && (
						<CampaignSearch
							search={search}
							setLoading={(id, l) => l ? addLoading(id) : removeLoading(id)}
							onSelect={() => setOpen(false)}
						/>
					)}

					{page === 'user_search' && (
						<UserSearch
							search={search}
							setLoading={(id, l) => l ? addLoading(id) : removeLoading(id)}
							onSelect={() => setOpen(false)}
						/>
					)}
				</Command.List>
			</Command>
		</Command.Dialog>
	)
}

CmdK.propTypes = {}

export default memo(CmdK)
