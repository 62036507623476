import * as React from 'react'

import {
	getBreaksNeededForEmptyLineAfter,
	getBreaksNeededForEmptyLineBefore,
	selectWord,
} from 'react-mde/lib/js/util/MarkdownUtil'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const center_command = {
	name: 'center',
	icon: () => <FontAwesomeIcon icon="align-center" />,
	buttonProps: { 'aria-label': 'Center text' },
	execute: ({ initialState, textApi }) => {
		// Adjust the selection to encompass the whole word
		const newSelectionRange = selectWord({
			text: initialState.text,
			selection: initialState.selection,
		})
		const state1 = textApi.setSelectionRange(newSelectionRange)

		const breaksBeforeCount = getBreaksNeededForEmptyLineBefore(state1.text, state1.selection.start)
		const breaksBefore = Array(breaksBeforeCount + 1).join('\n')

		const breaksAfterCount = getBreaksNeededForEmptyLineAfter(state1.text, state1.selection.end)
		const breaksAfter = Array(breaksAfterCount + 1).join('\n')

		// Replaces the current selection with the quote mark up
		textApi.replaceSelection(`${breaksBefore}->${state1.selectedText}<-${breaksAfter}`)

		const selectionStart = state1.selection.start + breaksBeforeCount + 2
		const selectionEnd = selectionStart + state1.selectedText.length

		textApi.setSelectionRange({
			start: selectionStart,
			end: selectionEnd,
		})
	},
}

