// Core React
import React from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'

// React Toolbox Components

// Custom Components

// NPM Packages

// Custom Utils

// CSS

export default class EmailTemplates extends React.Component
{
	constructor( props )
	{
		super( props )

		this.state = {
			templates: this.templatesByCategory( props.templates )
		}
	}

	templatesByCategory = ( templates ) => {

		let templates_by_category = {}
		templates.forEach( template => {
			if( !templates_by_category[template.email_template_name] )
				templates_by_category[template.email_template_name] = []
			templates_by_category[template.email_template_name].push( template )
		})

		return templates_by_category
	}

	selectTemplate = ( template ) => {
		this.props.selectTemplate( template.template_index, template.id )
	}

	render()
	{
		return(
			<div>
				<div className="card-columns">
					{Object.keys(this.state.templates).map(key =>
					<div key={key} className="card">
						<div className="card-header">
							<h3 className="card-title">{key}</h3>
						</div>
						<ul className="list-group list-group-flush">
							{this.state.templates[key].map(template =>
							<li className="list-group-item" key={template.id}>

								<label className="custom-control custom-radio custom-control-inline mb-0 mr-0">
									<input
										type="radio"
										className="custom-control-input"
										checked={(!this.props.template_defaults[template.template_index] && template.is_default) || this.props.template_defaults[template.template_index] === template.id}
										name={template.template_index}
										onChange={() => this.selectTemplate(template)}
									/>
									<span className="custom-control-label">&nbsp;</span>
								</label>

								{template.name}
							</li>
							)}
						</ul>
					</div>
					)}
				</div>
				<div className="text-center">
					{this.props.onComplete &&
					<button className="btn btn-info" onClick={this.props.onComplete}>Close Email Templates</button>
					}
				</div>
			</div>
		)
	}
}

EmailTemplates.defaultProps = {
	templates: []
}

EmailTemplates.contextTypes = {
	history: PropTypes.object
}
