/**
 *
 * TextEditor
 *
 */

import React from 'react'

// NPM Libraries
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Editor } from 'react-draft-wysiwyg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EditorState, ContentState, convertToRaw, convertFromRaw } from 'draft-js'
import { withState } from 'recompose'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const defaultWrapperStyle = {
	position: 'relative',
	// backgroundColor: '#FFF',
	border: '1px solid #AAA',
}

const defaultEditorStyle = {
	padding: '0 2em',
	height: 'calc(100% - 44px)',
	// backgroundColor: '#FFF',
}

const defaultToolbarStyle = {
	width: '100%',
	backgroundColor: '#FFF',
	zIndex: 2,
}

const SaveButton = ({ save, has_changes }) => (
	<button
		type="button"
		className={classNames('btn', 'btn-sm', {
			'btn-outline-success': !has_changes,
			'btn-success': has_changes,
		})}
		onClick={save}
	>
		<FontAwesomeIcon icon="save" />
	</button>
)

function TextEditor({
	wrapperStyle,
	editorStyle,
	toolbarStyle,
	toolbarCustomButtons,
	editorState,
	setEditorState,
	defaultContentState,
	has_changes,
	onSave,
	editorDidChange,
	...props
}) {
	const save = () => {
		onSave && onSave(convertToRaw(editorState.getCurrentContent()))
	}

	const defaultToolbarCustomButtons = [<SaveButton save={save} has_changes={has_changes} />]

	const onEditorStateChange = (newEditorState) => {
		if (editorState.getCurrentContent() !== newEditorState.getCurrentContent()) {
			editorDidChange && editorDidChange()
		}

		setEditorState(newEditorState)
	}

	return (
		<Editor
			defaultEditorState={editorState}
			onEditorStateChange={onEditorStateChange}
			wrapperStyle={{ ...defaultWrapperStyle, ...wrapperStyle }}
			editorStyle={{ ...defaultEditorStyle, ...editorStyle }}
			toolbarStyle={{ ...defaultToolbarStyle, ...toolbarStyle }}
			toolbarCustomButtons={[...toolbarCustomButtons, ...defaultToolbarCustomButtons]}
			{...props}
		/>
	)
}

TextEditor.propTypes = {
	wrapperStyle: PropTypes.object,
	editorStyle: PropTypes.object,
	toolbarStyle: PropTypes.object,
	toolbarCustomButtons: PropTypes.arrayOf(PropTypes.any),
	onSave: PropTypes.func,
	editorDidChange: PropTypes.func,
}

TextEditor.defaultProps = {
	wrapperStyle: {},
	editorStyle: {},
	toolbarStyle: {},
	toolbarCustomButtons: [],
}

export default withState('editorState', 'setEditorState', ({ default_content }) => {
	if (default_content === null || !Object.keys(default_content).length) default_content = ''

	let content_state
	switch (typeof default_content) {
		case 'undefined':
			content_state = ContentState.createFromText('')
			break
		case 'string':
			content_state = ContentState.createFromText(default_content)
			break
		default:
			content_state = convertFromRaw(default_content)
			break
	}

	return EditorState.createWithContent(content_state)
})(TextEditor)
