/**
 *
 * Notifications/List/Item
 *
 */

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// NPM Libraries
import TimeAgo from 'react-timeago'
import VisibilitySensor from 'react-visibility-sensor'
import axios from 'axios'
import classNames from 'classnames'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Utils
import Spinner from 'components/Spinner'
import safelySetInnerHTML from 'utils/safelySetInnerHTML'
import { Notification } from 'globalVars'
import { COLORS } from 'global-styles'

const Item = styled.a`
	& .time {
		line-height: 18px;
	}

	&.viewed {
		opacity: 0.5;
	}

	&.unread {
		background-color: ${COLORS.brand_highlight};
	}

	&:hover {
		background-color: ${COLORS.backgroundGrey};
	}
`
const Circle = styled.div`
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin-right: 10px;
	background-color: ${COLORS.brand_logo};
`

const NotificationItem = ({ notification, disable_visibility_sensor, hide_mark_as_read }) => {
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		setLoading(false)
	}, [notification])

	const updateReadStatus = (read_status) => {
		if (notification.read_status_index < read_status) {
			setLoading(true)

			axios({
				method: 'PATCH',
				url: `${process.env.API_URL}/api/v1/notifications/${notification.id}`,
				data: { notification: { read_status } },
			})
		}
	}

	const markAsRead = (e) => {
		e.preventDefault()

		updateReadStatus(Notification.READ_VIEWED)

		return false
	}

	return (
		<VisibilitySensor
			partialVisibility
			onChange={(visibility) =>
				visibility && !disable_visibility_sensor ? updateReadStatus(Notification.READ_SEEN) : null
			}
		>
			<Item
				href={notification.link}
				className={`d-block py-2 px-3  notification-list-item ${notification.actionable ? 'actionable' : ''} ${
					notification.read_status
				}`}
			>
				<div className="row">
					<div className="text-center" style={{ width: 50 }}>
						<span className="fa-layers fa-fw fa-2x mt-2">
							<FontAwesomeIcon
								icon="circle"
								className={classNames({
									'text-warning': notification.actionable,
									'text-primary': !notification.actionable,
								})}
							/>
							<FontAwesomeIcon
								icon={notification.output.icon}
								className={classNames('text-white')}
								transform="shrink-8"
							/>
						</span>
					</div>
					<div className="col d-flex justify-content-between align-items-center">
						<div className="col px-0 d-flex flex-column justify-content-center">
							<p className="mb-0 text-default title">{safelySetInnerHTML(notification.output.text)}</p>
							<small className="text-muted time">
								<TimeAgo date={notification.created_at} />
								{hide_mark_as_read && notification.owner && ` - ${notification.owner.full_name}`}
							</small>
						</div>
						{loading ? (
							<div className="col-1 mt-1">
								<Spinner name="wave" scale={0.7} />
							</div>
						) : (
							<React.Fragment>
								{!hide_mark_as_read ? (
									<div className="col-1 px-0 d-flex align-items-center justify-content-end">
										{notification.read_status_index !== Notification.READ_VIEWED && <Circle />}
										<div className="d-flex flex-column align-items-end">
											{/* eslint-disable indent */}
											{!hide_mark_as_read &&
												Notification.READ_VIEWED !== notification.read_status_index && (
													<button
														type="button"
														className="btn-unstyled float-right"
														onClick={markAsRead}
													>
														<small>Mark as Read</small>
													</button>
												)}
											{/* eslint-enable indent */}
											{notification.owner && (
												<small className="float-right text-muted">
													<em>{notification.owner.full_name}</em>
												</small>
											)}
										</div>
									</div>
								) : (
									<div className="col-1">
										{notification.read_status_index !== Notification.READ_VIEWED && <Circle />}
									</div>
								)}
							</React.Fragment>
						)}
					</div>
				</div>
			</Item>
		</VisibilitySensor>
	)
}

NotificationItem.propTypes = {
	notification: PropTypes.objectOf(PropTypes.any),
	disable_visibility_sensor: PropTypes.bool,
	hide_mark_as_read: PropTypes.bool,
}

NotificationItem.defaultProps = {
	notification: {},
	disable_visibility_sensor: false,
	hide_mark_as_read: false,
}

export default React.memo(NotificationItem)
