/**
 *
 * Campaigns/Index
 *
 */

import React, { useState } from 'react'

// NPM Libraries
import FloatingLabel from '@joeyparis/react-floating-label-input'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { useTopicText } from 'hooks'
import { useStateAndSessionStorage } from 'persistence-hooks'
import { Helmet } from 'react-helmet-async'

// GraphQL
import gql from 'graphql-tag'

// Components
import CampaignsCreate from 'components/Campaigns/Create'
import FullModal from 'components/FullModal'
import QueryTable from 'components/Table/Query'
import { withCurrentUser } from 'currentUserContext'

// Utlis
import { toLocalDate } from 'components/utils'

export const SEARCH_CAMPAIGNS = gql`
	query searchCampaigns(
		$page: Int
		$page_size: Int
		$search: String
		$sort: String
		$sort_direction: String
		$filters: JSON
	) {
		search: search_campaigns(
			page: $page
			page_size: $page_size
			search: $search
			sort: $sort
			sort_direction: $sort_direction
			filters: $filters
		) {
			_total
			results: campaigns {
				id
				name
				headline
				job_codes
				objective
				reach
				next_date
				events_count
				appointments_count
				total_registrations_count
				updated_at
				deleted_at
				topic
				user {
					id
					full_name
					company_address {
						components {
							state
						}
					}
				}
			}
		}
	}
`

function Index({ current_user }) {
	const [show_modal, setShowModal] = useState(false)
	const topicText = useTopicText()

	const [search, setSearch] = useStateAndSessionStorage('', 'campaigns-index')
	const [search_input, setSearchInput] = useState(search)
	const [search_delay, setSearchDelay] = useState()

	return (
		<div>
			<Helmet>
				<title>Campaigns</title>
				<meta name="description" content="LeadJig Campaigns" />
			</Helmet>
			<div className="mt-3 mb-4 clearfix">
				<h1 className="float-left">Campaigns</h1>
				<div style={{ float: 'left', position: 'relative', top: '-1em', marginLeft: '2em', width: 280 }}>
					<FloatingLabel
						type="search"
						label="Search"
						autoFocus
						value={search_input}
						onChange={(e) => {
							const search_value = e.target.value
							setSearchInput(search_value)

							if (search_delay) clearTimeout(search_delay)
							setSearchDelay(
								setTimeout(() => {
									setSearch(search_value)
								}, 250),
							)
						}}
					/>
				</div>
				{current_user.is_consultant && (
					<button className="btn btn-success float-right" onClick={() => setShowModal(true)}>
						Create Campaign
					</button>
				)}
			</div>
			<QueryTable
				query={SEARCH_CAMPAIGNS}
				channels={['campaign_deleted']}
				search={search}
				cache_key="campaigns-index"
				columns={[
					{
						header: 'Name / Headline',
						id: 'name-headline',
						sort_key: 'name',
						filter_key: 'name-headline',
						accessor: (r) => (
							<Link to={`/campaigns/${r.id}`}>
								{r.name}
								<br />
								{r.headline}
							</Link>
						),
						minWidth: 280,
					},
					{
						header: 'Advisor',
						accessor: 'user.full_name',
						sort_key: 'users.full_name',
						filter_key: 'advisor_name',
						width: 0.12 * 1850,
					},
					{
						header: 'Job Codes',
						id: 'job_codes',
						accessor: (r) => (r.job_codes ? r.job_codes.join(' ') : '--'),
						className: 'text-center',
						width: 0.06 * 1850,
					},
					{
						header: 'Topic',
						id: 'topic',
						accessor: (r) => topicText(r.topic),
						className: 'text-center',
						width: 0.075 * 1850,
						sortable: true,
						filter_key: 'topic',
					},

					{
						header: 'Objective',
						accessor: 'objective',
						className: 'text-center',
						width: 0.07 * 1850,
						sortable: false,
						filter_key: 'objective',
					},
					{
						header: 'Next Date',
						id: 'next_date',
						accessor: (r) => (r.next_date ? format(toLocalDate(r.next_date), 'MMM do, yyyy hh:mm') : '--'),
						className: 'text-center',
						width: 0.08 * 1850,
					},
					{
						header: 'Events / Appts',
						id: 'events-appointments',
						accessor: (r) =>
							r.events_count && r.appointments_count
								? `${r.events_count} / ${r.appointments_count}`
								: r.events_count || r.appointments_count,
						className: 'text-center',
						width: 0.07 * 1850,
						sortable: false,
					},
					{
						header: 'Contacts',
						accessor: 'total_registrations_count',
						sort_key: 'registrations_estimate',
						className: 'text-center',
						width: 0.06 * 1850,
					},
					{
						header: 'Reach',
						accessor: 'reach',
						sort_key: 'reach_override',
						className: 'text-center',
						width: 0.06 * 1850,
					},
					{
						header: 'Last Update',
						id: 'updated_at',
						accessor: (r) => (
							<React.Fragment>
								{format(new Date(r.updated_at), 'MMM do, yyyy')}
								<br />
								{format(new Date(r.updated_at), 'hh:mma')}
							</React.Fragment>
						),
						className: 'text-center',
						width: 0.08 * 1850,
					},
					{
						header: 'State',
						id: 'state',
						accessor: (r) => r.user.company_address?.components?.state,
						className: 'text-center',
						width: 0.05 * 1850,
						filter_key: 'advisor_state',
					},
				]}
			/>
			<FullModal show={show_modal} modalDidClose={() => setShowModal(null)}>
				<CampaignsCreate current_user={current_user} onSave={() => setShowModal(null)} />
			</FullModal>
		</div>
	)
}

Index.propTypes = {}

export default withCurrentUser(Index)
