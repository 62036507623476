/**
 *
 * CampaignTemplates/Show/Advisor
 *
 */

import React, { useState, useEffect, memo } from 'react'

// NPM Libraries
import PropTypes from 'prop-types'
import { convertFromRaw } from 'draft-js'
import { sanitizeDraftJsMap, formatPhoneNumber } from 'components/utils'
import { stateToHTML } from 'draft-js-export-html'
import Parser from 'html-react-parser'

const AdvisorDescription = ({ description_json }) =>
	description_json && Object.keys(description_json).length
		? Parser(stateToHTML(convertFromRaw(sanitizeDraftJsMap(description_json))))
		: null

const Advisor = ({ profile, company_name }) => {
	const [hide_profile_image, setHideProfileImage] = useState(false)

	useEffect(() => {
		setHideProfileImage(false)
	}, [profile.profile_image])

	return (
		<div className="row">
			<div className="text-center my-4 col" style={{ minWidth: 300 }}>
				{!hide_profile_image && profile.profile_image && (
					<React.Fragment>
						{profile.display_image_as_circle && (
							<div
								style={{
									width: 170,
									height: 170,
									background: `center / cover no-repeat url(${
										profile.profile_image
									}?t=${new Date().getTime()})`,
									borderRadius: '100%',
									margin: '0 auto 20px',
								}}
								alt={profile.display_name}
							></div>
						)}

						{!profile.display_image_as_circle && (
							<img
								src={`${profile.profile_image}?t=${new Date().getTime()}`}
								style={{
									maxWidth: 300,
									marginBottom: '20px',
								}}
								alt={profile.display_name}
								onError={() => setHideProfileImage(true)}
							/>
						)}
					</React.Fragment>
				)}
				<h4>{profile.display_name}</h4>
				<div>{company_name}</div>
				<div>{formatPhoneNumber(profile.phone)}</div>
				<div>{profile.phone_extension}</div>
			</div>
			<div className="col" style={{ minWidth: '300px' }}>
				<AdvisorDescription {...profile} />
			</div>
		</div>
	)
}

Advisor.propTypes = {
	profile: PropTypes.objectOf(PropTypes.any),
	company_name: PropTypes.string,
}

Advisor.defaultProps = {
	profile: {},
}

export default memo(Advisor)
