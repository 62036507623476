import { ApolloClient, HttpLink } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'

import { buildAxiosFetch } from '@lifeomic/axios-fetch'
import axios from 'axios'

export default class ApolloClientWrapper {
	constructor() {
		if (!this.client) {
			const httpLink = new HttpLink({
				uri: `${process.env.API_URL}/graphql`,
				fetch: buildAxiosFetch(axios),
			})

			this.client = new ApolloClient({
				// By default, this client will send queries to the
				//  `/graphql` endpoint on the same host
				link: httpLink,
				cache: new InMemoryCache(),
				defaultOptions: {
					query: {
						fetchPolicy: 'network-only',
					},
				},
			})
		}

		return this.client
	}
}
