/**
 *
 * Table/Row
 *
 */

import React, { useContext, useEffect, useState } from 'react'

// NPM Libraries
import styled from '@emotion/styled'

// Components
import TableCell from 'components/Table/Cell'
import { TableContext } from 'components/Table'

const StyledRow = styled.div`
	display: inline-flex;
	flex: 1 0 auto;

	&:hover:not(:first-of-type) {
		background-color: #e7e7e7;
	}
`

const RowWithDropdown = styled.div`
	display: flex;
	flex-direction: column;
`

const RowDropdown = ({ row, dropdown, dropdown_data, toggleDropdown, rowHeightRecompute }) => {
	const CellComponent =
		typeof dropdown === 'function' ? (
			<React.Fragment>{dropdown(row, dropdown_data, toggleDropdown, rowHeightRecompute)}</React.Fragment>
		) : (
			dropdown
		)

	return (
		<div className="w-100 pb-4 px-4" style={{ height: 'auto' }}>
			{CellComponent}
		</div>
	)
}

function Row({ row, decorated_columns, buildTooltip, row_dropdown, children, virtualized, rowHeightRecompute }) {
	const { columns, resized } = useContext(TableContext)
	const [show_dropdown, setShowDropdown] = useState(false)

	const flex = columns.reduce((s, col) => s + (col.width || resized.get(col.id || col.accessor) ? 0 : col.minWidth))
	const width = columns.reduce((s, col) => s + resized.get(col.id) || col.width || col.minWidth)
	const maxWidth = columns.reduce((s, col) => s + resized.get(col.id) || col.width || col.maxWidth)

	const flexStyles = {
		flex: `${flex} 0 auto`,
		width,
		maxWidth,
	}

	if (!row_dropdown)
		return (
			<StyledRow
				style={{
					...flexStyles,
				}}
			>
				{children || (
					<React.Fragment>
						{decorated_columns.map((column, idx) => (
							<TableCell
								key={idx}
								row={row}
								column={column}
								buildTooltip={buildTooltip}
								virtualized={virtualized}
							/>
						))}
					</React.Fragment>
				)}
			</StyledRow>
		)

	return (
		<RowWithDropdown>
			<StyledRow
				style={{
					...flexStyles,
				}}
			>
				{decorated_columns.map((column, idx) => (
					<TableCell
						key={idx}
						row={row}
						column={column}
						buildTooltip={buildTooltip}
						toggleRowDropdown={setShowDropdown}
						virtualized={virtualized}
					/>
				))}
			</StyledRow>
			{row_dropdown && show_dropdown && (
				<RowDropdown
					row={row}
					dropdown={row_dropdown}
					dropdown_data={show_dropdown}
					toggleDropdown={setShowDropdown}
					rowHeightRecompute={rowHeightRecompute}
				/>
			)}
		</RowWithDropdown>
	)
}

Row.propTypes = {}

export default Row
