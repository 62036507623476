/* eslint-disable */
// TODO: Fix Eslint

import React, { useRef, useEffect, useCallback, useState, useMemo } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
// import Diff from 'diff'
import { formatPhoneNumber, optionsFromStrings, queryVariables, dig } from 'components/utils'

import IO from 'components/Socket/IO'

import { Client as PasswordlessClient, isBrowserSupported } from '@passwordlessdev/passwordless-client'

import gql from 'graphql-tag'
import useQuery from 'components/UseQuery'
import { useCurrentUser } from 'currentUserContext'
import usePermissionCheck from './usePermissionCheck' // estlint-disable-line no-unresolved
import useScreenshot from './useScreenshot'
import useCampaignTopicOptions from './useCampaignTopicOptions'
import useTooltip from './useTooltip'

export { usePermissionCheck, useCurrentUser, useScreenshot, useCampaignTopicOptions, useTooltip }

export const useTopicText = (topic_value) => {
	const topics = useCampaignTopicOptions()

	const topicText = useCallback(
		(topic_value) => {
			return topics?.find((topic) => topic.value == topic_value)?.text || topic_value
		},
		[JSON.stringify(topics)],
	)

	return topicText
}

export const useQueryWithResultChannels = (query, options = {}) => {
	const [ids, setIds] = useState(options.channels || [])
	const query_result = useQuery(query, { ...options, channels: ids })

	useEffect(() => {
		const objects_with_ids = Object.values(query_result.data)
			.filter((value) => Array.isArray(value) && value[0]?.id)
			.flat()

		setIds(objects_with_ids.map((ob) => ob.id))
	}, [JSON.stringify(query_result.data)])

	return query_result
}

// result_identifier can be nested such as "user.calendly_account" depending on the query
export const useStateFromQuery = (query, options = {}, result_identifier) => {
	const query_result = useQuery(query, options)
	const [state, handleInputChange, mergeState, setState] = useHandleInputChange(
		query_result.data ? dig(query_result.data, result_identifier) : {},
	)

	useEffect(() => {
		const result = dig(query_result.data, result_identifier)
		result && setState(result)
	}, [JSON.stringify(query_result.data)])

	return { state, setState, handleInputChange, mergeState, ...query_result }
}

// adds id or id's to channels
export const useStateFromQueryWithResultChannels = (query, options = {}, result_identifier) => {
	const [ids, setIds] = useState(Array.isArray(options.channels) ? options.channels : [])
	const [state, handleInputChange, mergeState, setState] = useHandleInputChange({})
	const query_result = useQuery(query, { ...options, channels: ids })

	useEffect(() => {
		const result = dig(query_result.data, result_identifier)
		result && setState(result)

		const ids = Array.isArray(options.channels) ? options.channels : []
		result?.id && ids.push(result.id)

		if (Array.isArray(result)) {
			result.forEach((item) => item.id && ids.push(item.id))
		}
		setIds(ids)
	}, [JSON.stringify(query_result.data)])

	return { state, setState, handleInputChange, mergeState, ...query_result }
}

export const usePrevious = (value) => {
	const ref = useRef()
	useEffect(() => {
		ref.current = value
	})
	return ref.current
}

export const useForceUpdate = () => {
	const [value, setValue] = useState(true)
	return () => setValue(!value)
}

export const useUpdateOnChange = () => {
	const [value, setValue] = useState(0)
	return [value, () => setValue(value + 1)]
}

export const useOnce = (func) => {
	const [called, setCalled] = useState(false)
	const [results, setResults] = useState(undefined)

	const call = (...args) => {
		if (!called) {
			setCalled(true)
			const r = func(args)
			setResults(r)
			return r
		}
		console.warn('useOnce function called again, returning previous results')
		return results
	}

	return call
}

export const useIdentifier = (default_identifier) => {
	const [identifier] = useState(default_identifier || Math.random().toString(36).substr(2))

	return identifier
}

export const useChannels = (callback, channels) => {
	const identifier = useIdentifier()

	const cb = useCallback((message) => {
		callback(message)
	})

	useEffect(() => {
		const io = new IO()
		io.join(channels, identifier)
		channels.forEach((channel) => {
			io.on(channel, (p) => {
				let message
				try {
					message = JSON.parse(p)
				} catch (e) {}
				cb(message)
			})
		})

		return () => {
			io.leave(channels, identifier)
		}
	}, [JSON.stringify(channels)])
}

export const useCounter = (initial_value = 0) => {
	const [state, setState] = useState(initial_value)

	const increment = () => setState((prev_state) => prev_state + 1)
	const decrement = () => setState((prev_state) => prev_state - 1)

	return [
		state,
		{
			increment,
			decrement,
		},
	]
}

export const useIsMount = () => {
	const isMountRef = useRef(true)
	useEffect(() => {
		isMountRef.current = false
	}, [])
	return isMountRef.current
}

export const useDebounce = (callback, delay, options = {}) => {
	const { maxWait, leading } = options
	const trailing = options.trailing === undefined ? true : options.trailing

	const maxWaitHandler = useRef()
	const maxWaitArgs = useRef([])

	const leadingCall = useRef(false)

	const functionTimeoutHandler = useRef()
	const isComponentUnmounted = useRef(false)

	const debouncedFunction = useRef(callback)
	debouncedFunction.current = callback

	const cancelDebouncedCallback = useCallback(() => {
		clearTimeout(functionTimeoutHandler.current)
		clearTimeout(maxWaitHandler.current)
		maxWaitHandler.current = null
		maxWaitArgs.current = []
		functionTimeoutHandler.current = null
		leadingCall.current = false
	}, [])

	useEffect(
		() => () => {
			isComponentUnmounted.current = true
		},
		[],
	)

	const debouncedCallback = useCallback(
		(args) => {
			maxWaitArgs.current = args
			clearTimeout(functionTimeoutHandler.current)
			if (leadingCall.current) {
				leadingCall.current = false
			}
			if (!functionTimeoutHandler.current && leading && !leadingCall.current) {
				debouncedFunction.current(args)
				leadingCall.current = true
			}

			functionTimeoutHandler.current = setTimeout(() => {
				let shouldCallFunction = true
				if (leading && leadingCall.current) {
					shouldCallFunction = false
				}
				cancelDebouncedCallback()

				if (!isComponentUnmounted.current && trailing && shouldCallFunction) {
					debouncedFunction.current(args)
				}
			}, delay)

			if (maxWait && !maxWaitHandler.current && trailing) {
				maxWaitHandler.current = setTimeout(() => {
					const shadow_args = maxWaitArgs.current
					cancelDebouncedCallback()

					if (!isComponentUnmounted.current) {
						debouncedFunction.current.apply(null, shadow_args)
					}
				}, maxWait)
			}
		},
		[maxWait, delay, cancelDebouncedCallback, leading, trailing],
	)

	const callPending = () => {
		if (!functionTimeoutHandler.current) {
			return
		}

		debouncedFunction.current.apply(null, maxWaitArgs.current)
		cancelDebouncedCallback()
	}

	return [debouncedCallback, cancelDebouncedCallback, callPending]
}

export const useDebounceState = (initial_state, delay, options = {}) => {
	const [immediate_value, setImmediateValue] = useState(initial_state)
	const [debounced_value, setDebouncedValue] = useState(initial_state)

	const [setStateDebounce] = useDebounce(
		(new_value) => {
			setDebouncedValue(new_value)
		},
		delay,
		options,
	)

	const setState = (new_state) => {
		setImmediateValue(new_state)
		setStateDebounce(new_state)
	}

	return [debounced_value, setState, immediate_value]
}

export const useWindowSize = () => {
	const [width, setWidth] = useState(window.innerWidth)
	const [height, setHeight] = useState(window.innerHeight)

	useEffect(() => {
		const handleResize = () => {
			setWidth(window.innerWidth)
			setHeight(window.innerHeight)
		}

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	})

	return { width, height }
}

export const useScroll = () => {
	const [scroll, setScroll] = useState({
		scrollX: document.body.getBoundingClientRect().left,
		scrollY: document.body.getBoundingClientRect().top,
		scrollDirection: '',
	})

	const listener = () => {
		// `prev` provides us the previous state: https://reactjs.org/docs/hooks-reference.html#functional-updates
		setScroll((prev) => ({
			scrollX: document.body.getBoundingClientRect().left,
			scrollY: -document.body.getBoundingClientRect().top,
			// Here we’re comparing the previous state to the current state to get the scroll direction
			scrollDirection: prev.y > -document.body.getBoundingClientRect().top ? 'up' : 'down',
		}))
	}

	useEffect(() => {
		window.addEventListener('scroll', listener)
		return () => window.removeEventListener('scroll', listener)
	}, [])

	return scroll
}

export const useOnClickOutside = (ref, callback) => {
	const handleOutsideClick = (e) => {
		if (ref.current && !ref.current.contains(e.target)) {
			callback()
		}
	}

	useEffect(() => {
		window.addEventListener('mousedown', handleOutsideClick)

		return () => {
			window.removeEventListener('mousedown', handleOutsideClick)
		}
	}, [])
}

export const useToggle = (initial_value, toggles) => {
	const [value, setValue] = useState(initial_value)
	const initial_index = toggles.indexOf(initial_value)
	const [index, setIndex] = useState(initial_index === -1 ? 0 : initial_index)

	return [
		value,
		() => {
			const next_index = (index + 1) % toggles.length
			setValue(toggles[next_index])
			setIndex(next_index)

			return value
		},
	]
}

export const useMergeState = (initial_state) => {
	const [state, setState] = useState(initial_state)

	const mergeState = (new_state) => setState((prev_state) => ({ ...prev_state, ...new_state }))

	return [state, mergeState, setState]
}

export const useSet = (initial_state = []) => {
	const [state, setState] = useState(new Set(initial_state))

	const add = (value) => setState((prev_state) => new Set(prev_state).add(value))
	const remove = (value) =>
		setState((prev_state) => {
			const new_state = new Set(prev_state)
			new_state.delete(value)
			return new_state
		})

	return [state, add, remove, (new_state) => setState(new Set(new_state))]
}

export const useHandleInputChange = (initial_state) => {
	const [state, mergeState, setState] = useMergeState(initial_state)

	const handleInputChange = (e) => {
		const { name } = e.target
		let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
		if (name === 'phone' || name === 'company_phone') {
			value = formatPhoneNumber(value)
		}

		if (e.target.type === 'number') {
			value = Number(value)
		}
		mergeState({ [name]: value })
	}

	return [state, handleInputChange, mergeState, setState]
}

export const useHandleInputChangeWithDelay = (initial_state, miliseconds = 250) => {
	const [state, handleInputChange, mergeState, setState] = useHandleInputChange(initial_state)
	const [input_states, mergeInputStates] = useMergeState(initial_state)
	const [delay, setDelay] = useState()

	const handleInputChangeWithDelay = (e) => {
		const { name, value, type, checked } = e.target
		mergeInputStates({ [name]: value })

		if (delay) clearTimeout(delay)
		setDelay(
			setTimeout(() => {
				handleInputChange({ target: { name, value, type, checked } })
			}, miliseconds),
		)
	}

	// mergeState and setState will change the state immediately
	return [state, input_states, handleInputChangeWithDelay, mergeState, setState]
}

export const useHover = () => {
	const [hovered, setHovered] = useState(false)

	const ref = useRef(null)

	const handleMouseOver = () => setHovered(true)
	const handleMouseLeave = () => setHovered(false)

	useEffect(() => {
		const node = ref.current
		if (node) {
			node.addEventListener('mouseover', handleMouseOver)
			node.addEventListener('mouseleave', handleMouseLeave)

			return () => {
				node.removeEventListener('mouseover', handleMouseOver)
				node.removeEventListener('mouseleave', handleMouseLeave)
			}
		}
		return () => undefined
	}, [ref.current])

	return [ref, hovered]
}

export function useAutocomplete(all_strings) {
	const autocomplete_options = all_strings?.every((s) => {
		return s.hasOwnProperty('value') && s.hasOwnProperty('text')
	})
		? all_strings
		: optionsFromStrings(all_strings)

	function matchAutocomplete(input_val = '') {
		if (input_val.length > 0) {
			const mapped = autocomplete_options.map(({ value, text }) => {
				const whole_match = text.toLowerCase().match(input_val.toLowerCase())
				const letter_match = text.toLowerCase().startsWith(input_val[0].toLowerCase())
				let status = 100
				if (whole_match && letter_match) {
					status = whole_match.index // 0
				} else if (letter_match) {
					status = 1
				} else if (whole_match) {
					status = whole_match.index + 1
				}
				return { text, value, status }
			})
			const sorted = mapped
				.sort((a, b) => a.status - b.status)
				.map((obj) => ({ value: obj.value, text: obj.text }))

			return sorted
		} else if (all_strings) {
			return autocomplete_options
		} else return []
	}

	return [autocomplete_options, matchAutocomplete]
}

export const useKeyboardScroll = ({ initial_options, selected_option, onEnter, required }) => {
	// const selected_option = visible_options && visible_options.find( o => String(o.value) === String(value) )

	const [visible_options, setVisibleOptions] = useState(initial_options || [])
	const [active_index, setActiveIndex] = useState(selected_option ? visible_options.indexOf(selected_option) : 0)

	if (selected_option && visible_options[0] && visible_options[0].text !== '--' && !required) {
		const options_with_empty = [{ text: '--', value: '' }].concat(visible_options)
		setVisibleOptions(options_with_empty)
		setActiveIndex(options_with_empty.indexOf(selected_option))
	}

	function handleKeyboardScroll(e) {
		if (e.key === 'Enter') {
			e.preventDefault()
			onEnter(active_index)
			e.target.blur()
			return false
		}
		if (e.key === 'ArrowUp') {
			if (active_index > 0) {
				setActiveIndex(active_index - 1)
			} else {
				setActiveIndex(visible_options.length - 1)
			}
			return true
		}
		if (e.key === 'ArrowDown') {
			if (active_index < visible_options.length - 1) {
				setActiveIndex(active_index + 1)
			} else {
				setActiveIndex(0)
			}
			return true
		}
		if (e.key === 'Tab') {
			e.target.blur()
			return true
		}
		return true // todo: This might need to be false instead
	}

	function updateOptions(options) {
		setVisibleOptions(options)
		setActiveIndex(selected_option ? visible_options.indexOf(selected_option) : 0)
	}

	return { handleKeyboardScroll, active_index, visible_options, updateOptions, selected_option }
}

export const usePosition = () => {
	const ref = useRef()
	const [position, setPosition] = useState({})

	useEffect(() => {
		if (ref.current) {
			setPosition(ref.current.getBoundingClientRect())
		}
	}, [ref.current, ref.current && JSON.stringify(ref.current.getBoundingClientRect())])

	return [ref, position]
}

export const useQueryParams = () => {
	const location = useLocation()
	const params = queryVariables(location)
	const decoded = {}
	Object.keys(params).forEach((name) => {
		let value = decodeURIComponent(params[name])
		try {
			value = JSON.parse(value)
		} catch (err) {
			if (!err.message.includes('JSON')) {
				Sentry.captureException(err)
			} // Normal to have an error if value isn't json, and most aren't.
		}
		decoded[decodeURIComponent(name)] = value
	})
	return decoded
}

export const useArcTan = ({ x = 0, p = 100, n = 50000, q = 5 }) => {
	const value = useMemo(() => Math.atan((x - p / 2) / (p / q)) * (n / (Math.atan(q / 2) * 2)) + n / 2, [x, p, n])
	return value
}

export const useDifferences = (a, b) => {
	const getDifferences = useCallback(() => {
		const Diff = require('diff')

		const diff = Diff.diffChars(a, b)

		const differences = diff.map((part) => {
			// green for additions, red for deletions
			// grey for common parts
			const color = part.added ? 'green' : part.removed ? 'red' : 'grey'
			// const display = 'initial' || part.added || part.removed ? 'initial' : 'none'

			return <span style={{ color }}>{part.value}</span>
		})

		return differences
	}, [a, b])

	return getDifferences()
}

export const useWhyDidYouUpdate = (name, props) => {
	// Get a mutable ref object where we can store props ...
	// ... for comparison next time this hook runs.
	const previousProps = useRef()

	useEffect(() => {
		if (previousProps.current) {
			// Get all keys from previous and current props
			const allKeys = Object.keys({ ...previousProps.current, ...props })
			// Use this object to keep track of changed props
			const changesObj = {}
			// Iterate through keys
			allKeys.forEach((key) => {
				// If previous is different from current
				if (previousProps.current[key] !== props[key]) {
					// Add to changesObj
					changesObj[key] = {
						from: previousProps.current[key],
						to: props[key],
					}
				}
			})

			// If changesObj not empty then output to console
			if (Object.keys(changesObj).length) {
				console.log('[why-did-you-update]', new Date().getTime(), name, JSON.stringify(changesObj))
			}
		}

		// Finally update previousProps with current props for next hook call
		previousProps.current = props
	})
}

export const useConfirmSaveItemsAndRedirect = ({ confirmation_title, confirmation_text, item_ids }) => {
	const history = useHistory()
	const [should_save_all, setShouldSaveAll] = useState(false)
	const saved_item_ids = useRef({})
	const [path, setPath] = useState()

	const confirmRedirectTo = (path) => {
		swal({
			title: confirmation_title || 'Save changes and leave page?',
			text: confirmation_text || '',
			showCancelButton: true,
			confirmButtonText: 'Yes, save and continue.',
			cancelButtonText: 'Cancel',
			confirmButtonClass: 'btn btn-success d-inline',
			cancelButtonClass: 'btn btn-default d-inline',
			buttonsStyling: false,
		}).then(() => {
			setShouldSaveAll(true)
			setPath(path)
		}, reset)
	}

	const reset = () => {
		setShouldSaveAll(false)
		saved_item_ids.current = {}
		setPath()
	}

	const useSetItemSaved = (id) => (boolean_value) => (saved_item_ids.current[id] = boolean_value)
	// returns a function for indicating when each item is saved.

	useEffect(() => {
		if (item_ids.length) {
			const saved = item_ids.every((id) => saved_item_ids.current[id] === true)
			if (saved) history.push(path)
		}
	}, [JSON.stringify(saved_item_ids.current)])

	return { confirmRedirectTo, should_save_all, useSetItemSaved }
}

// export const useTimeStates = ({ initial_start, initial_end }) => {
// 	const [start_time, setStartTime] = useState(initial_start || setHours(new Date(), ))

// 	const setStartHours = (hour24) => {}

// 	return ({ start_time, end_time, setStartDate, setStartHours, setStartMinutes, setEndDate, setEndHours, setEndMinutes })
// }

export const useShiftSelect = (onChange, options=[]) => {

    const [previous_selected, setPreviousSelected] = useState(null)
    const [previous_checked, setPreviousChecked] = useState(false)
    const [current_selected, setCurrentSelected] = useState(null)

    const handleSelect = useCallback((event, item) => {
        if (event.nativeEvent.shiftKey) {
            const current_index = options.findIndex((o) => o.key === item.key)
            const previous_index = options.findIndex((o) => o.key === previous_selected?.key)
            const previous_current = options.findIndex((o) => o.key === current_selected?.key)

            const start = Math.min(previous_index, current_index)
            const end = Math.max(previous_index, current_index)
            if (start > -1 && end > -1) {
                onChange(previous_checked, options.slice(start, end + 1))
                if (previous_current > end) {
                    onChange(!previous_checked, options.slice(end + 1, previous_current + 1))
                }
                if (previous_current < start) {
                    onChange(!previous_checked, options.slice(previous_current, start))
                }
                setCurrentSelected(item)
                return
            }
        } else {
            setPreviousSelected(item)
            setCurrentSelected(null)
            setPreviousChecked(event.target.checked)
        }
        onChange(event.target.checked, [item])
    }, [
        onChange,
        options,
        previous_selected,
        setPreviousSelected,
        previous_checked,
        setPreviousChecked,
        current_selected,
        setCurrentSelected,
    ])

    return handleSelect
}

export const usePasswordless = () => {
	const passwordless_client = new PasswordlessClient({
		apiKey: 'leadjig:public:2de17dd32ddf4c6cb4c58ae30591c5cc'
	})

	const [registration_token, setRegistrationToken] = useState()
	const [registration_error, setRegistrationError] = useState()
	const [login_token, setLoginToken] = useState()
	const [login_error, setLoginError] = useState()

	const register = useCallback(async (t) => {
		const { token, error } = await passwordless_client.register(t)
		if (token) {
			setRegistrationToken(token)
		}

		if (error) {
			setRegistrationError(error)
		}

		return { token, error }
	})

	const login = useCallback(async () => {
		const { token, error, ...rest } = await passwordless_client.signinWithDiscoverable()

		console.info("login", {token, error, ...rest})

		if (token) {
			setLoginToken(token)
		}

		if (error) {
			setLoginError(error)
		}

		return { token, error }
	})

	return { register, login, registration_token, registration_error, login_token, login_error, browser_supported: isBrowserSupported() }
}
