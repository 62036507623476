/**
 *
 * FullModal
 *
 */

import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { Global, css } from '@emotion/react'

import ErrorBoundary from 'components/ErrorBoundary'

import Animate from 'react-move/Animate'
import { easeQuadOut, easeQuadIn } from 'd3-ease'

import { compose, defaultProps, withState } from 'recompose'

const styles = css`
	.full_modal {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		overflow: scroll;

		padding-left: 50px;
		padding-right: 50px;

		background-color: #fff;
		z-index: 1000;
		cursor: default;
	}

	.full_modal_content {
		min-height: 100%;
		border-left: 1px solid;
	}

	.close_button {
		position: absolute;
		top: 0;
		left: 0;
		border: none;
		outline: none;

		width: 40px;
		margin: 5px;

		color: #a2a2a2;
	}

	.close_button:active,
	.close_button:focus {
		outline: none;
	}

	.close_button:hover {
		color: #eb6b56;
	}

	.tooltip {
		position: absolute;
		left: 110%;
		width: max-content;
		top: 0;
		background-color: #eb6b56;
		border-radius: 5px;
		padding: 0.5em 1em;
		color: white;
		z-index: 1001;

		animation: fadeIn 1000ms forwards;
	}

	.close_button:hover .tooltip {
		opacity: 0.9;
	}

	.close_button:not(:hover) .tooltip {
		animation: fadeOut 1000ms 1000ms forwards;
	}

	.tooltip:before {
		content: '';
		height: 8px;
		width: 15px;
		position: absolute;
		left: -13px;
		z-index: 1001;
		border-top: 10px solid transparent;
		border-bottom: 10px solid transparent;
		border-right: 10px solid #eb6b56;
		top: 25%;
	}

	@keyframes fadeIn {
		to {
			opacity: 0.9;
		}
	}

	@keyframes fadeOut {
		to {
			opacity: 0;
		}
	}
`

function FullModal({
	show,
	modalWillClose,
	modalDidClose,
	tooltip_text,
	setTooltipText,
	zIndex,
	children,
	style = {},
}) {
	const testClose = () => {
		const modal_can_close = modalWillClose()
		setTooltipText(typeof '' === typeof modal_can_close ? modal_can_close : '')

		return modal_can_close
	}

	const close = () => {
		if (testClose() === true) {
			modalDidClose()
		}
	}

	const forceClose = () => {
		modalDidClose()
	}

	return ReactDOM.createPortal(
		<Animate
			show={Boolean(show)}
			start={() => ({
				opacity: 1e-6,
				x_offset: 100,
			})}
			enter={() => ({
				opacity: [1],
				x_offset: [1e-6],
				timing: { duration: 500, ease: easeQuadOut },
			})}
			leave={() => ({
				opacity: [1e-6],
				x_offset: [100],
				timing: { duration: 750, ease: easeQuadIn },
			})}
		>
			{({ opacity, x_offset }) => (
				<React.Fragment>
					<Global styles={styles} />
					<div
						className="full_modal"
						role="button"
						style={{
							zIndex,
							opacity,
							marginLeft: `${String(x_offset)}px`,
							...style,
						}}
						tabIndex={-1}
						onKeyDown={(e) => e.key === 'Escape' && close()}
					>
						<button
							type="button"
							className="close_button"
							onMouseEnter={testClose}
							onClick={close}
							onDoubleClick={forceClose}
						>
							<svg
								style={{ display: 'block' }}
								role="img"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 352 512"
							>
								<path
									fill="currentColor"
									d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
								/>
							</svg>
							{tooltip_text && <div className="tooltip">{tooltip_text}</div>}
						</button>
						<ErrorBoundary message="Oops! Something went wrong.">{children}</ErrorBoundary>
					</div>
				</React.Fragment>
			)}
		</Animate>,
		document.body,
	)
}

FullModal.propTypes = {
	show: PropTypes.bool,
	modalCanClose: PropTypes.func,
	modalWillClose: PropTypes.func,
	modalDidClose: PropTypes.func.isRequired,
	zIndex: PropTypes.number,
}

FullModal.defaultProps = {
	modalCanClose: () => true,
	modalWillClose: () => true,
	zIndex: 1000,
}

export default compose(defaultProps(FullModal.defaultProps), withState('tooltip_text', 'setTooltipText', ''))(FullModal)
