/**
 *
 * CampaignTemplates/Show/Disclaimer
 *
 */

import React from 'react'

// NPM Libraries
import Parser from 'html-react-parser'
import { convertFromRaw } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'

// Utils
import { sanitizeDraftJsMap } from 'components/utils'

function Disclaimer({ advisor: { disclaimer_json } }) {
	return (
		<small>
			{disclaimer_json ? Parser(stateToHTML(convertFromRaw(sanitizeDraftJsMap(disclaimer_json)))) : null}
		</small>
	)
}

Disclaimer.propTypes = {}

export default Disclaimer
