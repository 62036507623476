/*
 * CmdK/NavigationMenuItems Messages
 *
 * This contains all the text for the NavigationMenuItems component.
 */

import { defineMessages } from 'react-intl'

export const scope = 'app.components.CmdKNavigationMenuItems'

export default defineMessages({
	header: {
		id: `${scope}.header`,
		defaultMessage: 'This is the CmdKNavigationMenuItems component!',
	},
})
