/**
 *
 * Banners/NewVersion
 *
 */

import React, { memo, useState, useEffect } from 'react'

// NPM Libraries
import styled from '@emotion/styled'
import { useLocation } from 'react-router-dom'
import { COLORS } from 'global-styles'

const Banner = styled.div`
	z-index: 100000; /* 1 below BecomeOriginalUserBanner */
	top: 0;
	left: 0;
	right: 0;
	padding: 0.5em;
	background: ${COLORS.info};
	text-align: center;
`

function NewVersion() {
	const location = useLocation()
	const [show_new_version_available, setShowNewVersionAvailable] = useState(false)

	useEffect(() => {
		if (sessionStorage.getItem('highest_front_end_build_number') > process.env.BUILD_NUMBER) {
			setShowNewVersionAvailable(true)
		}
	}, [location.pathname])

	if (!show_new_version_available) {
		return null
	}

	return (
		<Banner>
			A newer version of LeadJig is available.{' '}
			<button type="button" className="btn-unstyled text-white" onClick={() => window.location.reload()}>
				Refresh to update
			</button>
		</Banner>
	)
}

NewVersion.propTypes = {}

export default memo(NewVersion)
