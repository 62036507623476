/**
 *
 * Integrations/Webinarjam/AvailableWebinars
 *
 */

import React, { memo, useState } from 'react'

// NPM Libraries
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Graphql
import useQuery from 'components/UseQuery'
import { gql } from '@apollo/client'

// Components
import Modal from 'components/Modal'

const LiButton = styled.button`
	border: none;
	background: transparent;
	text-align: left;
	display: block;
	width: 100%;
`

const GET_AVAILABLE_WEBINARS = gql`
	query getAvailableWebinars($user_id: ID!) {
		user(id: $user_id) {
			id
			webinarjam_webinars
		}
	}
`

function AvailableWebinars({ webinarjam_webinar_id, user, onChange }) {
	const [show, setShow] = useState(false)

	const {
		data: {
			user: { webinarjam_webinars },
		},
	} = useQuery(GET_AVAILABLE_WEBINARS, {
		variables: { user_id: user.id },
		default_data: { user: { webinarjam_webinars: [] } },
	})

	const selected_webinar = webinarjam_webinars.find((w) => String(w.webinar_id) === webinarjam_webinar_id)

	return (
		<div>
			<div className="form-text text-muted">Webinar</div>
			{selected_webinar && (
				<li className="list-group-item">
					<FontAwesomeIcon
						icon="times"
						className="text-muted float-right clickable"
						onClick={() => onChange('')}
					/>
					<LiButton type="button" onClick={() => setShow(true)}>
						<strong>{selected_webinar.name}</strong>
						<p>{selected_webinar.description}</p>
						{selected_webinar.schedules.join(', ')}
					</LiButton>
				</li>
			)}
			{!selected_webinar && (
				<button className="btn-unstyled" type="button" onClick={() => setShow(true)}>
					Select a Webinar
				</button>
			)}
			<Modal show={show} modalDidClose={() => setShow(false)}>
				<ul className="list-group">
					{webinarjam_webinars.map((webinar) => (
						<li className="list-group-item">
							<LiButton
								type="button"
								onClick={() => {
									onChange({
										webinarjam_webinar_id: String(webinar.webinar_id),
										webinarjam_type: webinar.type,
									})
									setShow(false)
								}}
							>
								<strong>{webinar.name}</strong>
								<p>{webinar.description}</p>
								{webinar.schedules.join(', ')}
							</LiButton>
						</li>
					))}
				</ul>
			</Modal>
		</div>
	)
}

AvailableWebinars.propTypes = {}

export default memo(AvailableWebinars)
