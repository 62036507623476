/**
 *
 * CampaignTemplates/Show/Profiles
 *
 */

import React, { memo } from 'react'

// Components
import Advisor from 'components/CampaignTemplates/Show/Advisor'

function Profiles({ advisor }) {
	return (
		<div className="row bg-light">
			{advisor?.profiles?.map((profile, index) => (
				<React.Fragment key={profile.id}>
					<div className="col py-5" style={{ minWidth: 300 }}>
						<Advisor profile={profile} company_name={advisor.company_name} />
					</div>
					{index % 2 === 1 && <div className="w-100"></div>}
				</React.Fragment>
			))}
		</div>
	)
}

Profiles.propTypes = {}

export default memo(Profiles)
