/**
 *
 * /MarkdownEditor
 *
 */

import React, { useState } from 'react'
import ReactMde, { getDefaultToolbarCommands } from 'react-mde'
import ReactMarkdown from 'react-markdown'
import remarkAlign from 'remark-align'
import { center_command } from './center_command'
import 'react-mde/lib/styles/css/react-mde-all.css'

function MarkdownEditor({ value, setValue, onBlur, disabled }) {
	const [tab, setTab] = useState('write')

	const renderers = {
		centerAligned: ({ children }) => <div className="text-center">{children}</div>,
	}

	return (
		<div onBlur={onBlur}>
			<ReactMde
				value={value || ''}
				onChange={setValue}
				selectedTab={tab}
				onTabChange={setTab}
				disabled={disabled}
				generateMarkdownPreview={(markdown) =>
					Promise.resolve(<ReactMarkdown source={markdown} plugins={[remarkAlign]} renderers={renderers} />)
				}
				commands={{ center: center_command }}
				toolbarCommands={[...getDefaultToolbarCommands(), ['center']]}
			/>
		</div>
	)
}

MarkdownEditor.propTypes = {}

export default MarkdownEditor
