import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

if (process.env.ENV !== 'development') {
	Sentry.init({
		dsn: 'https://499f986f97d946b58bcc7a80ccdef060@sentry.io/2016748',
		release: `${process.env.ENV[0]}.${process.env.BUILD_NUMBER}`,
		environment: process.env.ENV,
		attachStacktrace: true,
		integrations: [new Integrations.BrowserTracing()],
		tracesSampleRate: 0.25,
	})

	Sentry.setTag('version_label', `${process.env.ENV[0]}.${process.env.BUILD_NUMBER}`)
}

export default Sentry
