/**
 *
 * Table/Cell/Resizer
 *
 */

import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { ADD_RESIZED, DELETE_RESIZED } from 'components/Table'

const ResizerElement = styled.div`
	display: inline-block;
	position: absolute;
	top: 0;
	bottom: 0;
	right: -18px;
	width: 36px;
	cursor: col-resize;
`

function Resizer({ column, setCurrentlyResizing, currently_resizing, dispatchResized }) {
	const column_id = column.id || column.accessor

	const resizeColumnStart = (event) => {
		event.stopPropagation()
		const parentWidth = event.target.parentElement.getBoundingClientRect().width

		const { pageX } = event

		setCurrentlyResizing({
			id: column_id,
			startX: pageX,
			parentWidth,
		})
	}

	const resizeColumnMoving = (event) => {
		if (currently_resizing) {
			event.stopPropagation()

			// Delete old value
			dispatchResized({ type: DELETE_RESIZED, id: currently_resizing.id })

			const { pageX } = event

			// Set the min size to 10 to account for margin and border or else the
			// group headers don't line up correctly
			const newWidth = Math.max(currently_resizing.parentWidth + pageX - currently_resizing.startX, 11)

			dispatchResized({
				type: ADD_RESIZED,
				id: currently_resizing.id,
				width: newWidth,
			})

			// onResizedChange callback
		}
	}

	const resizeColumnEnd = (event) => {
		event.stopPropagation()
		setCurrentlyResizing(false)
	}

	useEffect(() => {
		if (currently_resizing && currently_resizing.id === column_id) {
			document.addEventListener('mousemove', resizeColumnMoving)
			document.addEventListener('mouseup', resizeColumnEnd)
			document.addEventListener('mouseleave', resizeColumnEnd)
		}

		return () => {
			if (currently_resizing && currently_resizing.id === column_id) {
				document.removeEventListener('mousemove', resizeColumnMoving)
				document.removeEventListener('mouseup', resizeColumnEnd)
				document.removeEventListener('mouseleave', resizeColumnEnd)
			}
		}
	}, [currently_resizing])

	return <ResizerElement onMouseDown={resizeColumnStart} />
}

Resizer.propTypes = {}

export default Resizer
