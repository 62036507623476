/**
 *
 *  Table/BodyVirtualized
 *
 */

import React from 'react'

// NPM Libraries
import PropTypes from 'prop-types'
import { AutoSizer, CellMeasurer, CellMeasurerCache, List, WindowScroller } from 'react-virtualized'

// Component
import TableRow from 'components/Table/Row'

const TableBodyVirtualized = ({ rows, decorated_columns, row_dropdown, buildTooltip }) => {
	const cache = React.useRef(new CellMeasurerCache({ fixedWidth: true, defaultHeight: 0 }))

	return (
		<div style={{ width: '100%' }}>
			<WindowScroller>
				{({ height, scrollTop }) => (
					<AutoSizer disableHeight>
						{({ width }) => (
							<List
								autoHeight
								width={width}
								height={height}
								scrollTop={scrollTop}
								rowCount={rows.length}
								rowHeight={cache.current.rowHeight}
								deferredMeasurementCache={cache.current}
								rowRenderer={({ key, index, style, parent }) => {
									const element = rows[index]

									return (
										<CellMeasurer
											key={key}
											parent={parent}
											rowIndex={index}
											columnIndex={0}
											cache={cache.current}
										>
											{({ measure }) => (
												<div style={style}>
													<TableRow
														key={index}
														row={element}
														decorated_columns={decorated_columns}
														row_dropdown={row_dropdown}
														buildTooltip={buildTooltip}
														rowHeightRecompute={measure}
														virtualized
													/>
												</div>
											)}
										</CellMeasurer>
									)
								}}
							/>
						)}
					</AutoSizer>
				)}
			</WindowScroller>
		</div>
	)
}

TableBodyVirtualized.propTypes = {
	rows: PropTypes.array,
	decorated_columns: PropTypes.array,
	row_dropdown: PropTypes.func,
	buildTooltip: PropTypes.func,
}

export default TableBodyVirtualized
