/**
 *
 * /CircleImage
 *
 */

import React from 'react'
// import PropTypes from 'prop-types'
import styled from '@emotion/styled'

// import gql from 'graphql-tag'
// import Query from 'components/Query'
// import { Mutation } from '@apollo/client/react/components'

// import { FormattedMessage } from 'react-intl'
// import messages from './messages'

function CircleImage({ size, url, children, className, css }) {
	const Image = styled('div')`
		background: #eee top / cover no-repeat url(${url});
		height: ${size};
		width: ${size};
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		${css}
	`

	return <Image className={className}>{children}</Image>
}

CircleImage.propTypes = {}

export default CircleImage
