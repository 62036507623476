/* eslint-disable no-param-reassign */

/**
 *
 * Campaigns/Form/CarouselImages/DragAndDropImage
 *
 */

import React, { memo, useRef, useState } from 'react'

// NPM Libraries
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDrag, useDrop } from 'react-dnd'

// Components
import Modal from 'components/Modal'

function DragAndDropImage({ id, index, data, removeImage, moveItem }) {
	const [show_preview, setShowPreview] = useState()
	const ref = useRef()

	const [, drop] = useDrop({
		accept: 'div',
		hover(item, monitor) {
			if (!ref.current) {
				return
			}
			const dragIndex = item.index
			const hoverIndex = index
			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return
			}
			// Determine rectangle on screen
			const hoverBoundingRect = ref.current.getBoundingClientRect()
			// Get vertical middle
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
			// Determine mouse position
			const clientOffset = monitor.getClientOffset()
			// Get pixels to the top
			const hoverClientY = clientOffset.y - hoverBoundingRect.top
			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%
			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return
			}
			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return
			}
			// Time to actually perform the action
			moveItem(dragIndex, hoverIndex)
			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex
		},
	})

	const [, drag] = useDrag({
		item: { type: 'div', id, index },
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	})

	drag(drop(ref))

	return (
		<div
			ref={ref}
			id={id}
			index={index}
			className="d-flex justify-content-center align-items-center my-2 p-1 w-75 border position-relative mx-auto"
			style={{ cursor: 'move' }}
		>
			<img src={data.url} style={{ maxHeight: '10rem' }} alt="Carousel img" />
			<button type="button" onClick={removeImage} className="btn-unstyled p-1 place-br mr-1">
				<FontAwesomeIcon className="text-danger" icon="trash" size="sm" />
			</button>
			<button
				type="button"
				onClick={() => setShowPreview(true)}
				className="btn-unstyled p-1 place-br mr-1"
				style={{ bottom: 30 }}
			>
				<FontAwesomeIcon icon="search" size="sm" />
			</button>
			<Modal
				show={show_preview}
				modalDidClose={() => setShowPreview(false)}
				style={{
					padding: '1rem',
					maxWidth: '70%',
					maxHeight: '100%',
				}}
			>
				<div className="text-center">
					<img
						src={data.url}
						alt="Carousel img"
						style={{
							maxHeight: '100%',
							maxWidth: '100%',
						}}
					/>
				</div>
			</Modal>
		</div>
	)
}

DragAndDropImage.propTypes = {}

export default memo(DragAndDropImage)
