/**
 *
 * /Checkbox
 *
 */

import React from 'react'

// NPM Libraries
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Checkbox({ label, name, onChange, checked, disabled, style, value, className }) {
	return (
		<label className={`${!disabled && 'clickable'} d-inline-flex`} style={style} data-test={name}>
			<FontAwesomeIcon
				icon={['far', checked ? 'check-square' : 'square']}
				className={`${className} mr-2`}
				size="lg"
				style={{ opacity: disabled ? '0.5' : 1 }}
			/>
			{label}
			<input
				type="checkbox"
				name={name}
				value={value || checked || true}
				onChange={(e) => onChange(e.target.checked, e)}
				disabled={disabled}
				hidden
				checked={checked}
			/>
		</label>
	)
}

Checkbox.propTypes = {
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	name: PropTypes.string,
	onChange: PropTypes.func,
	checked: PropTypes.bool,
	disabled: PropTypes.bool,
	style: PropTypes.object,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	className: PropTypes.string,
}

Checkbox.defaultProps = {
	className: 'text-success',
}

export default Checkbox
