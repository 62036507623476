/*
 * Notifications/NotificationCenter Messages
 *
 * This contains all the text for the NotificationCenter component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
	view_all: {
		id: 'app.components.NotificationsNotificationCenter.view_all',
		defaultMessage: 'View All Notifications',
	},
	mark_all_read: {
		id: 'app.components.Notifications/NotificationCenter.view_all',
		defaultMessage: 'Mark All as Read',
	},
})
