/**
 *
 * CampaignTemplates/Show/Map
 *
 */

import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
// import styled from 'styled-components'

// Custom Components
import GoogleMapReact from 'google-map-react'

// NPM Tools
import sha256 from 'js-sha256'

const MapMarker = ({ className, lat, lng }) => <i lat={lat} lng={lng} className={className}></i>

MapMarker.propTypes = {
	className: PropTypes.string,
	lat: PropTypes.number,
	lng: PropTypes.number,
}

const Map = ({ events, advisor, is_webinar }) => {
	const [locations, setLocations] = useState([])

	useEffect(() => {
		const unique_locations = {}

		if (events?.length && !is_webinar) {
			events.forEach((event) => {
				const location = {
					title: event.title,
					venue: event.venue,
					lat: event.address_coordinates[0],
					lng: event.address_coordinates[1],
					address_full: event.address_full,
					address_apt: event.address_apt,
					address_components: event.address_components || {},
				}
				unique_locations[sha256(JSON.stringify(location))] = location
			})
		} else {
			advisor.company_addresses?.forEach((address) => {
				const location = {
					title: advisor.company_name,
					lat: address.coordinates[0],
					lng: address.coordinates[1],
					address_full: address.full,
					apt: address.apt,
					address_components: address.components || {},
				}
				unique_locations[sha256(JSON.stringify(location))] = location
			})
		}

		setLocations(
			Object.keys(unique_locations).map((location_id) => ({ ...unique_locations[location_id], id: location_id })),
		)
	}, [events, advisor])

	const getMapBounds = (map, maps) => {
		const bounds = new maps.LatLngBounds()

		locations.forEach((location) => {
			bounds.extend(new maps.LatLng(location.lat, location.lng))
		})

		return bounds
	}

	const bindResizeListener = (map, maps, bounds) => {
		maps.event.addDomListenerOnce(map, 'idle', () => {
			maps.event.addDomListener(window, 'resize', () => {
				if (locations.length > 1) map.fitBounds(bounds)
			})
		})
	}

	const apiIsLoaded = (map, maps) => {
		if (map) {
			const bounds = getMapBounds(map, maps)
			if (locations.length > 1) map.fitBounds(bounds)
			bindResizeListener(map, maps, bounds)
		}
	}

	if (locations.length === 0) return null

	const center = {
		lat: locations.reduce((lats, event) => lats + event.lat, 0) / locations.length,
		lng: locations.reduce((lngs, event) => lngs + event.lng, 0) / locations.length,
	}

	return (
		<div style={{ height: 500, position: 'relative' }}>
			<GoogleMapReact
				bootstrapURLKeys={{
					key: 'AIzaSyCDw1iSOum_YiNHHbttsog0tdlxnpNdI90',
				}}
				center={center}
				zoom={11}
				yesIWantToUseGoogleMapApiInternals
				onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
			>
				{locations.map((location) => (
					<MapMarker
						key={location.id}
						lat={location.lat}
						lng={location.lng}
						className="fas fa-3x fa-map-marker-alt"
					/>
				))}
			</GoogleMapReact>
			<div
				style={{
					position: 'absolute',
					top: 15,
					right: 50,
					backgroundColor: '#FFF',
					boxShadow: '1px 1px 3px #000',
				}}
			>
				<h2
					className="mr-3 mt-5"
					style={{
						position: 'absolute',
						right: '100%',
						fontWeight: 'normal',
						width: '5em',
					}}
				>
					Directions
				</h2>
				<ul className="list-inline mb-0">
					{locations.map((location) => (
						<li key={location.id} className="list-inline-item px-5 pt-4 pb-3">
							<div className="d-inline-block align-top px-3 pt-1">
								<span className="fa-layers fa-fw">
									<i className="fas fa-square-full text-primary" data-fa-transform="grow-12"></i>
									<i className="fas fa-map-signs text-white"></i>
								</span>
							</div>
							<div className="d-inline-block">
								{location.title && (
									<strong>
										{location.title}
										<br />
									</strong>
								)}
								<strong>{location.venue}</strong>
								<address>
									{location.address_components.street_number} {location.address_components.street}{' '}
									{location.address_apt}
									<br />
									{location.address_components.city}, {location.address_components.state}{' '}
									{location.address_components.zip}
								</address>
							</div>
						</li>
					))}
				</ul>
			</div>
		</div>
	)
}

Map.propTypes = {
	events: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
	is_webinar: PropTypes.bool,
	advisor: PropTypes.objectOf(PropTypes.any),
}

Map.defaultProps = {}

export default memo(Map)
