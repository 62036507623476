/**
 *
 * /BecomeOriginalUserBanner
 *
 */

import React, { memo } from 'react'

// NPM Libraries
import axios from 'axios'
import client from 'configureApolloClient'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { useCurrentUser } from 'hooks'
import { COLORS } from 'global-styles'

const Banner = styled.div`
	z-index: 100000;
	top: 0;
	left: 0;
	right: 0;
	background: ${COLORS.warning};
	text-align: center;
	font-size: 80%;
`

function BecomeOriginalUserBanner({ absolute }) {
	const current_user = useCurrentUser()

	const becomeOriginalUser = () => {
		axios({
			method: 'POST',
			url: `${process.env.API_URL}/api/v1/become_original_user`,
		}).then(() => {
			sessionStorage.removeItem('can_become_original_user')
			client.resetStore()
		})
	}

	if (sessionStorage.getItem('can_become_original_user')) {
		return (
			<Banner style={{ position: absolute ? 'absolute' : 'static' }}>
				Signed in as {current_user.full_name} ({current_user.email})
				{new Date().getTime() < sessionStorage.getItem('can_become_original_user') && (
					<button className="btn btn-unstyled" type="button" onClick={becomeOriginalUser}>
						<small>Become Original User</small>
					</button>
				)}
			</Banner>
		)
	}
	return null
}

BecomeOriginalUserBanner.propTypes = {
	absolute: PropTypes.bool,
}

export default memo(BecomeOriginalUserBanner)
