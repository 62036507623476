/**
 *
 *  CmdK/UserSearch
 *
 */

import React, { memo, useEffect } from 'react'
// NPM Libraries
// import PropTypes from 'prop-types'
// import styled from '@emotion/styled'
import { useHistory } from 'react-router-dom'
import { Command } from 'cmdk'
import { useIdentifier } from '@joeyparis/hooks'

// GraphQL
import useQuery from 'components/UseQuery'
import { gql } from '@apollo/client'

import { FormattedMessage } from 'react-intl'
import messages from './messages'

export const SEARCH_USERS = gql`
	query searchUsers(
		$page: Int
		$page_size: Int
		$search: String
		$sort: String
		$sort_direction: String
	) {
		search: search_users(
			page: $page
			page_size: $page_size
			search: $search
			sort: $sort
			sort_direction: $sort_direction
		) {
			_total
			results: users {
				id
				full_name
				email
				company_name
				consultant {
					id
					full_name
				}
			}
		}
	}
`

function UserSearch({ search, setLoading, onSelect }) {
	const history = useHistory()
	const id = useIdentifier()

	const { data, loading } = useQuery(SEARCH_USERS, {
		variables: {
			search,
		},
		default_data: {
			search: {
				results: [],
			},
		},
		skip: !search || search.length < 3,
	})

	useEffect(() => {
		setLoading(id, loading)
	}, [loading])

	useEffect(() => {
		return () => {
			setLoading(id, false)
		}
	}, [])

	return (
		<Command.Group heading="Users">
			{data.search.results.map((user) => (
				<Command.Item
					key={user.id}
					value={`${user.full_name} - ${user.company_name} - ${user.email}`}
					onSelect={() => {
						history.push(`/users/preferences/${user.id}`)
						if (onSelect) onSelect()
					}}
				>
					{user.full_name} - {user.company_name} - {user.email}
				</Command.Item>
			))}
		</Command.Group>
	)
}

UserSearch.propTypes = {}

export default memo(UserSearch)
