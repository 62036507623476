/**
 *
 * Campaigns/Form/HeaderTags
 *
 */

import React, { useState } from 'react'

// NPM Libraries
import FloatingLabel from '@joeyparis/react-floating-label-input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHandleInputChange } from 'hooks'

// Component
import Modal from 'components/Modal'

function HeaderTags({ campaign_state, setHeaderTags }) {
	const [edit_tag, setEditTag] = useState()

	const addTag = () => {
		const new_tag = {
			tag_name: 'script',
			attributes: [{ name: 'type', value: 'text/javascript' }],
			content: undefined,
		}

		const new_state = Array.isArray(campaign_state.header_tags)
			? campaign_state.header_tags.concat(new_tag)
			: [new_tag]
		setHeaderTags(new_state)

		setEditTag({ header_tag: new_tag, index: new_state.indexOf(new_tag) })
	}

	return (
		<div className="row">
			<div className="col-12">
				<div className="form-text text-muted">Header Tags</div>
				<button type="button" className="btn-unstyled" onClick={addTag}>
					<FontAwesomeIcon icon="plus" className="mr-2" />
					Add a header tag
				</button>

				{campaign_state.header_tags?.map((header_tag, index) =>
					!header_tag ? null : (
						<div key={index} className="d-flex mt-3">
							<div>
								<button
									type="button"
									className="btn btn-primary btn-sm rounded mr-3"
									onClick={() => setEditTag({ header_tag, index })}
								>
									Edit
								</button>
							</div>
							<pre>
								&lt;
								{header_tag.tag_name}
								{header_tag.attributes?.map((attribute, index_internal) => (
									<span key={index_internal}>
										{' '}
										{attribute.name}="{attribute.value}"
									</span>
								))}
								&gt;
								{header_tag.content?.length > 0 && <div className="ml-4">{header_tag.content}</div>}
								&lt;/{header_tag.tag_name}&gt;
							</pre>
						</div>
					),
				)}

				{edit_tag?.header_tag && (
					<Modal show={!!edit_tag} modalDidClose={() => setEditTag(null)}>
						<HeaderTagEdit
							header_tag={edit_tag.header_tag}
							setHeaderTag={(header_tag) => {
								const header_tags_copy = campaign_state.header_tags.slice()
								header_tags_copy[edit_tag.index] = header_tag
								setHeaderTags(header_tags_copy)
							}}
							removeHeaderTag={() => {
								const header_tags_copy = campaign_state.header_tags.slice()
								header_tags_copy.splice(edit_tag.index, 1)
								setHeaderTags(header_tags_copy)
							}}
							close={() => setEditTag(null)}
						/>
					</Modal>
				)}
			</div>
		</div>
	)
}

// in Modal
function HeaderTagEdit({ header_tag, setHeaderTag, removeHeaderTag, close }) {
	const [tag_state, handleInputChange, mergeState] = useHandleInputChange(header_tag)

	const addAttribute = () => {
		mergeState({ attributes: tag_state.attributes.concat({ name: '', value: '' }) })
	}

	return header_tag ? (
		<div className="m-3">
			<FloatingLabel
				label="Tag Name"
				type="text"
				name="tag_name"
				value={tag_state.tag_name || ''}
				onChange={handleInputChange}
			/>
			{tag_state.attributes.map((attribute, index) => (
				<HeaderTagAttribute
					key={index}
					attribute={attribute}
					setAttribute={(new_attribute) => {
						const attributes = tag_state.attributes.slice()
						attributes[index] = new_attribute
						mergeState({ attributes })
					}}
				/>
			))}
			<button type="button" className="btn-unstyled d-block my-3" onClick={addAttribute}>
				<FontAwesomeIcon icon="plus" className="mr-2" />
				Add an attribute
			</button>

			<div className="form-text text-muted">Content</div>
			<textarea
				rows={7}
				className="form-control"
				name="content"
				value={tag_state.content || ''}
				onChange={handleInputChange}
				style={{ fontFamily: 'Menlo, Monaco, monospace', fontSize: 'small' }}
			/>

			<div className="text-center mt-3">
				<button
					className="btn btn-success rounded"
					type="button"
					onClick={() => {
						setHeaderTag({
							...tag_state,
							// remove empty attributes
							attributes: tag_state.attributes.filter(
								(at) => at?.name?.trim().length && at?.value?.trim().length,
							),
						})
						close()
					}}
				>
					Done
				</button>
				<button
					className="btn-unstyled text-danger ml-3"
					type="button"
					onClick={() => {
						removeHeaderTag()
						close()
					}}
				>
					Delete
				</button>
			</div>
		</div>
	) : null
}

function HeaderTagAttribute({ attribute, setAttribute }) {
	const [state, handleInputChange] = useHandleInputChange(attribute)

	const onBlur = () => setAttribute(state)

	return attribute ? (
		<div className="row">
			<div className="col-3">
				<FloatingLabel
					type="text"
					label="Attribute Name"
					name="name"
					onChange={handleInputChange}
					value={state.name}
					onBlur={onBlur}
				/>
			</div>
			<div className="col-9">
				<FloatingLabel
					type="text"
					label="Attribute Value"
					name="value"
					value={state.value}
					onChange={handleInputChange}
					onBlur={onBlur}
				/>
			</div>
		</div>
	) : null
}

HeaderTags.propTypes = {}

export default HeaderTags
