/**
 *
 * Campaigns/Form/TemplateSelector
 *
 */

import React, { useState } from 'react'

// Components
import CampaignTemplateSelector from 'components/CampaignTemplates/Selector'
import CircleImage from 'components/CircleImage'
import FullModal from 'components/FullModal'

// Image
import TemplateIcon from 'components/Campaigns/Form/Template.svg'

function TemplateSelector({ campaign, setCampaignState }) {
	const [show, setShow] = useState(false)
	const [applied_template_name, setAppliedTemplateName] = useState()

	return (
		<div>
			<div className="py-3 d-flex justify-content-center align-items-start">
				<CircleImage size="4rem">
					<img src={TemplateIcon} style={{ height: '2.6rem' }} alt="" />
				</CircleImage>
				<div className="ml-3">
					<span className="font-weight-bold">Template</span>
					<div>{applied_template_name}</div>
					<button type="button" className="btn-unstyled" onClick={() => setShow(true)}>
						Change Template
					</button>
				</div>
			</div>
			<FullModal show={show} modalDidClose={() => setShow(false)}>
				<CampaignTemplateSelector
					campaign={{ ...campaign, objective: campaign.objective_index, always_on: undefined }}
					setAppliedTemplate={(template) => {
						setAppliedTemplateName(template.name)
						setCampaignState({
							...template,
							name: campaign.name,
							campaign_image_url: campaign.campaign_image_url ?? template.campaign_image_url,
							objective_index: template.objective,
						})
						setShow(false)
					}}
				/>
			</FullModal>
		</div>
	)
}

TemplateSelector.propTypes = {}

export default TemplateSelector
