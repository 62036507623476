import { useEffect } from 'react'

import useQuery from 'components/UseQuery'
import gql from 'graphql-tag'

import { underscore } from 'components/utils'

const GET_INTERCOM_DIGEST = gql`
	{
		intercom_digest
	}
`

export const useIntercom = (current_user) => {
	const {
		data: { intercom_digest },
	} = useQuery(GET_INTERCOM_DIGEST, {})

	useEffect(() => {
		if (current_user.id && intercom_digest && !sessionStorage.getItem('can_become_original_user')) {
			window.intercomSettings = {
				app_id: 'kzntj6p9',
				user_id: current_user.id,
				user_hash: intercom_digest,
				name: current_user.full_name, // Full name
				email: current_user.email,
				email_confirmed: current_user.is_confirmed,
				phone: current_user.phone,
				role: current_user.role_string,
				build_number: process.env.BUILD_NUMBER,
				is_locked_out: current_user.is_locked_out,
				company: {
					id: underscore(String(current_user.company_name).toLowerCase()) || 'company_unknown',
					name: current_user.company_name || 'Company Unknown',
					website: current_user.company_website,
				},
				created_at: new Date(current_user.created_at).getTime() / 1000, // Signup Date
			}

			/* eslint-disable */
			;(function () {
				const w = window
				const ic = w.Intercom
				if (typeof ic === 'function') {
					ic('reattach_activator')
					ic('update', intercomSettings)
				} else {
					const d = document
					var i = function () {
						i.c(arguments)
					}
					i.q = []
					i.c = function (args) {
						i.q.push(args)
					}
					w.Intercom = i
					function l() {
						const s = d.createElement('script')
						s.type = 'text/javascript'
						s.async = true
						s.src = 'https://widget.intercom.io/widget/kzntj6p9'
						const x = d.getElementsByTagName('script')[0]
						x.parentNode.insertBefore(s, x)
					}
					if (w.attachEvent) {
						w.attachEvent('onload', l)
					} else {
						w.addEventListener('load', l, false)
					}
				}
			})()
			/* eslint-enable */
		}
	}, [current_user.id, intercom_digest])

	useEffect(() => {
		/* eslint-disable */
		;(function () {
			const w = window
			const ic = w.Intercom
			if (typeof ic === 'function') {
				ic('reattach_activator')
				ic('update', intercomSettings)
			} else {
				const d = document
				var i = function () {
					i.c(arguments)
				}
				i.q = []
				i.c = function (args) {
					i.q.push(args)
				}
				w.Intercom = i
				function l() {
					const s = d.createElement('script')
					s.type = 'text/javascript'
					s.async = true
					s.src = 'https://widget.intercom.io/widget/kzntj6p9'
					const x = d.getElementsByTagName('script')[0]
					x.parentNode.insertBefore(s, x)
				}
				if (w.attachEvent) {
					w.attachEvent('onload', l)
				} else {
					w.addEventListener('load', l, false)
				}
			}
		})()
		/* eslint-enable */
	}, [])
}

export default useIntercom
