/**
 *
 * CampaignTemplates/Show/AdvisorContact
 *
 */

import React, { memo } from 'react'
import PropTypes from 'prop-types'

// Components
import { formatPhoneNumber } from 'components/utils'

const AdvisorContact = ({ advisor }) => (
	<div className="row text-center py-5">
		<div className="col-md col-12">
			<strong>{advisor.company_name}</strong>
		</div>
		{advisor.company_addresses &&
			advisor.company_addresses.map((company_address) => (
				<div className="col-md col-12 py-3" key={company_address.id}>
					<div className="d-inline-block align-top px-3 pt-1">
						<span className="fa-layers fa-fw">
							<i className="fas fa-square-full text-primary" data-fa-transform="grow-12"></i>
							<i className="fas fa-building text-white"></i>
						</span>
					</div>
					<div className="d-inline-block">
						<address>
							{company_address.components?.street_address}
							<br />
							{company_address.apt && (
								<span>
									{company_address.apt}
									<br />
								</span>
							)}
							{company_address.components?.city}, {company_address.components?.state}{' '}
							{company_address.components?.zip}
						</address>
					</div>
				</div>
			))}
		{advisor.company_phone && (
			<div className="col-md col-12 py-3">
				<div className="d-inline-block align-top px-3 pt-1">
					<span className="fa-layers fa-fw">
						<i className="fas fa-square-full text-primary" data-fa-transform="grow-12"></i>
						<i className="fas fa-phone text-white"></i>
					</span>
				</div>
				<div className="d-inline-block">
					<a href={`tel:${advisor.company_phone}`}>{formatPhoneNumber(advisor.company_phone)}</a>{' '}
					{advisor.company_phone_extension}
				</div>
			</div>
		)}
		{advisor.company_email && (
			<div className="col-md col-12 py-3">
				<div className="d-inline-block align-top px-3 pt-1">
					<span className="fa-layers fa-fw">
						<i className="fas fa-square-full text-primary" data-fa-transform="grow-12"></i>
						<i className="fas fa-paper-plane text-white"></i>
					</span>
				</div>
				<div className="d-inline-block">
					<a href={`mailto:${advisor.company_email}`}>{advisor.company_email}</a>
				</div>
			</div>
		)}
		{advisor.company_website && (
			<div className="col-md col-12 py-3">
				<div className="d-inline-block align-top px-3 pt-1">
					<span className="fa-layers fa-fw">
						<i className="fas fa-square-full text-primary" data-fa-transform="grow-12"></i>
						<i className="fas fa-globe text-white"></i>
					</span>
				</div>
				<div className="d-inline-block">
					<a href={advisor.company_website} target="_blank" rel="noopener noreferrer">
						{advisor.company_website}
					</a>
				</div>
			</div>
		)}
	</div>
)

AdvisorContact.propTypes = {
	advisor: PropTypes.objectOf(PropTypes.any),
}

export default memo(AdvisorContact)
