/**
 *
 * /ChangeLog
 *
 */

import React, { memo } from 'react'

// NPM Libraries
import ReactMarkdown from 'react-markdown'

// GraphQL
import useQuery from 'components/UseQuery'
import { gql } from '@apollo/client'

const GET_CHANGE_LOG = gql`
	query change_log {
		change_log
	}
`

function ChangeLog() {
	const {
		data: { change_log },
	} = useQuery(GET_CHANGE_LOG, { default_data: { change_log: '' } })

	return (
		<div>
			<ReactMarkdown source={change_log} />
		</div>
	)
}

ChangeLog.propTypes = {}

export default memo(ChangeLog)
