/**
 *
 * UsersSelect/WithData
 *
 */

import React, { useState } from 'react'

// NPM Libraries
import InfiniteScroll from 'react-infinite-scroll-component'
import FloatingLabel from '@joeyparis/react-floating-label-input'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// GraphQL
import useQuery from 'components/UseQuery'
import { gql } from '@apollo/client'

// Components
import Spinner from 'components/Spinner'
import { withCurrentUser } from 'currentUserContext'

const LOAD_USERS = gql`
	query getUsersConnection(
		$number_of_users: Int
		$filters: [JSON]
		$user_role: Int
		$cursor: String
		$with_campaigns: Boolean
		$viewer_id: ID
	) {
		usersConnection(
			first: $number_of_users
			filters: $filters
			user_role: $user_role
			after: $cursor
			with_campaigns: $with_campaigns
			viewer_id: $viewer_id
		) {
			pageInfo {
				startCursor
				endCursor
				hasNextPage
				hasPreviousPage
			}
			edges {
				cursor
				node {
					id
					full_name
					email
					company_name
					profile_image
					default_campaign_questions
					default_campaign_questions_required
				}
			}
		}
	}
`

function WithData({ onSelect, selected_user_ids, current_user, user_role, initial_amount, with_campaigns, viewer_id }) {
	const [number_of_users, setNumberOfUsers] = useState(initial_amount)
	const [filters, setFilters] = useState([])
	const [search_string, setSearchString] = useState('')

	const {
		data: {
			usersConnection: { pageInfo, edges },
		},
		loading,
		refetch,
		fetchMore,
	} = useQuery(LOAD_USERS, {
		variables: { number_of_users, filters, user_role, viewer_id, with_campaigns },
		default_data: {
			usersConnection: { pageInfo: {}, edges: [] },
		},
	})

	const handleChange = (e) => {
		setSearchString(e.target.value)
		setFilters([{ id: 'all', value: e.target.value }])
	}

	const getNext = () => {
		fetchMore({
			variables: {
				number_of_users,
				filters,
				user_role,
				viewer_id,
				with_campaigns,
				cursor: pageInfo.endCursor,
			},
			updateQuery: (previousResult, { fetchMoreResult }) =>
				fetchMoreResult.usersConnection.edges.length
					? {
							usersConnection: {
								__typename: previousResult.usersConnection.__typename,
								pageInfo: fetchMoreResult.usersConnection.pageInfo,
								edges: [
									...previousResult.usersConnection.edges,
									...fetchMoreResult.usersConnection.edges,
								],
							},
					  }
					: previousResult,
		})
	}

	return (
		<div>
			<FloatingLabel type="text" label="Search" value={search_string} onChange={handleChange} />
			<ul className="list-group list-group-flush">
				<InfiniteScroll
					refreshFunction={refetch}
					next={getNext}
					dataLength={edges.length}
					hasMore={pageInfo.hasNextPage}
					height={400}
					loader={
						<li className="list-group-item">
							<Spinner />
						</li>
					}
				>
					{current_user.full_name.match(search_string) && (
						<li
							className="list-group-item list-group-item-action clickable"
							onClick={(event) => {
								event.preventDefault()
								onSelect(current_user)
							}}
						>
							<strong>{current_user.full_name} (You)</strong>
							<br />
							<small className="text-muted">
								{[current_user.company_name, current_user.email].filter((x) => x).join(' - ')}
							</small>
						</li>
					)}
					{edges.map((edge) => {
						const user = edge.node
						return (
							<li
								key={user.id}
								className={classNames('list-group-item list-group-item-action clickable', {
									'list-group-item-secondary': selected_user_ids?.includes(user.id),
								})}
								onClick={() => onSelect(user)}
							>
								<strong>{user.full_name}</strong>
								<br />
								<small className="text-muted">
									{[user.company_name, user.email].filter((x) => x).join(' - ')}
								</small>
							</li>
						)
					})}
				</InfiniteScroll>
			</ul>
		</div>
	)
}

WithData.propTypes = {
	onSelect: PropTypes.func.isRequired,
}

WithData.defaultProps = {
	height: 400,
	initial_amount: 20,
}

export default withCurrentUser(WithData)
