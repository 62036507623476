/**
 *
 * /Molecule
 *
 */

import React, { memo, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

// import gql from 'graphql-tag'
// import useQuery from 'components/UseQuery'
// import { useMutation } from '@apollo/client'

import Modal from 'components/Modal'

import axios from 'axios'

import SafelySetInnerHTML from 'safely-set-inner-html'
import { decodeHTMLEntities } from 'components/utils'

import FoldingAtHomeLogo from './foldingathome-logo.png'

const safelySetInnerHTML = new SafelySetInnerHTML({
	ALLOWED_TAGS: ['strong', 'p', 'ul', 'li', 'a'],
	ALLOWED_ATTRIBUTES: ['href', 'target'],
})

const WhatsThis = styled.button`
	border: none;
	border-radius: 1em;
	background: #fff;

	&:hover {
		text-decoration: underline;
	}
`

function Molecule(props) {
	const [iframe_source, setIframeSource] = useState('')
	const [folding_project_id, setFoldingProjectId] = useState(props.folding_project_id)
	const [current_project_id, setCurrentProjectId] = useState('')
	const [folding_projects, setFoldingProjects] = useState([])
	const [project_info, setProjectInfo] = useState({})
	const [show_modal, setShowModal] = useState(false)
	const big_iframe = useRef()
	const small_iframe = useRef()

	useEffect(() => {
		axios({
			method: 'GET',
			url: `${process.env.API_URL}/api/v1/folding_project_id`,
			params: { folding_project_id },
			withCredentials: true,
			xsrfCookieName: 'X-CSRF-Token',
			xsrfHeaderName: 'X-CSRF-Token',
		}).then((response) => {
			setCurrentProjectId(response.data.current_project_id)
			setFoldingProjectId(response.data.folding_project_id)
			setProjectInfo(response.data.project_info)
			setFoldingProjects(response.data.folding_projects.filter((id) => id !== response.data.current_project_id))

			big_iframe.current?.contentWindow.location.reload()
			small_iframe.current?.contentWindow.location.reload()
		})
	}, [folding_project_id])

	useEffect(() => {
		setIframeSource(`/public/molecule.html#${folding_project_id}`)
	}, [folding_project_id])

	if (!folding_project_id) {
		return null
	}

	return (
		<div {...props}>
			<iframe
				ref={big_iframe}
				title="Molecule"
				src={iframe_source}
				style={{ height: '100%', width: '100%' }}
			/>
			<div style={{ position: 'absolute', bottom: 25, width: '100%' }} className="text-center">
				<WhatsThis onClick={() => setShowModal(true)}>What&apos;s this?</WhatsThis>
				<Modal
					debug
					show={show_modal}
					modalDidClose={() => setShowModal(false)}
					style={{
						width: '90%',
						height: '100%',
					}}
				>
					<div className="row mb-2">
						<div className="col-12 text-center">
							<img
								src="https://s3.amazonaws.com/leadjig-resources/images/LeadJig+Black+Text.png"
								alt="LeadJig"
								style={{ height: 105 }}
							/>
							<span className="mx-5">+</span>
							<img src={FoldingAtHomeLogo} alt="Folding At Home" />
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<ul className="list-inline">
								<li className="list-inline-item">Current Project:</li>
								<li className="list-inline-item">
									<button
										className="btn-unstyled"
										type="button"
										onClick={() => setFoldingProjectId(current_project_id)}
										style={{
											fontWeight: current_project_id === folding_project_id ? 'bold' : 'normal',
										}}
									>
										{current_project_id}
									</button>
								</li>
							</ul>
							<ul className="list-inline">
								<li className="list-inline-item">Other projects:</li>
								{folding_projects.map((id) => (
									<li key={id} className="list-inline-item">
										<button
											className="btn-unstyled"
											type="button"
											onClick={() => setFoldingProjectId(id)}
											style={{ fontWeight: id === folding_project_id ? 'bold' : 'normal' }}
										>
											{id}
										</button>
									</li>
								))}
							</ul>
						</div>
					</div>
					<div className="row mb-2">
						<div className="col-3" style={{ backgroundColor: 'black', minHeight: 300, padding: 0 }}>
							<iframe
								ref={small_iframe}
								title="Molecule"
								src={`/public/molecule.html#${folding_project_id}`}
								style={{ height: '100%', width: '100%' }}
							/>
						</div>
						<div className="col-9">
							<p>
								LeadJig is helping the fight against {project_info.cause} by donating our system&apos;s
								idle CPU cycles to run protein folding simulations and help scientists develop new
								therapeutics to a variety of diseases.{' '}
								{current_project_id === folding_project_id
									? 'Our current focus'
									: 'The focus of this project'}{' '}
								is to gain a better understanding of {project_info.cause}.
							</p>

							<p>
								The protein structure you see right now is a{' '}
								{current_project_id === folding_project_id
									? 'live visualization of a protein currently being'
									: 'visualization of a protein'}{' '}
								folded by the LeadJig system.{' '}
								{current_project_id === folding_project_id &&
									'This structure will continue to change as we run thousands of simulations on hundreds of different proteins every day.'}
							</p>
						</div>
					</div>
					<hr />
					<div className="row mb-2">
						<div className={`col-3 ${project_info.mthumb ? 'text-center' : 'text-left'}`}>
							<strong style={{ whiteSpace: 'nowrap' }}>ABOUT THIS SIMULATION&apos;S PROJECT</strong>
							{project_info.mthumb && (
								<img
									src={`data:image/png;base64, ${project_info.mthumb}`}
									className="img-fluid"
									alt={project_info.manager}
								/>
							)}
							{project_info.manager}
							<br />
							{project_info.institution}
						</div>
						<div className="col-9">
							<p>
								This particular simulation is being run to help scientists at {project_info.institution}{' '}
								gain a better understanding of {project_info.cause}. It is part of a bigger simulation
								project managed by {project_info.manager}.
							</p>
							<hr />
							{project_info.mdescription &&
								safelySetInnerHTML.transform(
									decodeHTMLEntities(project_info.mdescription).replace(
										/ href=/g,
										' target="_blank" href=',
									),
								)}
						</div>
					</div>
					<hr />
					<div className="row">
						<div className="col-12">
							{project_info.thumb && (
								<img
									src={`data:image/png;base64, ${project_info.thumb}`}
									className="img-fluid float-left mr-4"
									alt={project_info.cause}
									style={{ height: 150 }}
								/>
							)}
							{project_info.description &&
								safelySetInnerHTML.transform(
									decodeHTMLEntities(project_info.description).replace(
										/ href=/g,
										' target="_blank" href=',
									),
								)}
						</div>
					</div>
				</Modal>
			</div>
		</div>
	)
}

Molecule.propTypes = {
	folding_project_id: PropTypes.string,
}

export default memo(Molecule)
